<template>
	<div class="c-title-editor">
		<div class="c-title-editor__wrapper">
			<textarea class="c-title-editor__textarea" v-model="currentStep.title" cols="30" rows="10" placeholder="Titel" ref="textarea"></textarea>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TitleEditor',
	props: {
		currentStep: Object,
	},
	mounted() {
		this.$refs.textarea.focus();
	},
};
</script>

<style></style>
