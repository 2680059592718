<template>
	<div class="c-dice" ref="dice">
		<button class="c-button c-button--main">
			Gooi opnieuw <svg class="icon icon_arrow"><use xlink:href="#icon_arrow"></use></svg>
		</button>
	</div>
</template>

<script>
import Dice from './Dice';

export default {
	name: 'Dice',
	data() {
		return {
			dice: null,
		};
	},
	mounted() {
		this.dice = new Dice(this.$refs.dice, true);
	},
	beforeDestroy() {
		this.dice.destroy();
	},
};
</script>

<style scoped></style>
