var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-editor-preview__recording" }, [
    _c("video", {
      ref: "video",
      staticClass: "c-selector-preview__video",
      attrs: {
        src: _vm.video,
        muted: "",
        disablepictureinpicture: "",
        disableremoteplayback: "",
        preload: "auto"
      },
      domProps: { muted: true },
      on: {
        ended: function($event) {
          return _vm.ended()
        }
      }
    }),
    _vm._v(" "),
    _c("audio", {
      ref: "audio",
      staticClass: "c-editor-preview__music",
      attrs: { src: _vm.audio, preload: "auto" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }