var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-content" }, [
    _c("div", { staticClass: "c-bg" }),
    _vm._v(" "),
    _c("div", { staticClass: "u-container" }, [
      _c(
        "ul",
        { staticClass: "c-planet-list" },
        _vm._l(_vm.teams, function(team) {
          return _c("li", { key: team.uuid, staticClass: "c-planet-item" }, [
            _c(
              "div",
              { staticClass: "c-planet-item__shape" },
              [_c("TeamPlanet", { attrs: { team: team } })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "c-planet-item__info" }, [
              _c("div", { staticClass: "c-planet-item__name" }, [
                _vm._v(_vm._s(team.name))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "c-planet-item__objects" }, [
                _vm._v(
                  _vm._s(_vm.getTeamVotes(team)) +
                    " " +
                    _vm._s(_vm.getTeamVotes(team) == 1 ? "stem" : "stemmen")
                )
              ])
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }