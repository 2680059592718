<template>
	<div class="c-sidebar c-sidebar--music">
		<div class="c-sidebar__header">
			<h3 class="c-sidebar__title">{{ title }}</h3>
			<div class="c-sidebar__description">
				{{ description }}
			</div>
		</div>
		<div class="c-sidebar__items">
			<button
				class="c-sidebar-track"
				v-bind:class="{ 'is-active': currentStep.music.id == music.id }"
				type="button"
				v-for="(music, index) in tracks"
				:key="music.id"
				v-on:click="$emit('music-selected', music)"
			>
				<span class="c-sidebar-track__play-button">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 11">
						<path d="M8.74 5.037L.868.087A.57.57 0 00.09.253.541.541 0 000 .55v9.898c0 .304.25.55.561.551.109 0 .215-.03.306-.088l7.874-4.949a.543.543 0 000-.926z" fill-rule="nonzero" />
					</svg>
				</span>
				<span class="c-sidebar-track__label"> Muziek {{ index + 1 }} </span>
			</button>
		</div>
	</div>
</template>

<script>
import sidebarMixin from '../mixins/sidebar';

export default {
	name: 'MusicSidebar',
	mixins: [sidebarMixin],
	props: {
		currentStep: Object,
	},
	computed: {
		tracks() {
			return this.category.tracks;
		},
	},
};
</script>

<style></style>
