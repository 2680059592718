var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-content" }, [
    _c("div", { staticClass: "c-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "u-container" },
      [
        _c("Waiting", {
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function() {
                  return [
                    _vm.waitingTitle
                      ? _c("h2", { staticClass: "t-title" }, [
                          _vm._v(_vm._s(_vm.waitingTitle))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.waitingText
                      ? _c("div", { staticClass: "t-text" }, [
                          _c("p", [_vm._v(_vm._s(_vm.waitingText))])
                        ])
                      : _vm._e()
                  ]
                },
                proxy: true
              },
              _vm.slide.settings.disableBox
                ? {
                    key: "box",
                    fn: function() {
                      return [_c("br")]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }