import axios from 'axios';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios = axios;

const apiClient = axios.create({
	// baseURL: 'http://localhost:3000', // window.location (http(s) domain port)
	baseURL: process.env.MIX_APP_URL,
	withCredentials: false,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
	},
});

export const cmsService = {
	getSlides() {
		return apiClient.get(`/api/v1/slides`);
	},
	getStations() {
		return apiClient.get(`/api/v1/stations`);
	},
	getValues() {
		return apiClient.get(`/api/v1/values`);
	},
	getValueTypes() {
		return apiClient.get(`/api/v1/valueTypes`);
	},

	getClass(uuid) {
		return apiClient.get(`/api/v1/class/${uuid}`);
	},
	getTeams(uuid) {
		if (!uuid) {
			uuid = '';
		}
		return apiClient.get(`/api/v1/teams/${uuid}`);
	},
};

export const identityService = {
	getMe() {
		return apiClient.get(`/api/v1/me`);
	},
};
