<template>
	<div class="v-station-slide">
		<transition name="fade" mode="out-in" appear>
			<component v-if="slideComponent" :is="slideComponent" :slide="slide" :key="slide.uuid" :user="user" :class="`u-slide-color--${stationColor}`"></component>
			<div v-else class="c-content" :class="`u-station-color--${stationColor}`">
				<div class="c-bg"></div>
				<div class="u-container">
					<Waiting>
						<template v-slot:header>
							<h2 class="t-title">{{ waitTitle }}</h2>
							<div class="t-text">
								<p>{{ waitText }}</p>
							</div>
						</template>
					</Waiting>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import ChoosePlanetSlide from '../slides/ChoosePlanetSlide';
import QuestionsSlide from '../slides/QuestionsSlide';
import VoteSlide from '../slides/VoteSlide';
import HmwSlide from '../slides/HmwSlide';
import WhatIfSlide from '../slides/WhatIfSlide';
import ChooseValuesSlide from '../slides/ChooseValuesSlide';
import ImagesSlide from '../slides/ImagesSlide';
import SingleColorPlanetSlide from '../slides/SingleColorPlanetSlide';
import ScenarioSlide from '../slides/ScenarioSlide';
import Waiting from './Waiting';
import DiceSlide from '../slides/DiceSlide';
import NieuwsmakerSlide from '../slides/NieuwsmakerSlide';
import ResultsSlide from '../slides/ResultsSlide';
import TeamQuestionSlide from '../slides/TeamQuestionSlide';
import EvaluationQuestionsSlide from "../slides/EvaluationQuestionsSlide";
import TeamSlide from "../slides/TeamSlide";

export default {
	name: 'StationSlide',
	components: {
		ChoosePlanetSlide,
		QuestionsSlide,
		VoteSlide,
		HmwSlide,
		WhatIfSlide,
		ChooseValuesSlide,
		ImagesSlide,
		Waiting,
		SingleColorPlanetSlide,
		ScenarioSlide,
		DiceSlide,
		NieuwsmakerSlide,
		ResultsSlide,
		TeamQuestionSlide,
		EvaluationQuestionsSlide,
		TeamSlide,
	},
	props: {
		slide: {},
		user: {},
	},
	computed: {
		isFirstSlide() {
			//TODO: dit mooier implementeren
			return this.slide.order == 1;
		},
		slideComponent() {
			const type = this.slide?.type;

			// App niet stuk laten gaan bij andere type slides
			if (!this.$options.components[type]) {
				return false;
			}

			//lelijk, maar even de snelste oplossing voor nu
			if (type == 'ScenarioSlide' && !this.slide.settings.assignment) {
				return false;
			}

			return type;
		},
		station() {
			if (this.slide) {
				return this.$actions.getStation(this.slide.station_uuid);
			} else {
				return false;
			}
		},
		stationColor() {
			if (this.station) {
				return this.station.settings?.color;
			} else {
				return 'purple-orange';
			}
		},
		waitTitle() {
			if (this.slide) {
				const title = this.slide?.settings?.waiting?.title;

				if (title) {
					return title;
				}
			}

			if (this.station) {
				return `Welkom bij ${this.station.name.toLowerCase()}!`;
			} else {
				return '';
			}
		},
		waitText() {
			if (this.slide) {
				const text = this.slide?.settings?.waiting?.text;

				if (text) {
					return text;
				}
			}

			return 'Ben je er klaar voor? We gaan zo beginnen.';
		},
	},
};
</script>

<style></style>
