var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "c-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-content" },
      [
        _vm.settings.questions
          ? _c("Questions", {
              attrs: {
                settings: _vm.settings,
                "post-url": "/api/v1/student/questions/2",
                "storage-prefix": "second-"
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }