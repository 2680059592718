var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-app-header" },
    [
      _c("img", {
        staticClass: "c-app-header__title",
        attrs: { src: _vm.logo, alt: _vm.appName + " logo" }
      }),
      _vm._v(" "),
      _vm.showMenu
        ? [
            _c(
              "button",
              {
                staticClass: "c-hamburger",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toggleMenu.apply(null, arguments)
                  }
                }
              },
              [_c("span"), _c("span"), _c("span")]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }