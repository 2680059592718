<template>
	<div class="c-sidebar c-sidebar--video">
		<div class="c-sidebar__header">
			<h3 class="c-sidebar__title">{{ title }}</h3>
			<div class="c-sidebar__description">
				{{ description }}
			</div>
		</div>
		<div class="c-sidebar__items">
			<button
				class="c-sidebar-video"
				v-bind:class="{ 'is-active': currentStep.video.id == video.id }"
				type="button"
				v-for="video in videos"
				:key="video.id"
				v-on:click="$emit('video-selected', video)"
			>
				<img class="c-sidebar-video__thumb" v-bind:src="video.thumbnail" alt="" />
			</button>
		</div>
	</div>
</template>

<script>
import sidebarMixin from '../mixins/sidebar';

export default {
	name: 'VideoSidebar',
	mixins: [sidebarMixin],
	props: {
		currentStep: Object,
	},
	computed: {
		videos() {
			return this.category.videos;
		},
	},
};
</script>

<style></style>
