var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade", mode: "out-in", appear: "" } },
    [
      _vm.menuOpen
        ? _c("div", { staticClass: "c-menu", class: _vm.stationColor }, [
            _c("div", { staticClass: "c-bg" }),
            _vm._v(" "),
            _c("div", { staticClass: "c-app-header" }, [
              _c("img", {
                staticClass: "c-app-header__title",
                attrs: { src: _vm.logo, alt: _vm.appName + " logo" }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "c-hamburger",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleMenu.apply(null, arguments)
                    }
                  }
                },
                [_c("span"), _c("span"), _c("span")]
              )
            ]),
            _vm._v(" "),
            _c(
              "nav",
              { staticClass: "c-menu__nav" },
              [
                _vm.showAll
                  ? [
                      _c(
                        "a",
                        {
                          staticClass: "c-menu__nav-item",
                          attrs: { href: "/student/profiel" }
                        },
                        [_vm._v("Profiel")]
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "c-menu__nav-item",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.logout.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Uitloggen")]
                )
              ],
              2
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }