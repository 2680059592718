var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-video-editor-step",
      class: { "is-active": _vm.ids.currentId == _vm.step.id }
    },
    [
      _c(
        "div",
        { staticClass: "c-video-editor-step__frame" },
        _vm._l(_vm.subSteps, function(subStep) {
          return _c(
            "button",
            {
              key: subStep.id,
              class: [
                "c-video-editor-step__" + subStep.type,
                _vm.ids.currentSubId == subStep.id ? "is-active" : "",
                _vm.complete(subStep.type) ? "is-complete" : ""
              ],
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("current-step", {
                    currentId: _vm.step.id,
                    currentSubId: subStep.id
                  })
                }
              }
            },
            [
              subStep.type == "video"
                ? [
                    _vm.step.video.thumbnail
                      ? _c("img", {
                          staticClass: "c-video-editor-step__thumb",
                          attrs: { src: _vm.step.video.thumbnail, alt: "" }
                        })
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              subStep.type == "recording"
                ? [_vm.step.recording ? _c("SoundWave") : _vm._e()]
                : _vm._e()
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "c-video-editor-step__label" }, [
        _vm._v(_vm._s(_vm.step.label))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }