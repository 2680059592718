<template>
	<div class="c-planet-color-picker">
		<div class="c-color-picker">
			<div class="c-color-picker-radio" v-for="color in colorOptions[0]" :key="color.id">
				<input type="radio" v-model="color1" v-on:change="planetColorsChanged" v-bind:value="color.id" name="color1" />
				<label>
					<span class="c-color-picker-radio__color" v-bind:style="{ backgroundColor: color.value }"></span>
				</label>
			</div>
		</div>

		<div class="c-planet">
			<Planet v-bind:colors="colors" v-bind:showMoons="false"></Planet>
		</div>

		<div class="c-color-picker">
			<div class="c-color-picker-radio" v-for="color in colorOptions[1]" :key="color.id">
				<input type="radio" v-model="color2" v-on:change="planetColorsChanged" v-bind:value="color.id" name="color2" />
				<label>
					<span class="c-color-picker-radio__color" v-bind:style="{ backgroundColor: color.value }"></span>
				</label>
			</div>
		</div>
	</div>
</template>

<script>
import Planet from '../components/Planet.vue';

export default {
	name: 'PlanetColorPicker',
  props: [
      'value',
  ],
	components: {
		Planet,
	},
	data() {
		return {
			color1: '1',
			color2: '8',
			colorOptions: [
				[
					{
						id: '1',
						value: '#39cbb0',
					},
					{
						id: '2',
						value: '#770101',
					},
					{
						id: '3',
						value: '#1a6959',
					},
					{
						id: '4',
						value: '#fa9c25',
					},
					{
						id: '5',
						value: '#4f008f',
					},
					{
						id: '6',
						value: '#711153',
					},
				],
				[
					{
						id: '7',
						value: '#39cbb0',
					},
					{
						id: '8',
						value: '#770101',
					},
					{
						id: '9',
						value: '#1a6959',
					},
					{
						id: '10',
						value: '#fa9c25',
					},
					{
						id: '11',
						value: '#4f008f',
					},
					{
						id: '12',
						value: '#711153',
					},
				],
			],
		};
	},
	computed: {
		colors() {
			const color1 = this.colorOptions[0].find(color => color.id == this.color1);
			const color2 = this.colorOptions[1].find(color => color.id == this.color2);

			return [color1.value, color2.value];
		},
	},
	methods: {
		planetColorsChanged() {
			this.$emit('planet-colors', this.colors);
		},
	},
	mounted() {
	  if(this.value.length == 2){
      const color1 = this.value[0];
      const color2 = this.value[1];

      this.color1 = this.colorOptions[0].find(x => x.value == color1)?.id;
      this.color2 = this.colorOptions[1].find(x => x.value == color2)?.id;
    }
		this.planetColorsChanged();
	},
};
</script>

<style scoped>
.c-planet{
	height: 300px;
}
</style>
