var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-content" }, [
    _c("div", { staticClass: "c-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "u-container" },
      [
        _c("TeamLeaderView", {
          attrs: { user: _vm.user },
          scopedSlots: _vm._u([
            {
              key: "leader-content",
              fn: function() {
                return [
                  _c(
                    "form",
                    {
                      attrs: { autocomplete: "off" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm.step == 1
                        ? _c("div", { key: _vm.step }, [
                            _c("h2", { staticClass: "t-title" }, [
                              _vm._v("Vul de naam in van jullie planeet")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "t-text" }, [
                              _c("p", [
                                _vm._v(
                                  "Vul gezamenlijk met je teamleden welke naam jullie planeet gaat vertegenwoordigen."
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "c-input" }, [
                              _c("label", { attrs: { for: "planetName" } }, [
                                _vm._v("Planeetnaam")
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.planetName,
                                    expression: "form.planetName"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  id: "planetName",
                                  placeholder: "Planeetnaam",
                                  autocomplete: "off"
                                },
                                domProps: { value: _vm.form.planetName },
                                on: {
                                  keyup: function($event) {
                                    _vm.form.planetName = $event.target.value
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "planetName",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "c-button c-button--full c-button--main",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.disabled
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.next()
                                  }
                                }
                              },
                              [
                                _vm._v("\n\t\t\t\t\t\t\tVolgende "),
                                _c("svg", { staticClass: "icon icon_arrow" }, [
                                  _c("use", {
                                    attrs: { "xlink:href": "#icon_arrow" }
                                  })
                                ])
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.step == 2
                        ? _c(
                            "div",
                            { key: _vm.step },
                            [
                              _c("h2", { staticClass: "t-title" }, [
                                _vm._v("Kies de kleur van jullie planeet")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "t-text" }, [
                                _c("p", [
                                  _vm._v(
                                    "Kies gezamenlijk met je teamleden welke kleuren jullie planeet krijgt."
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("PlanetColorPicker", {
                                on: {
                                  "planet-colors": function($event) {
                                    _vm.form.planetColors = $event
                                  }
                                },
                                model: {
                                  value: _vm.form.planetColors,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "planetColors", $$v)
                                  },
                                  expression: "form.planetColors"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "c-button c-button--full c-button--main",
                                  attrs: {
                                    type: "submit",
                                    disabled: _vm.disabled
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.buttonText) +
                                      " "
                                  ),
                                  _c(
                                    "svg",
                                    { staticClass: "icon icon_arrow" },
                                    [
                                      _c("use", {
                                        attrs: { "xlink:href": "#icon_arrow" }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm.step == 3
                        ? _c(
                            "div",
                            { key: _vm.step },
                            [
                              _c("Waiting", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "header",
                                    fn: function() {
                                      return [
                                        _c("h2", { staticClass: "t-title" }, [
                                          _vm._v("Gelukt!")
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "t-text" }, [
                                          _c("p", [
                                            _vm._v(
                                              "Jullie naam en kleur zijn verstuurd."
                                            )
                                          ])
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "follower-content",
              fn: function() {
                return [
                  _c("div", { staticClass: "t-text" }, [
                    _c("p", [
                      _vm._v(
                        "Kies gezamenlijk met je teamleden welke naam en kleur jullie planeet gaat vertegenwoordigen. Éen van je teamleden kan nu de planeetnaam en kleur invoeren."
                      )
                    ])
                  ])
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }