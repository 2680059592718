<template>
	<div class="c-editor-preview">
		<template v-if="!presentation">
			<button class="c-editor-preview__close c-button c-button--main" type="button" v-on:click="close">sluiten</button>

			<div class="c-editor-preview__buttons">
				<button class="c-editor-preview__play c-button c-button--main" type="button" v-on:click="playing = !playing">{{ playing ? 'stoppen' : 'afspelen' }}</button>
				<button class="c-button c-button--main" type="button" v-on:click="submit">nieuwsmaker insturen</button>
			</div>
		</template>

		<div class="c-editor-preview__wrapper">
			<div class="c-editor-preview__frame">
				<PreviewTitle v-show="currentPart == 'title_0'" v-bind:title="title" v-bind:playing="playing" v-bind:active="currentPart == 'title_0'" v-on:ended="nextPart()"></PreviewTitle>

				<PreviewTitle v-show="currentPart == 'title_1'" v-bind:title="title" v-bind:playing="playing" v-bind:active="currentPart == 'title_1'" v-on:ended="nextPart()"></PreviewTitle>

				<PreviewRecording
					v-bind:recording="getRecording(0)"
					v-bind:playing="playing"
					v-bind:active="currentPart == 'recording_0'"
					v-show="currentPart == 'recording_0'"
					v-on:ended="nextPart()"
				></PreviewRecording>

				<PreviewRecording
					v-bind:recording="getRecording(1)"
					v-bind:playing="playing"
					v-bind:active="currentPart == 'recording_1'"
					v-show="currentPart == 'recording_1'"
					v-on:ended="nextPart()"
				></PreviewRecording>

				<PreviewVideos v-show="currentPart == 'videos'" v-bind:videos="videos" v-bind:playing="playing" v-bind:active="currentPart == 'videos'" v-on:ended="nextPart()"></PreviewVideos>
			</div>
		</div>

		<audio class="c-editor-preview__music" v-bind:src="music" ref="music" preload="auto" loop></audio>
	</div>
</template>

<script>
// Helpers etc.
import { getters } from '../store.js';

import PreviewRecording from './PreviewRecording.vue';
import PreviewTitle from './PreviewTitle.vue';
import PreviewVideos from './PreviewVideos.vue';

export default {
	name: 'EditorPreview',
	components: {
		PreviewRecording,
		PreviewTitle,
		PreviewVideos,
	},
	props: {
		presentation: {
			type: Boolean,
			default: false,
		},
		answer: {
			type: Array,
			default() {
				return [];
			},
		},
		remotePlaying: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			order: ['title_0', 'recording_0', 'videos', 'recording_1', 'title_1'],
			currentIndex: 0,
			playing: false,
		};
	},
	watch: {
		playing: {
			handler(val) {
				this.$nextTick(() => {
					if (!val) {
						this.reset();
						return;
					}

					this.playMusic();
				});
			},
			immediate: true,
		},
		remotePlaying(val) {
			if (!this.presentation) {
				return;
			}

			this.playing = val;
		},
	},
	computed: {
		currentPart() {
			return this.order[this.currentIndex];
		},
		result() {
			if (this.answer.length) {
				return this.answer;
			}

			return getters.result();
		},
		music() {
			const music = this.result.find(part => part.id == 'music');

			return music.music.url;
		},
		title() {
			const title = this.result.find(part => part.id == 'title');
			return title.text;
		},
		videos() {
			const videos = this.result.find(part => part.id == 'videos');
			return videos.videos;
		},
	},
	methods: {
		getRecording(index) {
			const recordings = this.result.find(part => part.id == 'recordings');
			return recordings.recordings[index];
		},
		close() {
			this.$emit('close');
		},
		reset() {
			this.playing = false;
			this.stopMusic();
			this.currentIndex = 0;
		},
		playMusic() {
			this.$refs.music.play();
		},
		stopMusic() {
			this.$refs.music.currentTime = 0;
			this.$refs.music.pause();
		},
		nextPart() {
			console.log('in next part');
			if (!this.playing) {
				return;
			}

			if (this.currentIndex < this.order.length - 1) {
				this.currentIndex++;
			} else {
				this.reset();
			}
		},
		submit() {
			this.$eventBus.$emit('nieuwsmaker-submit');
		},
	},
	mounted() {
		this.$refs.music.volume = 0.23;
	},
};
</script>

<style></style>
