<template>
	<div>
		<div class="c-bg"></div>
		<div class="c-content">
			<div class="u-container">
				<template v-if="!step && settings.intro">
					<h2 class="t-title">{{ settings.intro.title }}</h2>
					<div class="t-text">
						{{ settings.intro.text }}
					</div>
					<button class="c-button c-button--full c-button--main" type="button" v-on:click="next">
						{{ settings.intro.buttonText }} <svg class="icon icon_arrow"><use xlink:href="#icon_arrow"></use></svg>
					</button>
				</template>
				<template v-else-if="!submitted">
					<form @submit.prevent="submit">
						<transition name="fade" mode="out-in">
							<!-- <component v-bind:is="questionComponent" v-bind:question="currentQuestion" v-bind:steps="steps" :key="currentQuestion.id">
								<template v-slot:footer>
									<button type="button" v-on:click="prev" v-if="steps.currentStep > 1">Vorige</button>
									<button type="submit" v-if="steps.currentStep == steps.totalSteps">Afronden</button>
									<button type="button" v-on:click="next" v-else>Volgende vraag</button>
								</template>
							</component> -->
							<Question :question="currentQuestion" :answers="previousAnswers" :steps="steps" :submitting="submitting" :key="currentQuestion.id" @prev="prev" @next="next" @update="update" />
						</transition>
					</form>
				</template>
				<Waiting v-else> </Waiting>
			</div>
		</div>
	</div>
</template>

<script>
import Question from '../components/Question';
import Waiting from '../views/Waiting';

export default {
	name: 'QuestionsSlide',
	components: {
		Waiting,
		Question,
	},
	props: {
		slide: Object,
		user: null,
    storagePrefix: {
      default: '',
    }
	},
	data() {
		return {
			step: null,
			submitting: false,
			submitted: false,
			data: {},
		};
	},
	computed: {
		steps() {
			return {
				currentStep: this.step,
				totalSteps: this.settings.questions.length,
			};
		},
		currentQuestion() {
			return this.settings.questions[this.step - 1] || {};
		},
    answersKey(){
		  return this.storagePrefix + 'answers-'+this.slide.uuid;
    },
    //disable previous answers for https://september.eu.teamwork.com/#/tasks/14024986
    previousAnswers(){
		  return {};//JSON.parse(localStorage.getItem(this.answersKey)) || {};
    },
		settings() {
			return this.slide.settings;
		},
	},
	mounted() {
		if (!this.settings.intro) {
			this.step = 1;
		}
	},
	methods: {
		prev() {
			if (this.step > 1) {
				this.step--;
			}
		},
		next() {
			if (this.step === null) {
				this.step = 1;
				return;
			}

			if (this.step == this.settings.questions.length) {
				return;
			}

			this.step++;
		},
		submit() {
		  localStorage.setItem(this.answersKey, JSON.stringify(this.data));
			console.log('submit');
			setTimeout(() => {
				console.log('submitted');
				this.submitted = true;
			}, 600);
			this.$emit('submit', this.data);
		},

		update(name, value) {
			this.$set(this.data, name, value);
		},
	},
};
</script>

<style></style>
