var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-nm-video" }, [
    _c("video", {
      ref: "video",
      staticClass: "c-nm-video__video",
      attrs: {
        src: _vm.video.url,
        disablepictureinpicture: "",
        disableremoteplayback: "",
        controls: ""
      },
      on: {
        play: function($event) {
          return _vm.$emit("ply", _vm.video.id)
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }