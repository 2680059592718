var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-title-editor" }, [
    _c("div", { staticClass: "c-title-editor__wrapper" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentStep.title,
            expression: "currentStep.title"
          }
        ],
        ref: "textarea",
        staticClass: "c-title-editor__textarea",
        attrs: { cols: "30", rows: "10", placeholder: "Titel" },
        domProps: { value: _vm.currentStep.title },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.currentStep, "title", $event.target.value)
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }