var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-selector-preview" }, [
    _c(
      "button",
      {
        staticClass: "c-selector-preview__close",
        attrs: { type: "button" },
        on: { click: _vm.close }
      },
      [_vm._v("sluiten")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "c-selector-preview__wrapper" }, [
      _c("div", { staticClass: "c-selector-preview__frame" }, [
        _vm.currentVideo.url
          ? _c("video", {
              ref: "video",
              staticClass: "c-selector-preview__video",
              attrs: {
                src: _vm.currentVideo.url,
                disablepictureinpicture: "",
                disableremoteplayback: "",
                autoplay: "",
                preload: "auto"
              },
              on: { ended: _vm.ended }
            })
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }