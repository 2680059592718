<template>
	<div class="c-app-container" v-bind:class="appClass">
		<!-- <span>Last event: {{$actions.getLastEventDate()}}</span> -->
		<template v-if="loading">
			<Loading></Loading>
		</template>
		<template v-else-if="noTeam">
			<Menu v-bind:showAll="showDashboard" :slide="slide"></Menu>
			<AppHeader v-bind:showMenu="showMenu"></AppHeader>
			<transition name="fade" mode="out-in" appear>
				<div class="v-welcome">
					<div class="c-bg"></div>
					<div class="u-container">
						<h2 class="t-title">Nog geen team.</h2>
						<div class="t-text">
							<p>Je bent nog niet aan een team toegewezen. Vraag je docent om hulp.</p>
						</div>
					</div>
				</div>
			</transition>
		</template>
    <template v-else-if="noStationStarted">
      <Menu v-bind:showAll="showDashboard" :slide="slide"></Menu>
      <AppHeader v-bind:showMenu="showMenu"></AppHeader>
      <transition name="fade" mode="out-in" appear>
        <div class="v-welcome">
          <div class="c-bg"></div>
          <div class="u-container">
            <h2 class="t-title">Bedankt voor het invullen!</h2>
            <div class="t-text">
              <p>Je bent klaar met het invullen van de vragen. Wanneer de lessen beginnen kun je hier meedoen. </p>
            </div>
          </div>
        </div>
      </transition>
    </template>
		<template v-else>
			<Menu v-bind:showAll="showDashboard" :slide="slide"></Menu>
			<AppHeader></AppHeader>
			<transition name="fade" mode="out-in" appear>
				<StationSlide v-if="showSlide" :slide="slide" :user="user"></StationSlide>
				<Dashboard v-else-if="showDashboard" :user="user"></Dashboard>
				<Idle v-else></Idle>
			</transition>
		</template>
	</div>
</template>

<script>
// Helpers
import { cmsService, identityService } from '../../components/services';
import { catchUp } from '../../components/state-calculator';

// Components
import AppHeader from './components/AppHeader';
import Menu from './components/Menu';

// Views
import Loading from './views/Loading';
import Idle from './views/Idle';
import StationSlide from './views/StationSlide';
import Dashboard from './views/Dashboard';

export default {
	name: 'App',
	components: {
		AppHeader,
		Menu,
		Loading,
		Idle,
		Dashboard,
		StationSlide,
	},
	data() {
		return {
			user: null,
			loading: true,
		};
	},
	computed: {
    noStationStarted(){
      const state = this.$actions.getClass()?.state || {};
      const startedStations = Object.keys(state);
      return startedStations.length <= 0;
    },
		teams() {
			return this.$actions.getTeams();
		},
		team() {
			return this.teams.find(x => x.uuid == this.user?.team_uuid);
		},
		noTeam() {
			return !this.user?.team_uuid;
		},
		showDashboard() {
			return !this.showSlide; // ?
		},
		showSlide() {
			return !!this.slide;
		},
		showMenu() {
			return this.showDashboard;
		},
		slide() {
			return this.$actions.getSlide();
		},
		appClass() {
			return this.slide?.type ? `c-app-container--${this.slide?.type}` : '';
		},
	},
	mounted() {
		document.addEventListener('class.slide-subcommand-event', evt => {
			this.$eventBus.$emit(evt.data.name, evt.data.data);
		});

		this.$eventBus.$on('set-variable', evt => {
			this.$actions.setVariable(evt.name, evt.value);
		});

		Promise.all([
			cmsService.getStations().then(res => {
				this.$actions.setStations(res.data.stations);
			}),
			cmsService.getSlides().then(response => {
				this.$actions.setSlides(response.data.slides);
			}),
			cmsService.getValues().then(response => {
				this.$actions.setValues(response.data.values);
			}),
			cmsService.getValueTypes().then(response => {
				this.$actions.setValueTypes(response.data);
			}),
			cmsService.getTeams().then(response => {
				this.$actions.setTeams(response.data);
			}),
			identityService.getMe().then(response => {
				this.user = response.data;
				this.$actions.setClass(this.user.class);
			}),
		]).then(x => {
			catchUp().finally(x => {
				this.loading = false;
			});
		});

		document.addEventListener('student.team-leader-challenged', () => {
			window.axios.post(`/api/v1/app/nominate`);
		});

		document.addEventListener('team.elect-team-leader', evt => {
			const team = evt.data;

			console.log('team.elect-team-leader', {team});
			if(team.uuid != this.team.uuid){
				return;
			}

			if (this.team) {
				// delete this.team.leader;
				this.$set(this.team, 'leader', null);
			}

			window.axios.post('/api/v1/app/nominate');
		});



		document.addEventListener('class.student-updated', evt => {
			if(this.user.uuid != evt.data.uuid){
				return;
			}

			if (evt.data.team_uuid != this.user.team_uuid) {
				window.location = window.location;
			}
			this.$set(this, 'user', evt.data);
		});
	},
};
</script>

<style></style>
