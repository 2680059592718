<template>
	<div class="v-nieuwsmaker">
		<template v-if="isPhone">
			<div class="u-slide">
				<div class="c-bg"></div>
				<div class="u-container">
					<Waiting>
						<template v-slot:header>
							<h2 class="t-title">Pak je laptop erbij</h2>
							<div class="t-text">
								<p>De nieuwsmaker werkt alleen op een laptop</p>
							</div>
						</template>
						<template v-slot:box>
							<div class="u-box c-leader-box">
								<div class="c-leader-box__text">Log in op je laptop</div>
								<div class="c-leader-box__help">Gebruik je {{appName}} account om de nieuwsmaker te spelen</div>
							</div>
						</template>
					</Waiting>
				</div>
			</div>
		</template>
		<template v-else>
			<template v-if="loading">
				<div class="u-container">
					<h2 class="t-title">Even geduld...</h2>
				</div>
			</template>
			<template v-else-if="!playable">
				<div class="u-container">
					<h2 class="t-title">Helaas, je webbrowser is niet geschikt voor de nieuwsmaker.</h2>
				</div>
			</template>
			<template v-else-if="submitted">
				<div class="c-nieuwsmaker-success">
					<h2 class="t-title">Gelukt!</h2>
					<div class="t-text">
						<p>Jullie nieuwsmaker is verstuurd.</p>
					</div>
					<div class="u-box c-leader-box">
						<div class="c-leader-box__text">Log weer in op je telefoon</div>
						<div class="c-leader-box__help">Gebruik je {{appName}} account om direct door te spelen</div>
					</div>
				</div>
			</template>
			<template v-else>
				<transition name="fade" mode="out-in" appear>
					<component v-if="view" v-bind:is="view" v-on:view-finished="viewFinished($event)"></component>
				</transition>
			</template>
		</template>
	</div>
</template>

<script>
// Components
import VideoSelector from './views/VideoSelector';
import VideoEditor from './views/VideoEditor';
import Intro from './views/IntroVideo';
import Waiting from '../views/Waiting.vue';

// Helpers etc.
import { getters, mutations } from './store.js';
import cmsData from './mockup-data'; // temp

const breakpoint = window.matchMedia('(max-width:767px)');

export default {
	name: 'Nieuwsmaker',
	components: {
		Intro,
		VideoSelector,
		VideoEditor,
		Waiting,
	},
	props: {
		slide: Object,
	},
	data() {
		return {
			loading: true,
			isPhone: false,
			submitted: false,
			appName: window.settings.name,
			scenarios: [],
		};
	},
	computed: {
		...getters,
		view() {
			if (!this.currentState in this.states) {
				return false;
			}

			return this.states[this.currentState];
		},
		playable() {
			if (navigator.mediaDevices === undefined || !window.MediaRecorder) {
				return false;
			}

			return true;
		},
		scenario(){
			const scenario = this.$actions.getVariable('scenario');
			return this.scenarios.find(x => x.title === scenario)?.settings;
		},
	},
	methods: {
		...mutations,
		viewFinished(e) {
			if (!e.next) {
				return;
			}

			this.setCurrentState(e.next);
		},
		beforeUnload(e) {
			e.returnValue = `Let op: je voortgang zal verloren gaan! Weet je zeker dat je weg wilt?`;
		},
		submitItem() {
			window.axios
				.post(`/api/v1/answer/${this.slide.uuid}`, {
					answer: JSON.stringify(this.result),
				})
				.then(() => {
					localStorage.removeItem('nieuwsmaker_selector');
					localStorage.removeItem('nieuwsmaker_editor');
					this.submitted = true;
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {});
		},
		checkBreakpoint() {
			if (breakpoint.matches === true) {
				this.isPhone = true;
			} else {
				this.isPhone = false;
			}
		},
	},
	// created() {
	// 	this.$watch(
	// 		() => this.result,
	// 		value => {
	// 			console.log('result changed to:', value);

	// 			localStorage.setItem('nieuwsmaker', JSON.stringify(value));
	// 		},
	// 		{
	// 			deep: true,
	// 		},
	// 	);
	// },
	watch: {
		scenario(value){
			this.setCmsData(value);
		},
	},
	mounted() {

		this.$eventBus.$on('nieuwsmaker-submit', this.submitItem);

		window.axios.get('/api/v1/nieuwsmaker')
			.then(({data}) => {
				this.scenarios = data.scenarios;
				this.loading = false;
			});

		// window.addEventListener('beforeunload', this.beforeUnload);

		breakpoint.addListener(this.checkBreakpoint);
		this.checkBreakpoint();
	},
	beforeDestroy() {
		this.$eventBus.$off('nieuwsmaker-submit', this.submitItem);
		breakpoint.removeListener(this.checkBreakpoint);
		// window.removeEventListener('beforeunload', this.beforeUnload);
	},
};
</script>

<style></style>
