<template>
	<div>
		<div class="c-big-planet">
			<TeamPlanet v-bind:team="team"></TeamPlanet>
			<div class="c-big-planet__name">{{ team.name }}</div>
		</div>
		<ul class="c-team">
			<li class="c-team__member" v-for="member in members" :key="member.uuid">
				{{ member.name }}
			</li>
		</ul>
	</div>
</template>

<script>
import TeamPlanet from './TeamPlanet.vue';

export default {
	name: 'Team',
	components: {
		TeamPlanet,
	},
	props: {
		user: Object,
	},
	data() {
		return {};
	},
	methods: {},
	computed: {
		team() {
			return this.$actions.getTeam(this.user?.team_uuid) || {};
		},
		members() {
			return this.team?.students;
		},
	},
};
</script>

<style scoped>
.c-big-planet {
	width: 100%;
}
.planet-3d {
	margin: 0 auto;
	width: 200px;
	height: 200px;
}
</style>
