var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "dice", staticClass: "c-dice" }, [
    _c("button", { staticClass: "c-button c-button--main" }, [
      _vm._v("\n\t\tGooi opnieuw "),
      _c("svg", { staticClass: "icon icon_arrow" }, [
        _c("use", { attrs: { "xlink:href": "#icon_arrow" } })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }