var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-app-container u-slide-color--purple" },
    [
      _c("div", { staticClass: "c-bg" }),
      _vm._v(" "),
      _c("AppHeader", { attrs: { showMenu: false } }),
      _vm._v(" "),
      _c("QuestionsSlide", {
        attrs: { slide: _vm.slide, "storage-prefix": _vm.storagePrefix },
        on: { submit: _vm.submit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }