<template>
	<div class="c-video-editor-step" v-bind:class="{ 'is-active': ids.currentId == step.id }">
		<div class="c-video-editor-step__frame">
			<button
				v-for="subStep in subSteps"
				type="button"
				v-bind:class="[`c-video-editor-step__${subStep.type}`, ids.currentSubId == subStep.id ? 'is-active' : '', complete(subStep.type) ? 'is-complete' : '']"
				:key="subStep.id"
				v-on:click="$emit('current-step', { currentId: step.id, currentSubId: subStep.id })"
			>
				<template v-if="subStep.type == 'video'">
					<img class="c-video-editor-step__thumb" v-bind:src="step.video.thumbnail" v-if="step.video.thumbnail" alt="" />
				</template>

				<template v-if="subStep.type == 'recording'">
					<SoundWave v-if="step.recording"></SoundWave>
				</template>
			</button>

			<!-- <template v-for="subStep in subSteps">
				<button type="button" class="c-video-editor-step__video" v-if="subStep.type == 'video'" :key="subStep.id"></button>
				<button type="button" class="c-video-editor-step__recording" v-if="subStep.type == 'recording'" :key="subStep.id"></button>
			</template> -->
		</div>
		<div class="c-video-editor-step__label">{{ step.label }}</div>
	</div>
</template>

<script>
import SoundWave from './SoundWave';

export default {
	name: 'VideoEditorMultiStep',
	components: {
		SoundWave,
	},
	props: {
		step: Object,
		ids: Object,
	},
	computed: {
		subSteps() {
			return this.step.steps.reverse();
		},
	},
	methods: {
		complete(type) {
			if (type == 'video') {
				return this.step.video.thumbnail;
			}

			if (type == 'recording') {
				return this.step.recording;
			}
		},
	},
};
</script>

<style></style>
