<template>
	<div class="c-content">
		<div class="c-bg"></div>
		<div class="u-container">
			<TeamLeaderView :user="user">
				<template v-slot:leader-content>
					<template v-if="!submitted">
						<h2 class="t-title">{{ settings.title }}</h2>
						<div class="t-text">
							{{ settings.text }}
						</div>

						<form v-on:submit.prevent="submit">
							<div class="c-radio-button" v-for="(option, index) in options" :key="index">
								<input type="radio" :id="`vote_${index}`" :value="option.team" v-model="vote" />
								<label :for="`vote_${index}`">{{ option.text }}</label>
							</div>

							<div class="c-form-footer">
								<button type="submit" class="c-button c-button--main" data-button-next :disabled="submitting">Verzenden</button>
							</div>
						</form>
					</template>
					<template v-else>
						<Waiting>
							<template v-slot:header>
								<h2 class="t-title">Gelukt!</h2>
								<div class="t-text">
									<p>Jullie stem is verstuurd.</p>
								</div>
							</template>
						</Waiting>
					</template>
				</template>
			</TeamLeaderView>
		</div>
	</div>
</template>

<script>
import Question from '../components/Question';
import TeamLeaderView from '../views/TeamLeaderView';
import Waiting from '../views/Waiting';

export default {
	name: 'VoteSlide',
	components: {
		Question,
		TeamLeaderView,
		Waiting,
	},
	props: {
		slide: Object,
		user: null,
	},
	data() {
		return {
			submitting: false,
			submitted: false,
			vote: null,
		};
	},
	computed: {
		teams() {
			return this.$actions.getTeams();
		},
		team() {
			return this.teams.find(x => x.uuid == this.user?.team_uuid);
		},
		leader() {
			return this.team?.leader || {};
		},
		leading() {
			return this.leader?.uuid == this.user?.uuid;
		},
		options() {
			return this.$actions.getAnswers(this.subject, this.team?.uuid);
		},
		settings() {
			return this.slide.settings;
		},
		subject() {
			return this.slide.settings.subject;
		},
	},
	methods: {
		submit() {
			this.submitting = true;

			window.axios
				.post(`/api/v1/vote/${this.slide.uuid}`, {
					vote: this.vote,
				})
				.then(() => {
					this.submitted = true;
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					this.submitting = false;
				});
		},
	},
  mounted() {
	  const votes = this.team?.settings?.votes || {};
	  this.vote = votes[this.settings?.subject] || null;
  },
};
</script>

<style scoped>
.c-form-footer {
	margin-top: 24px;
}
</style>
