<template>
	<div class="s-dice">
		<div class="c-bg"></div>
		<div class="u-container">
			<template v-if="step == 1">
				<h2 class="t-title">Verhaalstenen</h2>
				<div class="t-text">
          Gooi de dobbelstenen. Denk na over wat je wil vertellen (vragen op het presentatiescherm). Vertel om de beurt.
				</div>
				<button class="c-button c-button--full c-button--main" type="button" v-on:click="step++">
					Gooi de verhaalstenen <svg class="icon icon_arrow"><use xlink:href="#icon_arrow"></use></svg>
				</button>
			</template>
			<template v-else>
				<Dice></Dice>
			</template>
		</div>
	</div>
</template>

<script>
import TeamLeaderView from '../views/TeamLeaderView';
import Dice from '../components/Dice.vue';

export default {
	name: 'DiceSlide',
	components: {
		TeamLeaderView,
		Dice,
	},
	props: {
		slide: Object,
		user: null,
		teams: null,
	},
	data() {
		return {
			step: 1,
		};
	},
};
</script>

<style>
/* .s-dice {
    height: 100vh;
  } */
</style>
