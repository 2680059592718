var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-content" }, [
    _c("div", { staticClass: "c-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "u-container" },
      [
        _c("TeamLeaderView", {
          attrs: { user: _vm.user },
          scopedSlots: _vm._u([
            {
              key: "leader-content",
              fn: function() {
                return [
                  !_vm.submitted
                    ? [
                        _c("h2", { staticClass: "t-title" }, [
                          _vm._v(_vm._s(_vm.settings.title))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "t-text" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.settings.text) +
                              "\n\t\t\t\t\t"
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.submit.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _vm._l(_vm.options, function(option, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "c-radio-button" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.vote,
                                        expression: "vote"
                                      }
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "vote_" + index
                                    },
                                    domProps: {
                                      value: option.team,
                                      checked: _vm._q(_vm.vote, option.team)
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.vote = option.team
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    { attrs: { for: "vote_" + index } },
                                    [_vm._v(_vm._s(option.text))]
                                  )
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "c-form-footer" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "c-button c-button--main",
                                  attrs: {
                                    type: "submit",
                                    "data-button-next": "",
                                    disabled: _vm.submitting
                                  }
                                },
                                [_vm._v("Verzenden")]
                              )
                            ])
                          ],
                          2
                        )
                      ]
                    : [
                        _c("Waiting", {
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c("h2", { staticClass: "t-title" }, [
                                    _vm._v("Gelukt!")
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "t-text" }, [
                                    _c("p", [
                                      _vm._v("Jullie stem is verstuurd.")
                                    ])
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        })
                      ]
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }