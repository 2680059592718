import Vue from 'vue';
import '../components/store';
import App from './vue/App';
import 'alpinejs';
import { setAppHeight } from './utils';

import '../components/realtime';
import '../components/state-calculator';

import Questions from './vue/Questions';

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const appElement = document.querySelector('#app');

if (appElement) {
	const app = new Vue({
		render: h => h(App),
	}).$mount(appElement);
}

const questionsSettings = document.querySelector('#questions_settings');

if (questionsSettings) {
	const app = new Vue({
		render: h =>
			h(Questions, {
				props: {
					settings: JSON.parse(questionsSettings.innerHTML),
				},
			}),
	}).$mount(questionsSettings);
}

setAppHeight();

// Temp: nieuwsmaker apart
import Nieuwsmaker from './vue/nieuwsmaker/App';
// Temp: nieuwsmaker apart

const nieuwsmaker = document.querySelector('#nieuwsmaker');

if (nieuwsmaker) {
	const app = new Vue({
		render: h => h(Nieuwsmaker),
	}).$mount(nieuwsmaker);
}
