var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-app-container", class: _vm.appClass },
    [
      _vm.loading
        ? [_c("Loading")]
        : _vm.noTeam
        ? [
            _c("Menu", {
              attrs: { showAll: _vm.showDashboard, slide: _vm.slide }
            }),
            _vm._v(" "),
            _c("AppHeader", { attrs: { showMenu: _vm.showMenu } }),
            _vm._v(" "),
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in", appear: "" } },
              [
                _c("div", { staticClass: "v-welcome" }, [
                  _c("div", { staticClass: "c-bg" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "u-container" }, [
                    _c("h2", { staticClass: "t-title" }, [
                      _vm._v("Nog geen team.")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "t-text" }, [
                      _c("p", [
                        _vm._v(
                          "Je bent nog niet aan een team toegewezen. Vraag je docent om hulp."
                        )
                      ])
                    ])
                  ])
                ])
              ]
            )
          ]
        : _vm.noStationStarted
        ? [
            _c("Menu", {
              attrs: { showAll: _vm.showDashboard, slide: _vm.slide }
            }),
            _vm._v(" "),
            _c("AppHeader", { attrs: { showMenu: _vm.showMenu } }),
            _vm._v(" "),
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in", appear: "" } },
              [
                _c("div", { staticClass: "v-welcome" }, [
                  _c("div", { staticClass: "c-bg" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "u-container" }, [
                    _c("h2", { staticClass: "t-title" }, [
                      _vm._v("Bedankt voor het invullen!")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "t-text" }, [
                      _c("p", [
                        _vm._v(
                          "Je bent klaar met het invullen van de vragen. Wanneer de lessen beginnen kun je hier meedoen. "
                        )
                      ])
                    ])
                  ])
                ])
              ]
            )
          ]
        : [
            _c("Menu", {
              attrs: { showAll: _vm.showDashboard, slide: _vm.slide }
            }),
            _vm._v(" "),
            _c("AppHeader"),
            _vm._v(" "),
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in", appear: "" } },
              [
                _vm.showSlide
                  ? _c("StationSlide", {
                      attrs: { slide: _vm.slide, user: _vm.user }
                    })
                  : _vm.showDashboard
                  ? _c("Dashboard", { attrs: { user: _vm.user } })
                  : _c("Idle")
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }