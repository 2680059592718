import { actions } from './store';

document.addEventListener('realtime.init', function(evt) {
	evt.channels.forEach(channel => {
		handleChannel(channel);
	});
});

function handleChannel(channel) {
	const channelType = channel.name.replace(/^(private-|presence-)?([^.]+)\..*$/, '$2');
	channel.subscription.bind_global(function(event, data) {
		const forwarded = maybeForwardEvent(channelType, event, data);

		handleEvent(event, data, forwarded);
	});
}

let oldState = {};

let state = {
	teams: [],
};

function maybeForwardEvent(channelType, event, data) {
	let forward = true;
	switch (event) {
		case 'elect-team-leader':
			//maybe handle specific event stuff
			break;

		case 'team-leader-challenged':
			break;


		case 'student-updated':
			break;

		case 'slide-subcommand-event':
			break;

		default:
			forward = false;
	}
	if (forward) {
		const evt = new Event(channelType + '.' + event);
		evt.data = data;
		document.dispatchEvent(evt);
	}
	return forward;
}

function handleEvent(event, data, forwarded) {
	console.debug('Handling event', event, data);
	actions.newEvent();

	switch (event) {
        case 'show-station-slide':
            actions.setSlide(data.slide);
            break;

        case 'show-station':
            actions.setStationUuid(data.station);
            break;

		case 'elected-team-leader':
			actions.selectTeamLeader(data.team, data.leader);
			break;

		case 'team-updated':
			actions.updateTeam(data);
			break;

		case 'class-updated':
			actions.setClass(data);
			break;

		case 'station-start-event':
			break;

		case 'station-stop-event':
			actions.setSlide(null);
			break;

		case 'pusher:subscription_succeeded':
			break;

		default:
			if (!forwarded) {
				console.error('unhandled event', event, data);
			}
	}
}

function handleEvents(evts) {
	for (let key in evts) {
		if (!evts.hasOwnProperty(key)) {
			continue;
		}
		const evt = evts[key];

		const forwarded = maybeForwardEvent('class', evt.name, evt.data);
		handleEvent(evt.name, evt.data, forwarded);
	}
}

let catchUpResolve = null;
export const catchUpPromise = new Promise((resolve, reject) => {
	catchUpResolve = resolve;
});

export function catchUp(uuid) {
	if (!uuid) {
		uuid = '';
	}

	console.log("Catching up");
	return window.axios.get(`/api/v1/catch-up${uuid ? '/' + uuid : ''}`).then(res => {
		setTimeout(()=> {
			handleEvents(res.data.events);
			console.warn("Catch up complete");
			if(catchUpResolve){
				catchUpResolve();
			}
		}, 0);

	});
}
