<template>
	<div class="c-app-header">
		<img :src="logo" :alt="appName + ' logo'" class="c-app-header__title" />
		<template v-if="showMenu">
			<button class="c-hamburger" type="button" v-on:click.prevent="toggleMenu"><span></span><span></span><span></span></button>
		</template>
	</div>
</template>

<script>
export default {
	name: 'AppHeader',
	data() {
		return {
			logo: window.settings.logo,
			appName: window.settings.name,
		};
	},
	props: {
		showMenu: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		toggleMenu() {
			this.$actions.toggleMenu();
		},
	},
};
</script>

<style></style>
