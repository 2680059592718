var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "s-dice" }, [
    _c("div", { staticClass: "c-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "u-container" },
      [
        _vm.step == 1
          ? [
              _c("h2", { staticClass: "t-title" }, [_vm._v("Verhaalstenen")]),
              _vm._v(" "),
              _c("div", { staticClass: "t-text" }, [
                _vm._v(
                  "\n          Gooi de dobbelstenen. Denk na over wat je wil vertellen (vragen op het presentatiescherm). Vertel om de beurt.\n\t\t\t\t"
                )
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "c-button c-button--full c-button--main",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.step++
                    }
                  }
                },
                [
                  _vm._v("\n\t\t\t\t\tGooi de verhaalstenen "),
                  _c("svg", { staticClass: "icon icon_arrow" }, [
                    _c("use", { attrs: { "xlink:href": "#icon_arrow" } })
                  ])
                ]
              )
            ]
          : [_c("Dice")]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }