import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MeshBasicMaterial, MeshPhongMaterial } from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

// Moeten de planeetkleuren gebruikt worden voor de objecten?
const overrideColors = true;

// Loader + cache
const loader = new OBJLoader();

const modelCache = {};

function loadModel(model) {
	return new Promise((resolve, reject) => {
		if (modelCache[model]) {
			resolve(modelCache[model].clone());
		} else {
			loader.load(
				model,
				gltf => {
					console.log('gltf', gltf);
					//Get the mesh
					modelCache[model] = gltf.children[0];
					resolve(modelCache[model].clone());
				},
				x => {},
				reject,
			);
		}
	});
}

// Planet
export default class Moon {
	constructor(planet, { model, color1, color2 }) {
		console.log('Moon.constructor()', ...arguments);
		const self = this;

		planet.orbits.forEach(orbit => {
			if (orbit.moonCount >= orbit.slots) {
				return;
			} else {
				this.orbit = orbit;
			}
		});

		if (!this.orbit) {
			return;
		}

		const orbit = this.orbit;

		this.angle = (orbit.moonCount / (orbit.slots / 2)) * Math.PI;
		this.r = orbit.size;

		loadModel(model).then(mesh => {
			console.log('have mesh', mesh);

			if (mesh.material) {
			}

			if (overrideColors && mesh.isMesh) {
				this.material = mesh.material = new MeshPhongMaterial();
				this.changeColor(color1);
			}

			this.mesh = mesh;

			this.mesh.scale.set(0.5, 0.5, 0.5);

			this.mesh.position.set(self.r * Math.cos(self.angle), self.r * Math.sin(self.angle), 0);

			this.pivot = new THREE.Group();

			this.pivot.add(this.mesh);

			orbit.orbit.add(this.pivot);
		});

		this.orbit.moonCount++;
	}

	changeColor(color) {
		if (!this.material) {
			return;
		}

		if (color === 'gold') {
			this.material.shininess = 51.2;
			this.material.specular = new THREE.Color(0.628281, 0.555802, 0.366065);
			this.material.color = new THREE.Color(0.75164, 0.60648, 0.22648);
		} else {
			this.material.shininess = 10;
			this.material.specular = new THREE.Color('#111111');
			this.material.color = new THREE.Color(color);
		}
	}

	changeColor1(color1) {
		this.changeColor(color1);
		return;
		if (!this.material) {
			return;
		}
		this.material.color = new THREE.Color(color1);
	}

	changeColor2(color2) {
		this.changeColor(color2);
		return;
		if (!this.material) {
			return;
		}
		this.material.emissive = new THREE.Color(color2);
	}

	update(dt) {
		if (this.mesh) {
			// this.mesh.rotation.z -= 0.5 * dt;

			// this.pivot.rotation.z -= 0.5 * dt;
			this.mesh.rotation.y -= 0.2 * dt;
			this.pivot.rotation.x -= 1 * dt;
		}
	}
}
