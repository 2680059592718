<template>
	<div>
		<div class="c-big-planet">
			<TeamPlanet v-bind:team="team" v-bind:showMoons="true"></TeamPlanet>

			<div class="c-big-planet__name">{{ team.name }}</div>
			<div class="c-big-planet__type" v-if="allStationsClosed">{{ teamTypeName }}</div>
		</div>

		<simple-collapse v-bind:text="teamTypeDescription" v-if="allStationsClosed"></simple-collapse>

		<div class="c-values">
			<label class="c-value" v-for="object in objects" :key="object.uuid">
				<div class="c-value__content">
					<div class="c-value__model-wrapper">
						<div class="c-value__model">
							<Model3D :model="object.model" :color="getColor(object.type)" />
						</div>
					</div>
					<div class="c-value__name">{{ object.name }}</div>
				</div>
			</label>
		</div>
	</div>
</template>

<script>
import TeamPlanet from './TeamPlanet.vue';
// import ValueTypes from '../../../components/value-types';
import Model3D from '../../../components/3d/Model3D.vue';
import SimpleCollapse from './SimpleCollapse.vue';

export default {
	name: 'MyPlanet',
	components: {
		TeamPlanet,
		Model3D,
		SimpleCollapse,
	},
	props: {
		user: Object,
	},
	data() {
		return {};
	},
	computed: {
		team() {
			return this.$actions.getTeam(this.user.team_uuid) || {};
		},
		objects() {
			return this.$actions.getTeamValues(this.team);
		},
		teamType() {
			return this.$actions.getTeamType(this.team);
		},
		teamColors() {
			const type = this.$actions.getValueType(this.teamType);
			return type?.planetColors || [];
		},
		teamTypeName() {
			const type = this.$actions.getValueType(this.teamType);
			return type?.name || '';
		},
		teamTypeDescription() {
			const type = this.$actions.getValueType(this.teamType);
			return type?.description || '';
		},
		allStationsClosed() {
			const stations = this.$actions.getStations();
			const klas = this.$actions.getClass();
			const classState = klas.state;

			if (classState) {
				const open = stations.filter(x => !classState[x.uuid]?.stop);
				return !open.length;
			}

			return false;
		},
	},
	methods: {
		getColor(type) {
			return this.$actions.getValueType(type)?.objectColor ?? 'white';
		},
	},
};
</script>

<style scoped>
.c-big-planet {
	width: 100%;
}
.c-values {
	margin-top: 24px;
}
</style>
