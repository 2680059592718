<template>
	<div class="u-slide-color--purple-orange">
		<div class="c-bg"></div>
		<div class="c-content">
			<div class="c-tabs">
				<button type="button" class="c-tabs__tab" v-for="tab in tabs" :key="tab.component" :class="{ 'is-active': currentTab == tab.component }" @click="currentTab = tab.component">
					{{ tab.label }}
				</button>
			</div>
			<div class="u-container">
				<transition name="fade" mode="out-in">
					<Team :user="user"></Team>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
import Team from "../components/Team";
export default {
	name: "TeamSlide",
	props: {
		'user' : Object,
		'tabs': {
			type: Array,
			factory: () => [],
		}
	},
	components: {
		Team,
	},
}
</script>

<style scoped>

</style>