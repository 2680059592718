<template>
	<div class="c-editor-preview__videos">
		<video
			class="c-selector-preview__video"
			v-if="currentVideo.url"
			v-bind:src="currentVideo.url"
			v-on:ended="ended"
			ref="video"
			disablepictureinpicture
			disableremoteplayback
			preload="auto"
			v-bind:autoplay="autoplay"
		></video>
	</div>
</template>

<script>
export default {
	name: 'PreviewVideos',
	props: {
		videos: Array,
		playing: Boolean,
		active: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			index: 0,
			autoplay: false,
		};
	},
	watch: {
		active: {
			handler() {
				this.$nextTick(() => {
					if (this.active && this.playing) {
						this.play();
					} else {
						this.stop();
					}
				});
			},
			immediate: true,
		},
		playing(val) {
			if (val) {
				return;
			}

			if (typeof this.$refs.video == 'undefined') {
				return;
			}

			this.$refs.video.pause();
		},
	},
	computed: {
		currentVideo() {
			return this.videos[this.index];
		},
	},
	methods: {
		ended(e) {
			const max = this.videos.length;

			if (this.index + 1 < max) {
				this.index++;
			} else {
				if (this.playing) {
					this.$emit('ended');
				}
			}
		},
		stop() {
			this.$refs.video.pause();
		},
		play() {
			this.autoplay = true;
			this.$refs.video.play();
		},
	},
};
</script>

<style></style>
