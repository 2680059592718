<template>
	<transition name="fade" mode="out-in" appear>
		<div class="c-menu" v-bind:class="stationColor" v-if="menuOpen">
			<div class="c-bg"></div>
			<div class="c-app-header">
				<img :src="logo" :alt="appName + ' logo'" class="c-app-header__title" />
				<button class="c-hamburger" type="button" v-on:click.prevent="toggleMenu"><span></span><span></span><span></span></button>
			</div>

			<nav class="c-menu__nav">
				<template v-if="showAll">
					<a class="c-menu__nav-item" href="/student/profiel">Profiel</a>
				</template>
				<button type="button" class="c-menu__nav-item" v-on:click.prevent="logout">Uitloggen</button>
			</nav>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'Menu',
	data() {
		return {
			logo: window.settings.logo,
			appName: window.settings.name,
		};
	},
	props: {
		showAll: {
			type: Boolean,
			default: false,
		},
		slide: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	computed: {
		menuOpen() {
			return this.$actions.getMenuOpen();
		},
		station() {
			if (Object.keys(this.slide).length) {
				return this.$actions.getStation(this.slide.station_uuid);
			} else {
				return false;
			}
		},
		stationColor() {
			if (this.station) {
				return `u-station-color--${this.station.settings?.color}`;
			} else {
				return 'u-slide-color--purple-orange';
			}
		},
	},
	methods: {
		logout() {
			const logoutForm = document.querySelector('#logout-form');

			if (!logoutForm) {
				return;
			}

			logoutForm.submit();
		},
		toggleMenu() {
			this.$actions.toggleMenu();
		},
	},
};
</script>

<style></style>
