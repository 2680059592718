import * as THREE from 'three';
// import OrbitControls from 'threejs-orbit-controls';
// import Stats from 'stats.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import scene from 'three/examples/jsm/offscreen/scene';

// Engine
export default class Engine {
	constructor(el, options = {}) {
		this.container = el;
		this.options = options;
		this.init();
		this.animate();
	}

	init() {
		// Scene
		this.scene = new THREE.Scene();

		// Container
		this.width = this.container.clientWidth;
		this.height = this.container.clientHeight;
		if (this.width > this.height) {
			this.size = this.height;
		} else {
			this.size = this.width;
		}

		// Camera
		const position = this.options.cameraPosition ?? 70;
		this.camera = new THREE.PerspectiveCamera(45, this.size / this.size, 0.1, 1000);
		this.camera.position.set(0, 0, position);
		this.camera.lookAt(this.scene.position);

		const light = new THREE.AmbientLight(0xffffff, 0.5);
		this.scene.add(light);

		// Clock
		this.clock = new THREE.Clock();

		// Renderer
		this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
		this.renderer.setPixelRatio(window.devicePixelRatio);

		this.renderer.setSize(this.size, this.size);
		this.renderer.setClearColor(0x000000, 0.0);
		//this.renderer.outputEncoding = THREE.sRGBEncoding;
		//this.renderer.physicallyCorrectLights = true;

		this.container.appendChild(this.renderer.domElement);

		// Controls
		//this.controls = new OrbitControls(this.camera, this.renderer.domElement);

		// Stats
		//this.stats = new Stats();
		//this.container.appendChild(this.stats.dom);

		//Lights
		this.addLights();

		// Items
		this.items = [];
	}

	addItem(item) {
		item.init(this.scene);
		this.items.push(item);
	}

	addLights() {
		// const light = new THREE.AmbientLight(0xffffff, 0.25); // soft white light
		// this.scene.add(light);

		const hemiLight = new THREE.HemisphereLight(0xffffff, 0x11133f, 1);
		hemiLight.position.set(-1, 1, 0);
		this.scene.add(hemiLight);

		// const pointLight = new THREE.PointLight(0xffffff, 1, 100);
		// pointLight.position.set(25, 25, 50);
		// this.scene.add(pointLight);
	}

	animate() {
		var self = this;

		this.dt = this.clock.getDelta();

		this.items.forEach(function(item) {
			item.update(self.dt);
		});

		requestAnimationFrame(function() {
			self.animate();
		});
		this.renderer.render(this.scene, this.camera);

		// this.controls.update();
		// this.stats.update();
	}

	resize() {
		this.width = this.container.clientWidth;
		this.height = this.container.clientHeight;
		if (this.width > this.height) {
			this.size = this.height;
		} else {
			this.size = this.width;
		}
		this.camera.aspect = this.size / this.size;
		this.camera.updateProjectionMatrix();
		this.renderer.setSize(this.size, this.size);
	}
}
