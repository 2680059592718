var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-station-slide" },
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in", appear: "" } },
        [
          _vm.slideComponent
            ? _c(_vm.slideComponent, {
                key: _vm.slide.uuid,
                tag: "component",
                class: "u-slide-color--" + _vm.stationColor,
                attrs: { slide: _vm.slide, user: _vm.user }
              })
            : _c(
                "div",
                {
                  staticClass: "c-content",
                  class: "u-station-color--" + _vm.stationColor
                },
                [
                  _c("div", { staticClass: "c-bg" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "u-container" },
                    [
                      _c("Waiting", {
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _c("h2", { staticClass: "t-title" }, [
                                  _vm._v(_vm._s(_vm.waitTitle))
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "t-text" }, [
                                  _c("p", [_vm._v(_vm._s(_vm.waitText))])
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }