var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-nieuwsmaker-intro" }, [
    _c("div", { staticClass: "c-nieuwsmaker-logo c-nieuwsmaker-logo--intro" }, [
      _c("img", {
        staticClass: "c-nieuwsmaker-logo__img",
        attrs: { src: _vm.logo, alt: _vm.appName + " logo" }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-nieuwsmaker-intro__video-wrapper" }, [
      _vm.video
        ? _c("video", {
            ref: "video",
            staticClass: "c-nieuwsmaker-intro__video",
            attrs: {
              src: _vm.video,
              controls: "",
              controlslist: "nodownload",
              disablepictureinpicture: "",
              disableremoteplayback: "",
              autoplay: "",
              preload: "auto"
            }
          })
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "c-button c-button--main",
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.nextView()
          }
        }
      },
      [_vm._v("Start")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }