<template>
	<div>
		<h2 class="t-title">Planeten</h2>
		<ul class="c-planet-list">
			<li class="c-planet-item" v-for="team in teams" :key="team.uuid">
				<TeamPlanet v-bind:team="team"></TeamPlanet>

				<div class="c-planet-item__info">
					<div class="c-planet-item__name">{{ team.name }}</div>
					<div class="c-planet-item__objects">{{ $actions.getTeamValues(team).length }} objecten</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import TeamPlanet from './TeamPlanet.vue';

export default {
	name: 'AllPlanets',
	components: {
		TeamPlanet,
	},
	data() {
		return {};
	},
	computed: {
		teams() {
			return this.$actions.getTeams();
		},
	},
};
</script>

<style></style>
