<template>
	<div class="c-recorder">
		<div class="c-recorder__wrapper">
			<transition name="fade" mode="out-in" appear>
				<div class="c-recorder__step" v-if="state == 'initial'" :key="'initial'">
					<div class="c-recorder__buttons">
						<button class="c-record-button" type="button" v-on:click="start">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 24">
								<path
									d="M15.118 11.676c.5 0 .882.363.882.838 0 3.885-3.059 7.154-7.118 7.574v2.235h3.206c.5 0 .883.364.883.839s-.383.838-.883.838H3.912c-.5 0-.883-.363-.883-.838s.383-.839.883-.839h3.206v-2.235C3.058 19.668 0 16.398 0 12.514c0-.475.382-.838.882-.838s.883.363.883.838c0 3.27 2.794 5.925 6.235 5.925 3.441 0 6.235-2.655 6.235-5.925 0-.475.383-.838.883-.838zM8 0c2.57 0 4.667 2.087 4.667 4.648v7.57c0 2.56-2.096 4.647-4.667 4.647-2.57-.028-4.667-2.087-4.667-4.676V4.648C3.333 2.088 5.43 0 8 0z"
									fill-rule="nonzero"
								/>
							</svg>
						</button>
					</div>
					<div class="c-recorder__text">
						Klik op de knop om op te nemen
					</div>
					<div class="c-recorder__help-text">(precies {{ recordingTime }} sec)</div>
				</div>

				<div class="c-recorder__step" v-if="state == 'recording'" :key="'recording'">
					<div class="c-recorder__buttons">
						<div class="c-recording-button">
							<button class="c-stop-button c-stop-button--small" type="button" v-on:click="stop">
								<span></span>
							</button>
							<RecordingTimer v-bind:time="recordingTime" v-bind:running="timerRunning" v-on:timer-finished="stop"></RecordingTimer>
						</div>
					</div>
				</div>

				<div class="c-recorder__step" v-if="state == 'recorded'" :key="'recorded'">
					<div class="c-recorder__buttons">
						<button class="c-retry-button" type="button" v-on:click="reset">Opnieuw</button>
						<audio class="c-recorder__audio" controls v-bind:src="src" v-if="src" ref="audio" controlslist="nodownload" preload="auto"></audio>
					</div>
					<div class="c-recorder__help-text" v-if="showDurationWarning">
						Je audiofragment is een beetje kort ({{ duration }} seconden). Probeer ongeveer {{ recordingTime }} seconden te halen.
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import RecordingTimer from '../components/RecordingTimer';
import getBlobDuration from 'get-blob-duration';

// TODO: als Vue properties?
let recordedChunks = [];
let mediaRecorder = null;
let audioStream = null;

const blobToBase64 = blob => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	return new Promise(resolve => {
		reader.onloadend = () => {
			resolve(reader.result);
		};
	});
};

export default {
	name: 'Recorder',
	props: {
		currentStep: Object,
		currentSubStep: {
			type: Object,
			default: {},
		},
	},
	components: {
		RecordingTimer,
	},
	data() {
		return {
			state: '', // states: 'initial', 'recording', 'recorded'
			recording: false,
			permission: false,
			src: '',
			timerRunning: false,
			// recordingTime: 30,
			duration: 0,
			blob: null,
		};
	},
	computed: {
		showDurationWarning() {
			if (this.duration !== 0 && this.duration < this.recordingTime - 5) {
				return true;
			}

			return false;
		},
		recordingTime() {
			const recordingTime = this.currentSubStep?.recordingTime;

			return recordingTime ? recordingTime : 30;
		},
	},
	watch: {
		blob(val) {
			if (!val) {
				this.duration = 0;
				return;
			}
			getBlobDuration(val).then(duration => {
				this.duration = duration;
			});
		},
	},
	mounted() {
		if (this.currentStep.recording) {
			this.state = 'recorded';

			// base64 to blob to object url
			fetch(this.currentStep.recording)
				.then(res => res.blob())
				.then(blob => {
					this.blob = blob;
					this.src = window.URL.createObjectURL(blob);
				});
		} else {
			this.state = 'initial';
		}

		if (navigator.mediaDevices === undefined) {
			console.error('localhost of veilige verbinding vereist');
		} else {
			navigator.mediaDevices
				.getUserMedia({ audio: true })
				.then(this.handleSuccess)
				.catch(error => {
					console.log(error);
				});
		}
	},
	beforeDestroy() {
		if (mediaRecorder && mediaRecorder.state == 'recording') {
			mediaRecorder.stop();
		}

		this.stopAudioStream();
		audioStream = null;
		mediaRecorder = null;
		recordedChunks = [];
	},
	methods: {
		handleSuccess(stream) {
			audioStream = stream;
			this.permission = true;

			mediaRecorder = new MediaRecorder(stream, {
				mimeType: 'audio/webm',
			});

			mediaRecorder.ondataavailable = e => {
				recordedChunks.push(e.data);
			};

			mediaRecorder.onstop = () => {
				const blob = new Blob(recordedChunks);
				const audioURL = window.URL.createObjectURL(blob);
				this.src = audioURL;
				this.blob = blob;

				blobToBase64(blob).then(result => {
					this.currentStep.recording = result;
				});
			};
		},
		start() {
			mediaRecorder.start();
			this.state = 'recording';
			this.timerRunning = true;
		},
		stop() {
			mediaRecorder.stop();
			this.state = 'recorded';
			this.timerRunning = false;
		},
		stopAudioStream() {
			if (audioStream.active) {
				const track = audioStream.getTracks()[0];
				track.stop();
			}
		},
		reset() {
			recordedChunks = [];
			this.src = '';
			this.blob = null;
			this.state = 'initial';
			this.currentStep.recording = '';
		},
	},
};
</script>

<style></style>
