// Helpers etc.
import { getters } from '../store.js';

export default {
	props: {
		currentId: String,
		currentSubId: {
			type: String,
			required: false,
		},
	},
	computed: {
		cmsData() {
			return getters.cmsData();
		},
		currentState() {
			return getters.currentState();
		},
		category() {
			const category = this.cmsData[this.currentState].categories.find(category => category.id == this.currentId);

			if (typeof category.steps !== 'undefined' && category.steps.length) {
				return category.steps.find(step => step.id == this.currentSubId);
			}

			return category;
		},
		title() {
			if (!this.category) {
				return null;
			}

			return this.category.title;
		},
		description() {
			if (!this.category) {
				return null;
			}

			return this.category.description;
		},
	},
};
