var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-planet-color-picker" }, [
    _c(
      "div",
      { staticClass: "c-color-picker" },
      _vm._l(_vm.colorOptions[0], function(color) {
        return _c(
          "div",
          { key: color.id, staticClass: "c-color-picker-radio" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.color1,
                  expression: "color1"
                }
              ],
              attrs: { type: "radio", name: "color1" },
              domProps: {
                value: color.id,
                checked: _vm._q(_vm.color1, color.id)
              },
              on: {
                change: [
                  function($event) {
                    _vm.color1 = color.id
                  },
                  _vm.planetColorsChanged
                ]
              }
            }),
            _vm._v(" "),
            _c("label", [
              _c("span", {
                staticClass: "c-color-picker-radio__color",
                style: { backgroundColor: color.value }
              })
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-planet" },
      [_c("Planet", { attrs: { colors: _vm.colors, showMoons: false } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-color-picker" },
      _vm._l(_vm.colorOptions[1], function(color) {
        return _c(
          "div",
          { key: color.id, staticClass: "c-color-picker-radio" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.color2,
                  expression: "color2"
                }
              ],
              attrs: { type: "radio", name: "color2" },
              domProps: {
                value: color.id,
                checked: _vm._q(_vm.color2, color.id)
              },
              on: {
                change: [
                  function($event) {
                    _vm.color2 = color.id
                  },
                  _vm.planetColorsChanged
                ]
              }
            }),
            _vm._v(" "),
            _c("label", [
              _c("span", {
                staticClass: "c-color-picker-radio__color",
                style: { backgroundColor: color.value }
              })
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }