<template>
	<div class="c-nieuwsmaker-intro">
		<div class="c-nieuwsmaker-logo c-nieuwsmaker-logo--intro">
			<img :src="logo" :alt="appName + ' logo'" class="c-nieuwsmaker-logo__img" />
		</div>
		<div class="c-nieuwsmaker-intro__video-wrapper">
			<video
				class="c-nieuwsmaker-intro__video"
				v-if="video"
				v-bind:src="video"
				ref="video"
				controls
				controlslist="nodownload"
				disablepictureinpicture
				disableremoteplayback
				autoplay
				preload="auto"
			></video>
		</div>
		<button class="c-button c-button--main" type="button" v-on:click="nextView()">Start</button>
	</div>
</template>

<script>
// Helpers etc.
import { getters, mutations } from '../store.js';

export default {
	name: 'Intro',
	data() {
		return {
			logo: window.settings.nieuwsmaker_logo,
			appName: window.settings.name,
		};
	},
	computed: {
		cmsData() {
			return getters.cmsData();
		},
		currentState() {
			return getters.currentState();
		},
		video() {
			return this.cmsData[this.currentState].video;
		},
	},
	methods: {
		nextView() {
			this.$emit('view-finished', {
				next: 'selector',
			});
		},
	},
};
</script>

<style></style>
