<template>
	<div>
		<div class="c-bg"></div>
		<div class="c-content">
			<div class="u-container">
				<div class="c-big-planet">
					<div class="c-planet">
						<Planet :colors="teamColors" :showMoons="true" />
					</div>
					<div class="c-big-planet__name">{{ team.name }}</div>
					<div class="c-big-planet__type">{{ teamTypeName }}</div>
				</div>

				<simple-collapse v-bind:text="teamTypeDescription" v-if="teamTypeDescription"></simple-collapse>

				<div class="c-values">
					<label class="c-value" v-for="object in objects" :key="object.uuid">
						<div class="c-value__content">
							<div class="c-value__model-wrapper">
								<div class="c-value__model">
									<Model3D :model="object.model" :color="getColor(object.type)" />
								</div>
							</div>
							<div class="c-value__name">{{ object.name }}</div>
						</div>
					</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Planet from '../components/Planet';
// import ValueTypes from '../../../components/value-types';
import Model3D from '../../../components/3d/Model3D';
import SimpleCollapse from '../components/SimpleCollapse.vue';

export default {
	name: 'SingleColorPlanetSlide',
	components: {
		Planet,
		Model3D,
		SimpleCollapse,
	},
	props: {
		slide: Object,
		user: {},
	},
	methods: {
		getColor(type) {
			return this.$actions.getValueType(type)?.objectColor ?? 'white';
		},
	},
	computed: {
		settings() {
			return this.slide.settings;
		},
		team() {
			return this.$actions.getTeam(this.user.team_uuid);
		},
		objects() {
			return this.$actions.getTeamValues(this.team);
		},
		teamType() {
			return this.$actions.getTeamType(this.team);
		},
		teamColors() {
			const type = this.$actions.getValueType(this.teamType);
			return type?.planetColors || [];
		},
		teamTypeName() {
			const type = this.$actions.getValueType(this.teamType);
			return type?.name || '';
		},
		teamTypeDescription() {
			const type = this.$actions.getValueType(this.teamType);
			return type?.description || '';
		},
	},
};
</script>

<style scoped>
.c-big-planet {
	width: 100%;
}

.c-big-planet__name {
	font-size: 24px;
}

.c-big-planet__type {
	font-size: 21px;
}
</style>
