<template>
	<div class="c-nm-video-collapse">
		<button class="c-nm-video-collapse__button c-button c-button--main" type="button" v-on:click="open = !open">{{ buttonText }}</button>
		<div class="c-nm-video-collapse__videos" v-if="open">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SimpleCollapse',
	data() {
		return {
			open: false,
		};
	},
	computed: {
		buttonText() {
			return this.open ? 'Sluiten' : 'Bekijken';
		},
	},
};
</script>

<style></style>
