import { render, staticRenderFns } from "./VideoSidebar.vue?vue&type=template&id=14f0f03d&"
import script from "./VideoSidebar.vue?vue&type=script&lang=js&"
export * from "./VideoSidebar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/cmgames.septemberdev.nl/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('14f0f03d')) {
      api.createRecord('14f0f03d', component.options)
    } else {
      api.reload('14f0f03d', component.options)
    }
    module.hot.accept("./VideoSidebar.vue?vue&type=template&id=14f0f03d&", function () {
      api.rerender('14f0f03d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/student/vue/nieuwsmaker/components/VideoSidebar.vue"
export default component.exports