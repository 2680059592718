var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-question" },
    [
      _vm.steps
        ? [
            _c("div", { staticClass: "c-steps" }, [
              _vm._v(
                "Vraag " +
                  _vm._s(_vm.steps.currentStep) +
                  "/" +
                  _vm._s(_vm.steps.totalSteps)
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "t-text" }, [
        _vm._v("\n\t\t\t" + _vm._s(_vm.question.text) + "\n\t\t")
      ]),
      _vm._v(" "),
      _vm._l(_vm.question.media, function(item) {
        return _c(
          "div",
          { key: item.id, staticClass: "c-question__media" },
          [
            item.type === "video"
              ? [
                  _c("div", { staticClass: "c-question__video" }, [
                    _c("video", {
                      attrs: {
                        src: item.src,
                        controls: "",
                        playsinline: "",
                        disableRemotePlayback: ""
                      }
                    })
                  ])
                ]
              : _vm._e()
          ],
          2
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-question__form-fields" },
        [
          _vm._l(_vm.fields, function(field) {
            return _c(
              "div",
              { key: field.name },
              [
                field.type === "textarea"
                  ? [
                      _c("div", { staticClass: "c-input c-input--textarea" }, [
                        _c("label", { attrs: { for: field.name } }, [
                          _vm._v(_vm._s(field.label))
                        ]),
                        _vm._v(" "),
                        _c(
                          "textarea",
                          {
                            attrs: { id: field.name, rows: "10" },
                            on: {
                              input: function($event) {
                                var i = arguments.length,
                                  argsArray = Array(i)
                                while (i--) argsArray[i] = arguments[i]
                                return _vm.updateValue.apply(
                                  void 0,
                                  [field.name].concat(argsArray)
                                )
                              },
                              change: function($event) {
                                var i = arguments.length,
                                  argsArray = Array(i)
                                while (i--) argsArray[i] = arguments[i]
                                return _vm.updateValue.apply(
                                  void 0,
                                  [field.name].concat(argsArray)
                                )
                              },
                              keypress: function($event) {
                                var i = arguments.length,
                                  argsArray = Array(i)
                                while (i--) argsArray[i] = arguments[i]
                                return _vm.updateValue.apply(
                                  void 0,
                                  [field.name].concat(argsArray)
                                )
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.answers[field.name]))]
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                field.type === "radio"
                  ? [
                      field.question
                        ? _c("h2", [_vm._v(_vm._s(field.question))])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(field.options, function(option, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "c-radio-button" },
                          [
                            _c("input", {
                              attrs: {
                                type: "radio",
                                name: field.name,
                                id: field.name + "_" + index
                              },
                              domProps: {
                                value: option.value,
                                checked: _vm.answers[field.name] == option.value
                              },
                              on: {
                                change: function($event) {
                                  var i = arguments.length,
                                    argsArray = Array(i)
                                  while (i--) argsArray[i] = arguments[i]
                                  return _vm.updateValue.apply(
                                    void 0,
                                    [field.name].concat(argsArray)
                                  )
                                },
                                click: function($event) {
                                  var i = arguments.length,
                                    argsArray = Array(i)
                                  while (i--) argsArray[i] = arguments[i]
                                  return _vm.updateValue.apply(
                                    void 0,
                                    [field.name].concat(argsArray)
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: field.name + "_" + index } },
                              [_vm._v(_vm._s(option.label))]
                            )
                          ]
                        )
                      })
                    ]
                  : _vm._e()
              ],
              2
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "c-question__footer" }, [
            _vm.isFirst
              ? _c(
                  "button",
                  {
                    staticClass: "c-button c-button--transparent",
                    attrs: { type: "button", "data-button-prev": "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("prev")
                      }
                    }
                  },
                  [_vm._v("Vorige")]
                )
              : _vm.steps.totalSteps > 1 && !_vm.isLast
              ? _c(
                  "button",
                  {
                    staticClass: "c-button c-button--main c-button--purple",
                    attrs: {
                      type: "button",
                      "data-button-next": "",
                      disabled: _vm.disabled
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("next")
                      }
                    }
                  },
                  [_vm._v("\n\t\t\t\t\tVolgende vraag\n\t\t\t\t")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isLast
              ? _c(
                  "button",
                  {
                    staticClass: "c-button c-button--main c-button--purple",
                    attrs: {
                      type: "submit",
                      "data-button-next": "",
                      disabled: _vm.disabled
                    }
                  },
                  [_vm._v("Verzenden")]
                )
              : _vm._e()
          ])
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }