<template>
	<div class="v-nieuwsmaker-mobile">
		<div class="c-nm-video-category" v-for="(category, index) of selectorCategories" v-bind="category" :key="category.id">
			<div class="t-title" v-if="index == 0">Deskundige</div>
			<div class="t-title" v-else-if="index == 1">Voxpop</div>

			<simple-collapse>
				<simple-video v-for="video of category.videos" v-bind:video="video" v-bind:playing="playing" :key="video.id" v-on:ply="handlePlay"></simple-video>
			</simple-collapse>
		</div>

		<div class="c-nm-video-category">
			<div class="t-title">Sfeerbeelden</div>
			<simple-collapse>
				<simple-video v-for="video of editorVideos" v-bind:video="video" v-bind:playing="playing" :key="video.id" v-on:ply="handlePlay"></simple-video>
			</simple-collapse>
		</div>

		<div class="c-nm-video-category">
			<div class="t-title">Muziek</div>
			<simple-collapse>
				<simple-audio v-for="audio of editorTracks" v-bind:audio="audio" v-bind:playing="playing" :key="audio.id" v-on:ply="handlePlay"></simple-audio>
			</simple-collapse>
		</div>
	</div>
</template>

<script>
// Helpers etc.
import cmsData from './mockup-data'; // temp

// Components
import SimpleVideo from './components/SimpleVideo.vue';
import SimpleAudio from './components/SimpleAudio.vue';
import SimpleCollapse from './components/SimpleCollapse.vue';

export default {
	name: 'NieuwsmakerMobile',
	components: {
		SimpleVideo,
		SimpleAudio,
		SimpleCollapse,
	},
	props: {
		slide: Object,
	},
	data() {
		return {
			cmsData: null,
			playing: null,
		};
	},
	computed: {
		selectorCategories() {
			return this.cmsData.selector.categories.slice(0, 2);
		},
		editorVideos() {
			return this.cmsData.editor.categories[0].steps[1].videos;
		},
		editorTracks() {
			return this.cmsData.editor.categories[2].tracks;
		},
	},
	created() {
		const scenario = this.$actions.getVariable('scenario');
		const scenarios = this.$actions.getVariable('scenarios');
		let data = cmsData[0];

		if (scenario && scenarios) {
			const key = scenarios.findIndex(item => item.title == scenario);

			if (cmsData[key] !== undefined) {
				data = cmsData[key];
			}
		}

		this.cmsData = data;
	},
	methods: {
		handlePlay(e) {
			this.playing = e;
		},
	},
};
</script>

<style></style>
