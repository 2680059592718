import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

export default class {
	constructor(container) {
		const width = container.clientWidth;
		const height = container.clientHeight;

		const camera = (this.camera = new THREE.PerspectiveCamera(45, width / height, 1, 2000));
		camera.position.z = 250;

		// scene
		const scene = (this.scene = new THREE.Scene());

		const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
		scene.add(ambientLight);

		const pointLight = new THREE.PointLight(0xffffff, 0.8);
		camera.add(pointLight);
		scene.add(camera);

		const material = (this.material = new THREE.MeshPhongMaterial());

		// manager

		const manager = new THREE.LoadingManager(object => {
			if (!object) {
				return;
			}

			this.newModel(object);
		});

		manager.onProgress = function(item, loaded, total) {
			// console.log( item, loaded, total );
		};

		this.loaders = {
			obj: new OBJLoader(manager),
			stl: new STLLoader(manager),
		};

		const renderer = (this.renderer = new THREE.WebGLRenderer({
			antialias: true,
		}));
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(width, height);

		container.appendChild(renderer.domElement);

		this.clock = new THREE.Clock();
	}

	model = null;

	deleteModel() {
		if (this.model !== null) {
			this.scene.remove(this.model);
			this.model = null;
		}
	}

	newModel(newModel) {
		this.deleteModel();

		newModel.traverse(child => {
			if (child.isMesh) {
				child.material = this.material;
			}
		});

		//zwaartepunt in het midden
		newModel.position.x = 0;
		newModel.position.y = 0;
		newModel.position.z = 0;

		this.scene.add(newModel);
		this.model = newModel;
		this.animate();
	}

	newColor(color) {
		if (color === 'gold') {
			this.material.shininess = 51.2;
			this.material.specular = new THREE.Color(0.628281, 0.555802, 0.366065);
			this.material.color = new THREE.Color(0.75164, 0.60648, 0.22648);
		} else {
			this.material.shininess = 10;
			this.material.specular = new THREE.Color('#111111');
			this.material.color = new THREE.Color(color);
		}
	}

	animate() {
		const delta = this.clock.getDelta();
		if (this.model) {
			requestAnimationFrame(() => {
				this.animate();
			});
			this.render(delta);
		}
	}

	angle = 0;
	render(delta) {
		this.camera.position.x = Math.cos(this.angle) * 20;
		this.camera.position.z = Math.sin(this.angle) * 20;

		this.angle += 1 * delta;
		// camera.position.y += ( - mouseY - camera.position.y ) * .05;

		this.camera.lookAt(this.scene.position);

		this.renderer.render(this.scene, this.camera);
	}

	resize(width, height) {
		this.camera.aspect = width / height;
		this.renderer.setSize(width, height);
	}

	loadModel(modelUri) {
		const [_, type] = /\.(.*)$/.exec(modelUri);
		const loader = this.loaders[type];
		if (loader) {
			loader.load(modelUri, x => {
				this.newModel(x);
			});
		}
	}
}
