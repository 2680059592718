<template>
  <div>
			<slot name="header"></slot>
			<slot name="box">
				<div class="u-box c-leader-box">
					<div class="c-leader-box__text">Kijk naar het presentatiescherm</div>
					<div class="c-leader-box__help">Je gaat automatisch door wanneer je een actie moet uitvoeren</div>
				</div>
			</slot>
	</div>
</template>

<script>
export default {
	name: 'Waiting',
	props: {
		title: {
			default: 'Titel',
			type: String,
		},
		text: {
			default: 'Text',
			type: String,
		},
	},
};
</script>

<style></style>
