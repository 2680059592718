import Vue from 'vue';

const md5 = require('js-md5');

function overwrite(obj, newObj) {
	for (let key in newObj) {
		if (!newObj.hasOwnProperty(key)) {
			continue;
		}
		Vue.set(obj, key, newObj[key]);
	}
}

export const store = Vue.observable({
	slide: null,
	slides: [],
	values: [],
	teams: [],
	stations: [],
	stationUuid: null,
	class: {},
	variables: {},
	menuOpen: false,
	lastEvent: new Date(),
	stationSlide: {},
});

export const actions = {
	setClass(value) {
		Vue.set(store, 'class', value);
	},
	getClass() {
		return store.class;
	},
	getClassState(context) {
		const state = this.getClass()?.state;

		if (!state) {
			return null;
		}

		return state[context] || {};
	},
	setSlide(value) {
		store.slide = value;
		const slide = this.getSlide();
		if(slide){
			Vue.set(store.stationSlide, slide.station_uuid, slide.uuid);

			console.debug({stationSlide: JSON.stringify(store.stationSlide)});

		}else{
			store.stationUuid = '';
		}


	},
	clearSlide() {
		store.slide = null;
	},
	setSlides(value) {
		store.slides = value.sort((a, b) => a.order - b.order);
	},
	setValues(values) {
		store.values = values;
	},
	getValues() {
		return store.values;
	},
	setValueTypes(valueTypes) {
		store.valueTypes = valueTypes;
	},
	getValueTypes() {
		return store.valueTypes;
	},
	getValueType(type){
		return store.valueTypes[type];
	},
	getCurrentSlideData() {
		if (!store.slide || !store.slides) {
			return null;
		}

		return store.slides.find(slide => slide.uuid == store.slide);
	},
	getAnswers(subject, except) {
		return (
			store.teams
				.filter(x => x.uuid != except)
				.map(team => {
					const answers = team.settings?.answers;

					if (!answers) {
						return null;
					}

					const answer = answers[subject];
					if (!answer) {
						return null;
					}

					return {
						team: team.uuid,
						teamName: team.name,
						text: answer,
					};
				})

				//
				.filter(x => x)

				// sorteer op een "random" waarde,
				.sort((a, b) => {
					const md5A = md5(a.team + subject);
					const md5B = md5(b.team + subject);

					console.log('md5A', md5A, 'md5B', md5B);
					return md5A.localeCompare(md5B);
				})
		);
	},
	getVotes(subject) {
		const votes = store.teams.map(team => {
			const votes = team.settings?.votes;

			console.log('votes', votes);
			if (!votes) {
				return null;
			}

			return votes[subject];
		});

		const counts = votes
			.filter(x => x)
			.reduce((carry, vote) => {
				//count the votes
				carry[vote] = (carry[vote] || 0) + 1;

				return carry;
			}, {});

		// const results = [];
		// for (let uuid in counts) {
		// 	if (!counts.hasOwnProperty(uuid)) {
		// 		continue;
		// 	}
		//
		// 	results.push({
		// 		team: actions.getTeam(uuid),
		// 		count: counts[uuid],
		// 	});
		// }

		return this.getTeams().map(team => {
			return {
				team,
				count: counts[team.uuid] ?? 0,
			};
		});

		return results;
	},

	updateTeam(newTeam) {
		const team = this.getTeam(newTeam.uuid);
		if (!team) {
			store.teams.push(newTeam);
		} else {
			overwrite(team, newTeam);
		}

		this.selectTeamLeader(newTeam.uuid, newTeam.students.find(x=>x.uuid == newTeam.settings?.leader));
	},

	setTeams(teams) {
		teams.forEach(team => this.updateTeam(team));
	},

	getTeams() {
		return store.teams;
	},

	getSlide(uuid) {
		if (!uuid) {
			uuid = store.slide;
		}
		return store.slides.find(slide => slide.uuid == uuid);
	},

	selectTeamLeader(teamUuid, leader) {
		console.log('selectTeamLeader', teamUuid, leader);
		const team = this.getTeam(teamUuid);
		if (team) {
			Vue.set(team, 'leader', leader);
			console.log('set leader for team', JSON.stringify(team.uuid), 'to', JSON.stringify(leader));
		}
	},

	getTeam(uuid) {
		return store.teams.find(x => x.uuid == uuid);
	},

	setStations(value) {
		store.stations = value.sort((a, b) => a.order - b.order);
	},

	setStationUuid(value) {
		store.stationUuid = value;
	},

	getStations() {
		return store.stations;
	},

	getStation(uuid) {
		if (!uuid) {
			uuid = this.getSlide()?.station_uuid;
		}
		console.log('effective station uuid', uuid);
		if (!uuid) {
			return null;
		}
		return store.stations.find(x => x.uuid == uuid);
	},

	getStationSlides(stationUuid) {
		return store.slides.filter(x => x.station_uuid === stationUuid);
	},

	slideAfter(stationUuid, uuid) {
		const slides = this.getStationSlides(stationUuid);
		const index = slides.findIndex(x => x.uuid == uuid);
		if (index === -1) {
			return null;
		}
		return slides[index + 1];
	},

	// Temp actions for remote
	setNextSlide() {
		if (!store.cmsData) {
			return;
		}

		if (!store.slide) {
			this.setSlide(store.cmsData.slides[0].uuid);
			return;
		}

		const index = store.cmsData.slides.findIndex(slide => slide.uuid == store.slide);

		if (!(index + 1 < store.cmsData.slides.length)) {
			return;
		}

		this.setSlide(store.cmsData.slides[index + 1].uuid);
	},
	setPrevSlide() {
		if (!store.slide || !store.cmsData) {
			return;
		}

		const index = store.cmsData.slides.findIndex(slide => slide.uuid == store.slide);

		if (index == 0) {
			return;
		}

		this.setSlide(store.cmsData.slides[index - 1].uuid);
	},
	getVariable(name, defaultValue) {
		return store.variables[name] || defaultValue;
	},
	getVariables() {
		return store.variables;
	},
	setVariable(name, value) {
		Vue.set(store.variables, name, value);
		console.debug('variable', name, 'is now', this.getVariable(name));
	},

	getTeamValues(team) {
		const stationValues = team?.settings?.values || {};

		// Remove null
		Object.keys(stationValues).forEach(k => stationValues[k] == null && delete stationValues[k]);

		const uuids = Object.values(stationValues).reduce((carry, values) => {
			carry.push(...values);
			return carry;
		}, []);

		return this.getValues().filter(x => uuids.indexOf(x.uuid) !== -1);
	},
	getTeamType(team) {
		const chosenValues = this.getTeamValues(team).filter(x => x.type != null);

		const typeScores = {};
		chosenValues.forEach(value => {
			typeScores[value.type] = typeScores[value.type] || 0;
			typeScores[value.type]++;
		});

		const maxScore = Math.max(...Object.values(typeScores));

		if (maxScore <= 0) {
			return null;
		}

		const winningTypes = Object.keys(typeScores).filter(x => typeScores[x] === maxScore);

		const sortingOrder = chosenValues.map(x => x.type).reverse();

		const result = winningTypes.sort((a, b) => sortingOrder.indexOf(a) - sortingOrder.indexOf(b));

		return result[0];
	},
	getMenuOpen() {
		return store.menuOpen;
	},
	toggleMenu() {
		store.menuOpen = !store.menuOpen;
	},

	newEvent() {
		store.lastEvent = new Date();
	},
	getLastEventDate() {
		return store.lastEvent;
	},
	getStudents() {
		return (
			store.teams?.reduce((carry, team) => {
				team.students.forEach(student => {
					carry.push(student);
				});
				return carry;
			}, []) || []
		);
	},
};

export const eventBus = new Vue();

Vue.prototype.$store = store;
Vue.prototype.$actions = actions;
Vue.prototype.$eventBus = eventBus;
