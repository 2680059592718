import * as THREE from 'three';

const throwSpeed = 2;
const startHeight = 2;
const startOffset = 0.4;
const headerHeight = 58;

const dice = [
	{
		pos: new THREE.Vector3(-startOffset, startHeight, -startOffset),
		sides: ['voetbal', 'vrouwe-justitia', 'werken', 'yoga', 'zon', 'wereldbol'],
	},

	{
		pos: new THREE.Vector3(0, startHeight, -startOffset),
		sides: ['spelletje', 'vloggen', 'spuit', 'superheld', 'telefoon', 'spaceinvader'],
	},

	{
		pos: new THREE.Vector3(startOffset, startHeight, -startOffset),
		sides: ['slingers', 'slapen', 'schilderij', 'rennen', 'regen', 'snoep'],
	},

	{
		pos: new THREE.Vector3(-startOffset, startHeight, 0),
		sides: ['muzieknoten', 'natuur', 'opgelucht', 'podium', 'politie', 'protest'],
	},

	{
		pos: new THREE.Vector3(0, startHeight, 0),
		sides: ['koptelefoon', 'kwast', 'lopen', 'megafoon', 'microfoon', 'moersleutel'],
	},

	{
		pos: new THREE.Vector3(startOffset, startHeight, 0),
		sides: ['gitaar', 'groep', 'hart', 'instrument', 'kleding', 'klok'],
	},

	{
		pos: new THREE.Vector3(-startOffset, startHeight, startOffset),
		sides: ['duif', 'eten', 'familie', 'filmrol', 'foto', 'gamen'],
	},

	{
		pos: new THREE.Vector3(0, startHeight, startOffset),
		sides: ['brandweer', 'camera', 'champagne', 'chillen', 'comedian', 'discobal'],
	},

	{
		pos: new THREE.Vector3(startOffset, startHeight, startOffset),
		sides: ['backpack', 'barbell', 'bed', 'biertje', 'blijdschap', 'boek'],
	},
];

const colGroupBoundaries = 1;

class Engine {
	constructor(elm, Ammo, button, number = 3) {
		this.elm = elm;
		this.Ammo = Ammo;
		this.button = button;
		this.number = number;

		this.rigidBodies = [];

		this.initScene();

		this.createPhysics();
		this.createBoundaries();
	}

	createPhysics() {
		// Physics configuration

		this.collisionConfiguration = new this.Ammo.btDefaultCollisionConfiguration();
		this.dispatcher = new this.Ammo.btCollisionDispatcher(this.collisionConfiguration);
		this.broadphase = new this.Ammo.btDbvtBroadphase();
		this.solver = new this.Ammo.btSequentialImpulseConstraintSolver();
		this.physicsWorld = new this.Ammo.btDiscreteDynamicsWorld(this.dispatcher, this.broadphase, this.solver, this.collisionConfiguration);
		this.physicsWorld.setGravity(new this.Ammo.btVector3(0, -10, 0));

		this.transformAux1 = new this.Ammo.btTransform();
	}

	createBoundaries() {
		const cageHeight = 50;
		const thickness = 0.1;

		//base
		const base = this.scene.getObjectByName('base');
		if (!base) {
			this.createBlock('base', { x: 0, y: 0, z: 0 }, { x: this.width * 50, y: thickness, z: this.height * 50 });
		}

		//left wall
		const leftwall = this.scene.getObjectByName('leftwall');
		if (leftwall) {
			this.remove(leftwall);
		}
		this.createBlock('leftwall', { x: 0 - (this.width / 2 - this.size), y: 0, z: 0 }, { x: thickness, y: cageHeight, z: this.height });

		//right wall
		const rightwall = this.scene.getObjectByName('rightwall');
		if (rightwall) {
			this.remove(rightwall);
		}
		this.createBlock('rightwall', { x: this.width / 2 - this.size, y: 0, z: 0 }, { x: thickness, y: cageHeight, z: this.height });

		//top wall
		const topwall = this.scene.getObjectByName('topwall');
		if (topwall) {
			this.remove(topwall);
		}
		if (this.button) {
			this.createBlock('topwall', { x: 0, y: 0, z: 0 - (this.height / 2 - this.size * 2) }, { x: this.width, y: cageHeight, z: thickness });
		} else {
			this.createBlock('topwall', { x: 0, y: 0, z: 0 - (this.height / 2 - this.size) }, { x: this.width, y: cageHeight, z: thickness });
		}

		//bottom wall
		const bottomwall = this.scene.getObjectByName('bottomwall');
		if (bottomwall) {
			this.remove(bottomwall);
		}
		if (this.button) {
			this.createBlock('bottomwall', { x: 0, y: 0, z: this.height / 2 - this.size * 3 }, { x: this.width, y: cageHeight, z: thickness });
		} else {
			this.createBlock('bottomwall', { x: 0, y: 0, z: this.height / 2 - this.size }, { x: this.width, y: cageHeight, z: thickness });
		}
	}

	updateBoundaries() {
		if (this.scene && this.physicsWorld) {
			this.createBoundaries();
		}
	}

	createBlock(name, pos, scale) {
		let quat = { x: 0, y: 0, z: 0, w: 1 };
		let mass = 0;

		//threeJS Section

		let blockPlane = new THREE.Mesh(
			new THREE.BoxBufferGeometry(),
			new THREE.MeshPhongMaterial({
				color: 0xff0000,
				opacity: 0,
				transparent: true,
			}),
		);

		blockPlane.name = name;

		blockPlane.position.set(pos.x, pos.y, pos.z);
		blockPlane.scale.set(scale.x, scale.y, scale.z);

		blockPlane.castShadow = false;
		blockPlane.receiveShadow = false;

		this.scene.add(blockPlane);

		//Ammojs Section
		let transform = new Ammo.btTransform();
		transform.setIdentity();
		transform.setOrigin(new Ammo.btVector3(pos.x, pos.y, pos.z));
		transform.setRotation(new Ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w));
		let motionState = new Ammo.btDefaultMotionState(transform);

		let colShape = new Ammo.btBoxShape(new Ammo.btVector3(scale.x, scale.y, scale.z));
		colShape.setMargin(0.05);

		let localInertia = new Ammo.btVector3(0, 0, 0);
		colShape.calculateLocalInertia(mass, localInertia);

		let rbInfo = new Ammo.btRigidBodyConstructionInfo(mass, motionState, colShape, localInertia);
		let body = new Ammo.btRigidBody(rbInfo);

		blockPlane.userData.physicsBody = body;

		this.physicsWorld.addRigidBody(body, colGroupBoundaries, colGroupBoundaries);
		this.rigidBodies.push(blockPlane);
	}

	initScene() {
		this.clock = new THREE.Clock();

		this.scene = new THREE.Scene();

		this.camera = new THREE.PerspectiveCamera(45, 800 / 600, 0.2, 200);
		this.camera.position.set(0, 4, 0);

		this.camera.lookAt(0, 0, 0);

		this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize(this.elm.clientWidth, this.elm.clientHeight);

		this.resize();

		this.elm.appendChild(this.renderer.domElement);

		this.addLights();
	}

	addLights() {
		const ambientLight = new THREE.AmbientLight(0x666666);
		this.scene.add(ambientLight);

		const light = new THREE.DirectionalLight(0xffffff, 0.8);
		light.position.set(3, 20, 3);
		this.scene.add(light);
	}

	createRigidBody(mesh, mass) {
		const pos = mesh.position;
		const quat = mesh.quaternion;

		const geometry = mesh.geometry;
		geometry.computeBoundingBox();
		const boundingBox = geometry.boundingBox.max;

		const Ammo = this.Ammo;

		const physicsShape = new Ammo.btBoxShape(new this.Ammo.btVector3(boundingBox.x, boundingBox.y, boundingBox.z));

		const transform = new Ammo.btTransform();
		transform.setIdentity();
		transform.setOrigin(new Ammo.btVector3(pos.x, pos.y, pos.z));
		transform.setRotation(new Ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w));
		const motionState = new Ammo.btDefaultMotionState(transform);

		const localInertia = new Ammo.btVector3(0, 0, 0);
		physicsShape.calculateLocalInertia(mass, localInertia);

		const rbInfo = new Ammo.btRigidBodyConstructionInfo(mass, motionState, physicsShape, localInertia);
		const body = new Ammo.btRigidBody(rbInfo);

		body.setFriction(0.5);

		this.physicsWorld.addRigidBody(body, colGroupBoundaries, colGroupBoundaries);

		mesh.userData.physicsBody = body;

		this.rigidBodies.push(mesh);

		// Disable deactivation
		body.setActivationState(4);
	}

	removeRigidBody(mesh) {
		//console.log('removeRigidBody')
		const body = mesh.userData.physicsBody;
		this.physicsWorld.removeRigidBody(body);
	}

	update(deltaTime) {
		// Step world
		this.physicsWorld.stepSimulation(deltaTime, 10);

		// Update rigid bodies
		this.rigidBodies.forEach(objThree => {
			const objPhys = objThree.userData.physicsBody;
			const ms = objPhys.getMotionState();

			if (ms) {
				ms.getWorldTransform(this.transformAux1);
				const p = this.transformAux1.getOrigin();
				const q = this.transformAux1.getRotation();
				objThree.position.set(p.x(), p.y(), p.z());
				objThree.quaternion.set(q.x(), q.y(), q.z(), q.w());
			}
		});
	}

	add(mesh, mass) {
		this.createRigidBody(mesh, mass);
		this.scene.add(mesh);
	}

	remove(mesh) {
		this.removeRigidBody(mesh);
		this.scene.remove(mesh);
	}

	resize() {
		//console.log('resize!');

		this.size = 0.1;

		this.clientWidth = this.elm.clientWidth;
		this.clientHeight = this.elm.clientHeight - headerHeight;

		this.aspect = this.clientWidth / this.clientHeight;

		const number = this.number;

		if (this.aspect > 1) {
			this.width = Math.max(number * this.aspect, number);
			this.height = Math.min(number * this.aspect, number);
		} else {
			this.width = Math.min(number * this.aspect, number);
			this.height = Math.max(number * this.aspect, number);
		}

		let maxDim = 0;
		if (this.aspect > 1) {
			maxDim = Math.max(this.width, this.height);
		} else {
			maxDim = Math.min(this.width, this.height);
		}

		let distance = maxDim / 2 / this.aspect / Math.tan((Math.PI * this.camera.fov) / 360);

		this.camera.aspect = this.aspect;
		this.camera.position.set(0, distance, 0);
		this.camera.lookAt(0, 0, 0);
		this.camera.updateProjectionMatrix();

		this.updateBoundaries();

		this.renderer.setSize(this.clientWidth, this.clientHeight);
	}

	render() {
		const deltaTime = this.clock.getDelta();
		this.update(deltaTime);
		this.renderer.render(this.scene, this.camera);
	}

	running = false;

	animate() {
		if (!this.running) {
			return;
		}

		requestAnimationFrame(() => {
			this.render();
			this.animate();
		});
	}

	start() {
		if (!this.running) {
			this.running = true;
			this.animate();
		}
	}

	stop() {
		this.running = false;
	}
}

class Die {
	constructor(config, textureLookup, size = 0.2) {
		this.config = config;
		this.basePos = config.pos;
		this.size = size;

		this.materials = config.sides.map(
			side =>
				new THREE.MeshPhongMaterial({
					flatShading: true,
					shininess: 0,
					map: textureLookup[side],
				}),
		);
	}
	// size = 0.3;

	init() {
		const widthSegments = 2;
		const heightSegments = 2;
		const depthSegments = 2;
		const smoothness = 20;
		const boxGeometry = this.roundEdgedBox(this.size, this.size, this.size, this.size / 15, widthSegments, heightSegments, depthSegments, smoothness);

		const mesh = new THREE.Mesh(boxGeometry, this.materials);

		const boxQuat = new THREE.Quaternion();
		boxQuat.setFromAxisAngle(new THREE.Vector3(Math.ceil(Math.random() * 90), Math.ceil(Math.random() * 90), Math.ceil(Math.random() * 90)), Math.PI / 2);

		mesh.position.set(this.basePos.x, this.basePos.y, this.basePos.z);

		this.mesh = mesh;
	}

	roll(Ammo) {
		const box = this.mesh;

		const boxBody = box.userData.physicsBody;

		let throwPos = this.basePos.clone();

		const tf = boxBody.getCenterOfMassTransform();
		const origin = tf.getOrigin();

		//reset the die to the original position
		origin.setX(throwPos.x);
		origin.setY(throwPos.y);
		origin.setZ(throwPos.z);

		tf.setOrigin(origin);
		boxBody.setCenterOfMassTransform(tf);

		//chose a direction in the horizontal plane
		const angle = Math.random() * 2 * Math.PI;
		boxBody.setLinearVelocity(new Ammo.btVector3(Math.cos(angle), throwSpeed, Math.sin(angle)));

		boxBody.setAngularVelocity(new Ammo.btVector3(Math.ceil(Math.random() * 10), Math.ceil(Math.random() * 10), Math.ceil(Math.random() * 10)), Math.PI / 2);
	}

	roundEdgedBox(w, h, d, r, wSegs, hSegs, dSegs, rSegs) {
		w = w || 1;
		h = h || 1;
		d = d || 1;
		let minimum = Math.min(Math.min(w, h), d);
		r = r || minimum * 0.25;
		r = r > minimum * 0.5 ? minimum * 0.5 : r;
		wSegs = Math.floor(wSegs) || 1;
		hSegs = Math.floor(hSegs) || 1;
		dSegs = Math.floor(dSegs) || 1;
		rSegs = Math.floor(rSegs) || 1;

		let fullGeometry = new THREE.BufferGeometry();

		let fullPosition = [];
		let fullUvs = [];
		let fullIndex = [];
		let fullIndexStart = 0;

		let groupStart = 0;

		bendedPlane(w, h, r, wSegs, hSegs, rSegs, d * 0.5, 'y', 0, 0);
		bendedPlane(w, h, r, wSegs, hSegs, rSegs, d * 0.5, 'y', Math.PI, 1);
		bendedPlane(d, h, r, dSegs, hSegs, rSegs, w * 0.5, 'y', Math.PI * 0.5, 2);
		bendedPlane(d, h, r, dSegs, hSegs, rSegs, w * 0.5, 'y', Math.PI * -0.5, 3);
		bendedPlane(w, d, r, wSegs, dSegs, rSegs, h * 0.5, 'x', Math.PI * -0.5, 4);
		bendedPlane(w, d, r, wSegs, dSegs, rSegs, h * 0.5, 'x', Math.PI * 0.5, 5);

		fullGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(fullPosition), 3));
		fullGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(fullUvs), 2));
		fullGeometry.setIndex(fullIndex);

		fullGeometry.computeVertexNormals();

		return fullGeometry;

		function bendedPlane(width, height, radius, widthSegments, heightSegments, smoothness, offset, axis, angle, materialIndex) {
			let halfWidth = width * 0.5;
			let halfHeight = height * 0.5;
			let widthChunk = width / (widthSegments + smoothness * 2);
			let heightChunk = height / (heightSegments + smoothness * 2);

			let planeGeom = new THREE.PlaneBufferGeometry(width, height, widthSegments + smoothness * 2, heightSegments + smoothness * 2);

			let v = new THREE.Vector3(); // current vertex
			let cv = new THREE.Vector3(); // control vertex for bending
			let cd = new THREE.Vector3(); // vector for distance
			let position = planeGeom.attributes.position;
			let uv = planeGeom.attributes.uv;
			let widthShrinkLimit = widthChunk * smoothness;
			let widthShrinkRatio = radius / widthShrinkLimit;
			let heightShrinkLimit = heightChunk * smoothness;
			let heightShrinkRatio = radius / heightShrinkLimit;
			let widthInflateRatio = (halfWidth - radius) / (halfWidth - widthShrinkLimit);
			let heightInflateRatio = (halfHeight - radius) / (halfHeight - heightShrinkLimit);
			for (let i = 0; i < position.count; i++) {
				v.fromBufferAttribute(position, i);
				if (Math.abs(v.x) >= halfWidth - widthShrinkLimit) {
					v.setX((halfWidth - (halfWidth - Math.abs(v.x)) * widthShrinkRatio) * Math.sign(v.x));
				} else {
					v.x *= widthInflateRatio;
				} // lr
				if (Math.abs(v.y) >= halfHeight - heightShrinkLimit) {
					v.setY((halfHeight - (halfHeight - Math.abs(v.y)) * heightShrinkRatio) * Math.sign(v.y));
				} else {
					v.y *= heightInflateRatio;
				} // tb

				//re-calculation of uvs
				uv.setXY(i, (v.x - -halfWidth) / width, 1 - (halfHeight - v.y) / height);

				// bending
				let widthExceeds = Math.abs(v.x) >= halfWidth - radius;
				let heightExceeds = Math.abs(v.y) >= halfHeight - radius;
				if (widthExceeds || heightExceeds) {
					cv.set(widthExceeds ? (halfWidth - radius) * Math.sign(v.x) : v.x, heightExceeds ? (halfHeight - radius) * Math.sign(v.y) : v.y, -radius);
					cd.subVectors(v, cv).normalize();
					v.copy(cv).addScaledVector(cd, radius);
				}

				position.setXYZ(i, v.x, v.y, v.z);
			}

			planeGeom.translate(0, 0, offset);
			switch (axis) {
				case 'y':
					planeGeom.rotateY(angle);
					break;
				case 'x':
					planeGeom.rotateX(angle);
			}

			// merge positions
			position.array.forEach(function(p) {
				fullPosition.push(p);
			});

			// merge uvs
			uv.array.forEach(function(u) {
				fullUvs.push(u);
			});

			// merge indices
			planeGeom.index.array.forEach(function(a) {
				fullIndex.push(a + fullIndexStart);
			});
			fullIndexStart += position.count;

			// set the groups
			fullGeometry.addGroup(groupStart, planeGeom.index.count, materialIndex);
			groupStart += planeGeom.index.count;
		}
	}
}

// Instance
export default class Dice {
	destroy() {
		this.dice.forEach(die => this.engine.remove(die.mesh));

		if (this.button) {
			this.button.removeEventListener('click', this.boundHandleClick);
		}

		document.removeEventListener('dice.throw', this.boundHandleClick);

		this.engine.stop();
	}

	constructor(elm, autostart, number = 3, size = 0.2) {
		if (!('Ammo' in window)) {
			let ammo = document.createElement('script');
			ammo.setAttribute('src', '/vendor/ammo/ammo.wasm.js');
			document.body.appendChild(ammo);
		}

		this.elm = elm;
		this.autostart = autostart;
		this.number = number;
		this.size = size;

		window.addEventListener('resize', () => {
			this.onWindowResize();
		});

		this.button = this.elm.querySelector('button');
		this.boundHandleClick = this.handleClick.bind(this);

		if (this.button) {
			this.button.addEventListener('click', this.boundHandleClick);
		}

		document.addEventListener('dice.throw', this.boundHandleClick);

		this.rolling = false;
		this.dice = [];

		this.init();
	}

	roll() {
		if (this.rolling) {
			return;
		}

		this.rolling = true;

		this.dice.forEach(die => die.roll(this.Ammo));

		this.rolling = false;

		this.engine.start();
	}

	init() {
		const interval = setInterval(() => {
			if (!('Ammo' in window)) {
				return;
			}
			clearInterval(interval);
			let promise = Ammo.ready;
			if (!promise) {
				promise = Ammo();
			}

			const textureLoader = new THREE.TextureLoader();

			const textureLookup = dice
				.flatMap(x => x.sides)
				.filter((value, index, self) => {
					return self.indexOf(value) === index;
				})
				.reduce((result, texture) => {
					result[texture] = textureLoader.load(`/images/dice/icons/side-${texture}.svg`);
					return result;
				}, {});

			promise.then(ammoInstance => {
				this.Ammo = ammoInstance;
				this.engine = new Engine(this.elm, ammoInstance, this.button, this.number);

				dice.forEach(config => {
					const die = new Die(config, textureLookup, this.size);
					die.init();
					this.engine.add(die.mesh, 500);
					this.dice.push(die);
				});

				setTimeout(() => {
					this.engine.start();

					if (this.autostart) {
						this.roll();
					}
				}, 1000);
			});
		}, 1000);
	}

	handleClick() {
		this.roll();

		if (this.button) {
			this.button.setAttribute('disabled', true);

			setTimeout(() => {
				this.button.removeAttribute('disabled');
			}, 600);
		}
	}

	onWindowResize() {
		this.engine.resize();
	}
}
