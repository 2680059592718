<template>
	<div class="c-music-preview">
		<div class="c-music-preview__wrapper">
			<audio class="c-music-preview__audio" controls v-bind:src="audio" v-if="audio" ref="audio" controlslist="nodownload" preload="auto" autoplay></audio>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MusicPreview',
	props: {
		currentStep: Object,
		previewPlaying: Boolean,
	},
	watch: {
		previewPlaying(val) {
			if (!val) {
				return;
			}

			if (typeof this.$refs.audio == 'undefined') {
				return;
			}

			this.$refs.audio.pause();
		},
	},
	computed: {
		audio() {
			if (!this.currentStep) {
				return false;
			}

			return this.currentStep.music.url;
		},
	},
	mounted(){
		if(this.$refs.audio){
			this.$refs.audio.volume = 0.23;
		}
	}
};
</script>

<style></style>
