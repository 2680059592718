var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-music-preview" }, [
    _c("div", { staticClass: "c-music-preview__wrapper" }, [
      _vm.audio
        ? _c("audio", {
            ref: "audio",
            staticClass: "c-music-preview__audio",
            attrs: {
              controls: "",
              src: _vm.audio,
              controlslist: "nodownload",
              preload: "auto",
              autoplay: ""
            }
          })
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }