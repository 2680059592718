import Vue from 'vue';

const store = Vue.observable({
	cmsData: null,
	states: {
		intro: 'Intro',
		selector: 'VideoSelector',
		editor: 'VideoEditor',
	},
	currentState: 'intro', // start state
	result: [
		{
			id: 'title',
			text: '',
		},
		{
			id: 'videos',
			videos: [],
		},
		{
			id: 'recordings',
			recordings: [],
		},
		{
			id: 'music',
			music: '',
		},
	],
});

export const getters = {
	cmsData: () => store.cmsData,
	currentState: () => store.currentState,
	states: () => store.states,
	result: () => store.result,
	videos: () => {
		const part = store.result.find(part => part.id == 'videos');

		return part.videos;
	},
};

export const mutations = {
	setCmsData: val => (store.cmsData = val),
	setCurrentState: val => (store.currentState = val),
	setVideos: val => {
		const videos = store.result.find(part => part.id == 'videos');
		videos.videos = val;
	},
	setTitle: val => {
		const title = store.result.find(part => part.id == 'title');
		title.text = val;
	},
	clearRecordings: () => {
		const recordings = store.result.find(part => part.id == 'recordings');
		recordings.recordings = [];
	},
	setRecording: val => {
		const recordings = store.result.find(part => part.id == 'recordings');
		recordings.recordings.push(val);
	},
	setMusic: val => {
		const music = store.result.find(part => part.id == 'music');
		music.music = val;
	},
};
