<template>
	<div>
		<div class="c-bg"></div>
		<div class="c-content">
			<div class="u-container">
				<TeamLeaderView :user="user" v-if="maxNumberOfValues > 0">
					<template v-slot:leader-content>
						<template v-if="!submitted">
							<div class="c-steps">{{ settings.title }}</div>
							<div class="t-text">
								{{ settings.text }}
							</div>

							<form v-on:submit.prevent="submit">
								<div class="t-text">Jouw team mag {{ maxNumberOfValues }} waarde(n) uitkiezen.</div>

								<div class="c-values" v-bind:class="{ 'c-values--max': haveMaxValues }">
									<label class="c-value" v-for="option in values" :key="option.uuid">
										<input type="checkbox"
                           :value="option.uuid"
                           v-model="checkboxModel[option.uuid]"
                           :disabled="haveMaxValues && !checkboxModel[option.uuid]"
                      />
										<div class="c-value__content">
											<div class="c-value__model-wrapper">
												<div class="c-value__model">
													<Model3D :model="option.model" :color="getColor(option.type)" />
												</div>
											</div>
											<div class="c-value__name">{{ option.name }}</div>
										</div>
									</label>
								</div>

								<button type="submit" class="c-button c-button--main c-button--full" data-button-next :disabled="submitting || !haveMaxValues">
									Versturen <svg class="icon icon_arrow"><use xlink:href="#icon_arrow"></use></svg>
								</button>
							</form>
						</template>
						<template v-else>
							<Waiting>
								<template v-slot:header>
									<h2 class="t-title">Gelukt!</h2>
									<div class="t-text">
										<p>Jullie waarden zijn gekozen.</p>
									</div>
								</template>
							</Waiting>
						</template>
					</template>
				</TeamLeaderView>
				<Waiting v-else></Waiting>
			</div>
		</div>
	</div>
</template>

<script>
import Question from '../components/Question';
import TeamLeaderView from '../views/TeamLeaderView';
import Waiting from '../views/Waiting';
import Model3DEngine from '../../../components/3d/Model3D';
// import ValueTypes from '../../../components/value-types';

export default {
	name: 'ChooseValuesSlide',
	components: {
		Model3D: Model3DEngine,
		TeamLeaderView,
		Question,
		Waiting,
	},
	props: {
		slide: Object,
		user: null,
	},
	data() {
		return {
			submitting: false,
			submitted: false,
      checkboxModel: {},
		};
	},
	computed: {
		teams() {
			return this.$actions.getTeams();
		},
		team() {
			return this.teams.find(x => x.uuid == this.user?.team_uuid);
		},
		leader() {
			return this.team?.leader || {};
		},
		leading() {
			return this.leader?.uuid == this.user?.uuid;
		},
		settings() {
			return this.slide.settings;
		},
		values() {
			return this.$actions.getValues().filter(x => this.settings.values.indexOf(x.uuid) != -1);
		},
		chosenValues() {
			const values = this.team?.settings?.values || {};
			return values[this.slide.uuid] || [];
		},
		maxNumberOfValues() {
			let result = parseInt(this.settings.free_values) || 0;

			const subjects = this.settings.subjects || [];
			const points = this.team.settings.points || {};

			console.log('subjects', subjects, 'points', points);

			subjects.forEach(subject => {
				result += parseInt(points[subject]) || 0;
			});

			return result;
		},
		haveMaxValues() {
			return this.numberOfValues >= this.maxNumberOfValues;
		},
		numberOfValues() {
			return this.selectedValues.length;
		},
    selectedValues(){
      return Object.keys(this.checkboxModel).filter(x => this.checkboxModel[x]);
    },
	},
  mounted(){
    const checkboxModel = {};
    const values = this.settings?.values || [];
    values.forEach(x => {
      checkboxModel[x] = this.chosenValues.indexOf(x) !== -1;
    });
    this.checkboxModel = checkboxModel;
  },
	methods: {
		submit() {
			this.submitting = true;

			window.axios
				.post(`/api/v1/values/${this.slide.uuid}`, {
					values: this.selectedValues,
				})
				.then(() => {
					this.submitted = true;
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					console.log('submitting klaar!');
					this.submitting = false;
				});
		},

		getColor(type) {
			return this.$actions.getValueType(type)?.objectColor ?? 'white';
		},
	},
};
</script>

<style></style>
