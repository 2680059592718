var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-planet" },
    [
      _c("Planet", {
        attrs: {
          moons: _vm.objects,
          colors: _vm.colors,
          showMoons: _vm.showMoons,
          options: _vm.options
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }