<template>
	<div class="c-recording-timer">{{ prettyTime }}</div>
</template>

<script>
export default {
	name: 'RecordingTimer',
	props: {
		time: Number,
		running: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			timer: null,
			timeLeft: null,
		};
	},
	watch: {
		running(val) {
			val ? this.start() : this.stop();
		},
	},
	computed: {
		prettyTime() {
			const timeLeft = this.timeLeft;
			let minutes = Math.floor(timeLeft / 60);
			let seconds = timeLeft % 60;

			if (minutes < 10) {
				minutes = `0${minutes}`;
			}

			if (seconds < 10) {
				seconds = `0${seconds}`;
			}

			return `${minutes}:${seconds}`;
		},
	},
	methods: {
		start() {
			if (!this.timer) {
				this.timer = setInterval(() => {
					if (this.timeLeft > 0) {
						this.timeLeft--;
					} else {
						this.$emit('timer-finished');
						clearInterval(this.timer);
					}
				}, 1000);
			}
		},
		stop() {
			if (this.timer) {
				clearInterval(this.timer);
				this.timer = null;
				this.timeLeft = this.time;
			}
		},
	},
	mounted() {
		this.timeLeft = this.time;

		if (this.running) {
			this.start();
		}
	},
	beforeDestroy() {
		this.stop();
	},
};
</script>

<style></style>
