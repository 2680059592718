var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-nm-video-collapse" }, [
    _c(
      "button",
      {
        staticClass: "c-nm-video-collapse__button c-button c-button--main",
        attrs: { type: "button" },
        on: {
          click: function($event) {
            _vm.open = !_vm.open
          }
        }
      },
      [_vm._v(_vm._s(_vm.buttonText))]
    ),
    _vm._v(" "),
    _vm.open
      ? _c(
          "div",
          { staticClass: "c-nm-video-collapse__videos" },
          [_vm._t("default")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }