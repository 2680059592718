import * as THREE from 'three';

import Moon from './Moon';
import Orbit from './Orbit';


const textureLoader = new THREE.TextureLoader();

// Planet
export default class Planet {
    constructor({color1, color2, moons, showMoons}) {

    	console.log('Planet.constructor()', ...arguments);
        // this.data = data;
        this.color1 = color1 || "#1a6959";
        this.color2 = color2 || "#e2451a";
        this.size = showMoons ? 12 : 24;
        this.showMoons = showMoons;
        this.positionX = 0;
        this.positionY = 0;
        this.positionZ = 0;
        this.moonArray = moons || [];
        this.moonCount = this.moonArray.length;
    }

    init(scene) {

    	this.scene = scene;

        // Planet
        const geometry = new THREE.SphereGeometry(this.size, 35, 35);
        const material = new THREE.MeshPhongMaterial({

            flatShading: false,
            shininess: 0,
            // transparent: false,
            // opacity: 1
			// reflectivity: 0,
			// specular: 0,

            color: this.color1,
            map: textureLoader.load("/images/planets/pluto_texture.jpg"),

            emissiveMap: textureLoader.load("/images/planets/dd72-planet.png"),
            emissive: this.color2,
            emissiveIntensity: 1,

			lightMapIntensity: 0,
        });

        this.material = material;

        this.mesh = new THREE.Mesh(geometry, material);

        this.mesh.position.set(this.positionX, this.positionY, this.positionZ);
        this.mesh.rotation.y = Math.random()*360;
        this.mesh.rotation.z = 45;

        scene.add(this.mesh);

        // Orbits
        this.orbits = [];
        //this.orbits.push(new Orbit(this, this.size * 2.3, Math.random() * (0.02 - 0.01) + 0.01, 12));
        this.orbits.push(new Orbit(this, this.size * 1.8, 0.5 * (Math.random()  + 1), 8));
        this.orbits.push(new Orbit(this, this.size * 1.3, 0.5 * (Math.random()  + 1), 6));

        // Moons
        this.moons = [];
        this.setMoons(this.moonArray);

    }

    changeColor1(color) {
		this.material.color = new THREE.Color(color);
		this.moons.forEach(moon => moon.changeColor1(color));
    }

    changeColor2(color) {
		this.material.emissive = new THREE.Color(color);
		this.moons.forEach(moon => moon.changeColor2(color));
    }

    addMoon({model, color}) {
		if (!this.showMoons) {
			return;
		}
        this.moons.push(new Moon(this, {
        	model,
			color1: color,
			color2: color
        }));
    }

    setMoons(moons){
		this.orbits.forEach(orbit => {
			orbit.clear();
			orbit.moonCount = 0;
		});
		// this.moons.forEach(moon => moon.remove());
		moons.forEach(moon => this.addMoon(moon));
	}

    update(dt) {
        this.mesh.rotation.y -= 0.4 * dt;

        this.orbits.forEach(function(orbit) {
            orbit.update(dt);
        });

        this.moons.forEach(function (moon) {
            moon.update(dt);
        });
    }

};
