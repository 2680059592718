<template>
	<div class="c-content">
		<div class="c-bg"></div>
		<div class="u-container">
			<TeamLeaderView :user="user">
				<template v-slot:leader-content>
					<template v-if="!submitted">
						<div class="c-steps">{{ settings.intro.title }}</div>
						<div class="t-text">
							{{ settings.intro.text }}
						</div>
						<form v-on:submit.prevent="submit">
							<div class="c-input c-input--textarea">
								<label for="hmw-textarea">Typ je antwoord</label>
								<textarea v-model="answer" id="hmw-textarea" rows="10"></textarea>
							</div>
							<button type="submit" class="c-button c-button--main c-button--full" data-button-next :disabled="submitting">Verzenden</button>
						</form>
					</template>
					<template v-else>
						<Waiting>
							<template v-slot:header>
								<h2 class="t-title">Gelukt!</h2>
								<div class="t-text">
									<p>Jullie antwoord is verstuurd.</p>
								</div>
							</template>
						</Waiting>
					</template>
				</template>
			</TeamLeaderView>
		</div>
	</div>
</template>

<script>
import Question from '../components/Question';
import TeamLeaderView from '../views/TeamLeaderView';
import Waiting from '../views/Waiting';

export default {
	name: 'TeamQuestionSlide',
	components: {
		Waiting,
		Question,
		TeamLeaderView,
	},
	props: {
		slide: Object,
		user: null,
	},
	data() {
		return {
			submitting: false,
			submitted: false,
			answer: '',
		};
	},
	computed: {
		team() {
			return this.$actions.getTeam(this.user?.team_uuid);
		},
		leader() {
			return this.team?.leader || {};
		},
		leading() {
			return this.leader?.uuid == this.user?.uuid;
		},
		settings() {
			return this.slide.settings;
		},
		subject() {
			return this.slide.settings.subject;
		},
	},
	methods: {
		submit() {
			this.submitting = true;

			window.axios
				.post(`/api/v1/answer/${this.slide.uuid}`, {
					answer: this.answer,
				})
				.then(() => {
					this.submitted = true;
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					console.log('submitting klaar!');
					this.submitting = false;
				});
		},
	},
  mounted() {
    const answers = this.team?.settings?.answers || {};
    this.answer = answers[this.settings.subject];
  },
};
</script>

<style></style>
