var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("header"),
      _vm._v(" "),
      _vm._t("box", function() {
        return [_vm._m(0)]
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "u-box c-leader-box" }, [
      _c("div", { staticClass: "c-leader-box__text" }, [
        _vm._v("Kijk naar het presentatiescherm")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-leader-box__help" }, [
        _vm._v("Je gaat automatisch door wanneer je een actie moet uitvoeren")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }