<template>
	<button type="button" class="c-video-selector-step" v-bind:class="{ 'is-active': currentId == step.id, 'is-complete': complete }" v-on:click="$emit('current-step', step.id)">
		<div class="c-video-selector-step__frame">
			<img class="c-video-selector-step__thumb" v-bind:src="step.video.thumbnail" v-if="complete" alt="" />
		</div>
		<div class="c-video-selector-step__label">{{ step.label }}</div>
	</button>
</template>

<script>
export default {
	name: 'VideoSelectorStep',
	props: {
		step: Object,
		currentId: String,
	},
	computed: {
		complete() {
			return this.step.video.thumbnail;
		},
	},
};
</script>

<style></style>
