var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-sidebar c-sidebar--music" }, [
    _c("div", { staticClass: "c-sidebar__header" }, [
      _c("h3", { staticClass: "c-sidebar__title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-sidebar__description" }, [
        _vm._v("\n\t\t\t" + _vm._s(_vm.description) + "\n\t\t")
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-sidebar__items" },
      _vm._l(_vm.tracks, function(music, index) {
        return _c(
          "button",
          {
            key: music.id,
            staticClass: "c-sidebar-track",
            class: { "is-active": _vm.currentStep.music.id == music.id },
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("music-selected", music)
              }
            }
          },
          [
            _c("span", { staticClass: "c-sidebar-track__play-button" }, [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 9 11"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M8.74 5.037L.868.087A.57.57 0 00.09.253.541.541 0 000 .55v9.898c0 .304.25.55.561.551.109 0 .215-.03.306-.088l7.874-4.949a.543.543 0 000-.926z",
                      "fill-rule": "nonzero"
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "c-sidebar-track__label" }, [
              _vm._v(" Muziek " + _vm._s(index + 1) + " ")
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }