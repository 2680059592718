var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-team-leader" },
    [
      _vm.leading
        ? _vm._t("leader-content")
        : _vm.leader
        ? [
            _c("h2", { staticClass: "t-title" }, [_vm._v("Teamopdracht")]),
            _vm._v(" "),
            _vm._t("follower-content", function() {
              return [_vm._m(0)]
            }),
            _vm._v(" "),
            _c("div", { staticClass: "u-box c-leader-box" }, [
              _c("div", { staticClass: "c-leader-box__text" }, [
                _vm._v(_vm._s(_vm.leader.name) + " is teamleider")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "c-leader-box__help" }, [
                _vm._v(
                  "Je gaat automatisch door wanneer de actie is uitgevoerd"
                )
              ])
            ])
          ]
        : [
            _c("h2", { staticClass: "t-title" }, [_vm._v("Teamopdracht")]),
            _vm._v(" "),
            _vm._m(1)
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t-text" }, [
      _c("p", [
        _vm._v(
          "Bedenk gezamenlijk met je teamleden jullie antwoord. De teamleider kan het antwoord invoeren in zijn/haar scherm."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t-text" }, [
      _c("p", [_vm._v("Er wordt een teamleider gekozen.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }