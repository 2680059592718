<template>
	<div class="c-selector-monitor">
		<div class="c-selector-monitor__wrapper">
			<video
				class="c-selector-monitor__video"
				v-if="video"
				v-bind:src="video"
				v-on:loadedmetadata="setDuration"
				ref="video"
				controls
				controlslist="nodownload"
				disablepictureinpicture
				disableremoteplayback
				autoplay
				preload="auto"
			></video>
		</div>
		<div class="c-selector-monitor__duration">{{ duration }}</div>
	</div>
</template>

<script>
export default {
	name: 'SelectorMonitor',
	props: {
		currentStep: Object,
		previewPlaying: Boolean,
	},
	data() {
		return {
			duration: '00:00',
		};
	},
	watch: {
		previewPlaying(val) {
			if (!val) {
				return;
			}

			if (typeof this.$refs.video == 'undefined') {
				return;
			}

			this.$refs.video.pause();
		},
	},
	computed: {
		video() {
			if (!this.currentStep) {
				return false;
			}

			return this.currentStep.video.url;
		},
	},
	methods: {
		setDuration() {
			const seconds = this.$refs.video.duration;
			const date = new Date(null);

			date.setSeconds(seconds);
			this.duration = date.toISOString().substr(14, 5);
		},
	},
};
</script>

<style></style>
