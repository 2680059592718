var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-nieuwsmaker-frame" },
    [
      _c("div", { staticClass: "c-nieuwsmaker-frame__main" }, [
        _c("div", { staticClass: "c-nieuwsmaker-frame__content" }, [
          _c("div", { staticClass: "c-nieuwsmaker-logo" }, [
            _c("img", {
              staticClass: "c-nieuwsmaker-logo__img",
              attrs: { src: _vm.logo, alt: _vm.appName + " logo" }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "c-nieuwsmaker-frame__monitor" },
            [
              _vm.sidebar == "video"
                ? _c("SelectorMonitor", {
                    key: _vm.currentStep.id,
                    attrs: {
                      currentStep: _vm.currentStep,
                      previewPlaying: _vm.preview
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.sidebar == "recording"
                ? _c("Recorder", {
                    key: _vm.currentStep.id,
                    attrs: {
                      currentStep: _vm.currentStep,
                      currentSubStep: _vm.currentSubStep
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.sidebar == "music"
                ? _c("MusicPreview", {
                    key: _vm.currentStep.id,
                    attrs: {
                      currentStep: _vm.currentStep,
                      previewPlaying: _vm.preview
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.sidebar == "title"
                ? _c("TitleEditor", {
                    key: _vm.currentStep.id,
                    attrs: { currentStep: _vm.currentStep },
                    on: {
                      input: function($event) {
                        _vm.currentStep.title = $event
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c-nieuwsmaker-frame__bar" }, [
          _c("div", { staticClass: "c-selector-nav c-selector-nav--editor" }, [
            _c(
              "div",
              { staticClass: "c-selector-nav__steps" },
              [
                _vm._l(_vm.steps, function(step) {
                  return _c(step.component, {
                    key: step.id,
                    tag: "component",
                    attrs: { step: step, ids: _vm.ids },
                    on: {
                      "current-step": function($event) {
                        _vm.ids = $event
                      }
                    }
                  })
                }),
                _vm._v(" "),
                _vm.ready
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "c-video-selector-step c-video-selector-step--next-view",
                        attrs: { type: "button" },
                        on: { click: _vm.showPremiere }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "c-video-selector-step__frame" },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tKlaar voor de premiere\n\t\t\t\t\t\t"
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "c-video-selector-step__label" },
                          [_vm._v(" ")]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              2
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-nieuwsmaker-frame__sidebar" },
        [
          _vm.sidebar == "recording"
            ? _c("Sidebar", {
                attrs: {
                  currentId: _vm.currentId,
                  currentSubId: _vm.currentSubId
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.sidebar == "video"
            ? _c("VideoSidebar", {
                attrs: {
                  currentId: _vm.currentId,
                  currentSubId: _vm.currentSubId,
                  currentStep: _vm.currentStep
                },
                on: {
                  "video-selected": function($event) {
                    _vm.currentStep.video = $event
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.sidebar == "music"
            ? _c("MusicSidebar", {
                attrs: {
                  currentId: _vm.currentId,
                  currentStep: _vm.currentStep
                },
                on: {
                  "music-selected": function($event) {
                    _vm.currentStep.music = $event
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.sidebar == "title"
            ? _c("Sidebar", { attrs: { currentId: _vm.currentId } })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.preview
        ? _c(
            "NieuwsmakerModal",
            [
              _c("EditorPreview", {
                on: {
                  close: function($event) {
                    _vm.preview = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }