<template>
	<div>
		<template v-if="leading">
			<NieuwsMaker v-bind:slide="slide"></NieuwsMaker>
		</template>
		<template v-else>
			<TeamLeaderView :user="user" />
		</template>
	</div>
</template>

<script>
import TeamLeaderView from '../views/TeamLeaderView.vue';
import NieuwsMaker from '../nieuwsmaker/App.vue';
import NieuwsmakerMobile from '../nieuwsmaker/MobileApp.vue';

export default {
	name: 'NieuwsmakerSlide',
	components: {
		TeamLeaderView,
		NieuwsMaker,
		NieuwsmakerMobile,
	},
	props: {
		slide: Object,
		user: null,
	},
	computed: {
		team() {
			return this.$actions.getTeam(this.user?.team_uuid);
		},
		leader() {
			return this.team?.leader || {};
		},
		leading() {
			return this.leader?.uuid == this.user?.uuid;
		},
		settings() {
			return this.slide?.settings || {};
		},
	},
};
</script>

<style></style>
