var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-nieuwsmaker-frame" },
    [
      _c("div", { staticClass: "c-nieuwsmaker-frame__main" }, [
        _c("div", { staticClass: "c-nieuwsmaker-frame__content" }, [
          _c("div", { staticClass: "c-nieuwsmaker-logo" }, [
            _c("img", {
              staticClass: "c-nieuwsmaker-logo__img",
              attrs: { src: _vm.logo, alt: _vm.appName + " logo" }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "c-nieuwsmaker-frame__monitor" },
            [
              _c("SelectorMonitor", {
                key: _vm.currentId,
                attrs: {
                  currentStep: _vm.currentStep,
                  previewPlaying: _vm.preview
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c-nieuwsmaker-frame__bar" }, [
          _c("div", { staticClass: "c-selector-nav" }, [
            _c(
              "button",
              {
                staticClass: "c-play-button c-play-button--nav",
                attrs: { type: "button" },
                on: { click: _vm.showPreview }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 9 11"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M8.74 5.037L.868.087A.57.57 0 00.09.253.541.541 0 000 .55v9.898c0 .304.25.55.561.551.109 0 .215-.03.306-.088l7.874-4.949a.543.543 0 000-.926z",
                        "fill-rule": "nonzero"
                      }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.steps.length
              ? _c(
                  "div",
                  { staticClass: "c-selector-nav__steps" },
                  [
                    _vm._l(_vm.steps, function(step, index) {
                      return _c("VideoSelectorStep", {
                        key: step.id,
                        attrs: {
                          step: step,
                          currentId: _vm.currentId,
                          index: index
                        },
                        on: {
                          "current-step": function($event) {
                            _vm.currentId = $event
                          }
                        }
                      })
                    }),
                    _vm._v(" "),
                    _vm.ready
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "c-video-selector-step c-video-selector-step--next-view",
                            attrs: { type: "button" },
                            on: { click: _vm.nextView }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "c-video-selector-step__frame" },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\tDoor met deel 2\n\t\t\t\t\t\t"
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "c-video-selector-step__label" },
                              [_vm._v(" ")]
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-nieuwsmaker-frame__sidebar" },
        [
          _vm.steps.length
            ? _c("VideoSidebar", {
                attrs: {
                  currentId: _vm.currentId,
                  currentStep: _vm.currentStep
                },
                on: {
                  "video-selected": function($event) {
                    _vm.currentStep.video = $event
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.preview
        ? _c(
            "NieuwsmakerModal",
            [
              _c("SelectorPreview", {
                attrs: { videos: _vm.selectedVideos },
                on: {
                  close: function($event) {
                    _vm.preview = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }