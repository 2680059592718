var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "c-video-selector-step",
      class: {
        "is-active": _vm.currentId == _vm.step.id,
        "is-complete": _vm.complete
      },
      attrs: { type: "button" },
      on: {
        click: function($event) {
          return _vm.$emit("current-step", _vm.step.id)
        }
      }
    },
    [
      _c("div", { staticClass: "c-video-selector-step__frame" }, [
        _vm.complete
          ? _c("img", {
              staticClass: "c-video-selector-step__thumb",
              attrs: { src: _vm.step.video.thumbnail, alt: "" }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-video-selector-step__label" }, [
        _vm._v(_vm._s(_vm.step.label))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }