var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-content" }, [
    _c("div", { staticClass: "c-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "u-container" },
      [
        _c("TeamLeaderView", {
          attrs: { user: _vm.user },
          scopedSlots: _vm._u([
            {
              key: "leader-content",
              fn: function() {
                return [
                  !_vm.submitted
                    ? [
                        _c("div", { staticClass: "c-steps" }, [
                          _vm._v(_vm._s(_vm.settings.intro.title))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "t-text" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.settings.intro.text) +
                              "\n\t\t\t\t\t"
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.submit.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "c-input c-input--textarea" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "hmw-textarea" } },
                                  [_vm._v("Typ je antwoord")]
                                ),
                                _vm._v(" "),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.answer,
                                      expression: "answer"
                                    }
                                  ],
                                  attrs: { id: "hmw-textarea", rows: "10" },
                                  domProps: { value: _vm.answer },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.answer = $event.target.value
                                    }
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "c-button c-button--main c-button--full",
                                attrs: {
                                  type: "submit",
                                  "data-button-next": "",
                                  disabled: _vm.submitting
                                }
                              },
                              [_vm._v("Verzenden")]
                            )
                          ]
                        )
                      ]
                    : [
                        _c("Waiting", {
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c("h2", { staticClass: "t-title" }, [
                                    _vm._v("Gelukt!")
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "t-text" }, [
                                    _c("p", [
                                      _vm._v("Jullie antwoord is verstuurd.")
                                    ])
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        })
                      ]
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }