var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "c-bg" }),
    _vm._v(" "),
    _c("div", { staticClass: "c-content" }, [
      _c(
        "div",
        { staticClass: "u-container" },
        [
          _vm.maxNumberOfValues > 0
            ? _c("TeamLeaderView", {
                attrs: { user: _vm.user },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "leader-content",
                      fn: function() {
                        return [
                          !_vm.submitted
                            ? [
                                _c("div", { staticClass: "c-steps" }, [
                                  _vm._v(_vm._s(_vm.settings.title))
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "t-text" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.settings.text) +
                                      "\n\t\t\t\t\t\t\t"
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "form",
                                  {
                                    on: {
                                      submit: function($event) {
                                        $event.preventDefault()
                                        return _vm.submit.apply(null, arguments)
                                      }
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "t-text" }, [
                                      _vm._v(
                                        "Jouw team mag " +
                                          _vm._s(_vm.maxNumberOfValues) +
                                          " waarde(n) uitkiezen."
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "c-values",
                                        class: {
                                          "c-values--max": _vm.haveMaxValues
                                        }
                                      },
                                      _vm._l(_vm.values, function(option) {
                                        return _c(
                                          "label",
                                          {
                                            key: option.uuid,
                                            staticClass: "c-value"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.checkboxModel[
                                                      option.uuid
                                                    ],
                                                  expression:
                                                    "checkboxModel[option.uuid]"
                                                }
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                disabled:
                                                  _vm.haveMaxValues &&
                                                  !_vm.checkboxModel[
                                                    option.uuid
                                                  ]
                                              },
                                              domProps: {
                                                value: option.uuid,
                                                checked: Array.isArray(
                                                  _vm.checkboxModel[option.uuid]
                                                )
                                                  ? _vm._i(
                                                      _vm.checkboxModel[
                                                        option.uuid
                                                      ],
                                                      option.uuid
                                                    ) > -1
                                                  : _vm.checkboxModel[
                                                      option.uuid
                                                    ]
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a =
                                                      _vm.checkboxModel[
                                                        option.uuid
                                                      ],
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = option.uuid,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.checkboxModel,
                                                          option.uuid,
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.checkboxModel,
                                                          option.uuid,
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.checkboxModel,
                                                      option.uuid,
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "c-value__content"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "c-value__model-wrapper"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "c-value__model"
                                                      },
                                                      [
                                                        _c("Model3D", {
                                                          attrs: {
                                                            model: option.model,
                                                            color: _vm.getColor(
                                                              option.type
                                                            )
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "c-value__name"
                                                  },
                                                  [_vm._v(_vm._s(option.name))]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "c-button c-button--main c-button--full",
                                        attrs: {
                                          type: "submit",
                                          "data-button-next": "",
                                          disabled:
                                            _vm.submitting || !_vm.haveMaxValues
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\tVersturen "
                                        ),
                                        _c(
                                          "svg",
                                          { staticClass: "icon icon_arrow" },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href": "#icon_arrow"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            : [
                                _c("Waiting", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function() {
                                          return [
                                            _c(
                                              "h2",
                                              { staticClass: "t-title" },
                                              [_vm._v("Gelukt!")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "t-text" },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "Jullie waarden zijn gekozen."
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    607147723
                                  )
                                })
                              ]
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1688428156
                )
              })
            : _c("Waiting")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }