<template>
  <div class="canvas-3d">

  </div>
</template>

<script>

import Model3DEngine from "./Model3DEngine.js";


export default {
  name: "Model3D",
  props: [
      'model',
      'color',
  ],

  methods: {
    newModel(model){
      this.model3D.loadModel(model);
    },

    newColor(color){
      this.model3D.newColor(color);
    },
    resize(){
      this.model3D.resize(this.$el.clientWidth, this.$el.clientHeight);
    }
  },

  watch: {
    model(){
      this.newModel(this.model);
    },
    color(){
      this.newColor(this.color);
    }
  },

  created() {


  },

  mounted(){
    this.model3D = new Model3DEngine(this.$el);

    this.newModel(this.model);
    this.newColor(this.color);

    this.resize();
  },

}
</script>

<style scoped>
.canvas-3d {
  width: 100%;
  height: 100%;
}
</style>