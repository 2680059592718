<template>
	<div class="v-loading">
		<div>Even geduld...</div>
	</div>
</template>

<script>
export default {
	name: 'Loading',
};
</script>

<style></style>
