export default [
	{
		intro: {
			title: 'Welkom bij de Nieuwsmaker',
			video: 'https://player.vimeo.com/external/601005127.hd.mp4?s=cb9c61bdecf8aceaf30ba0af5958af29ee34ac3d&profile_id=174',
		},
		selector: {
			categories: [
				{
					id: '1d8ff274-c2d7-11eb-8529-0242ac130003',
					title: 'Wat vindt de deskundige?',
					description:
						'Om mensen te overtuigen laten journalisten vaak deskundigen aan het woord. Dit zijn mensen die veel van het thema afweten. Soms ben je ook deskundige als je persoonlijke ervaring hebt met een onderwerp, dit noem je een ervaringsdeskundige. Selecteer een fragment.',
					videos: [
						{
							id: '883ebdf0-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596488734.hd.mp4?s=276ada34dfe586b50438c947a86ca9d777ad2b01&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/2DESKUNDIGETEGEN.jpeg`,
						},
						{
							id: '883ec07a-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596488789.hd.mp4?s=25be12030284e3b23fb33b4f57d41bd8582808ed&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/3DESKUNDIGETEGEN.jpeg`,
						},
						{
							id: '883ec21e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596488830.hd.mp4?s=46936ad1e383848158bdf09d1a497d89fa26ed1e&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/6DESKUNDIGENUANCE.jpeg`,
						},
						{
							id: '883ec35e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596492208.hd.mp4?s=83e06e435f138b0220fac218cfe316ed9015660f&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/8DESKUNDIGEVOOR.jpeg`,
						},
						{
							id: '883ec46c-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596489007.hd.mp4?s=a9897302c444f315e1a2b962f250d5b7e4c4206b&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/10DESKUNDIGEVOOR.jpeg`,
						},
						{
							id: '883ec58e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596498063.hd.mp4?s=d024c353500720e8db5bf0fd348c41f74a52eee6&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/12DESKUNDIGENUANCE.jpeg`,
						},
					],
				},
				{
					id: '1d8ff85a-c2d7-11eb-8529-0242ac130003',
					title: 'Wat vindt de voxpop?',
					description:
						'Voor jullie tweede fragment kiezen jullie een "gewone" burger uit, die noem je ook wel "voxpop", Latijn voor: "de mening van het volk". Journalisten gebruiken voxpops om een onderwerp een menselijk gezicht te geven.',
					videos: [
						{
							id: 'a8cafb2e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596489513.hd.mp4?s=fcc4e360c6a983a37e42fd86bec6c8a0f2d9f767&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/1VOXPOPVOOR.jpeg`,
						},
						{
							id: 'a8cafde0-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596494768.hd.mp4?s=5f5e8e6e8a2b416dab5ee7ed23373e76ae6d7fab&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/2VOXPOPVOOR.jpeg`,
						},
						{
							id: 'a8caffde-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596489743.hd.mp4?s=700be9029e9bc122a7dc41ed0e32dca9991d699a&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/4VOXPOPNUANCE.jpeg`,
						},
						{
							id: 'a8cb00ba-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596489813.hd.mp4?s=c7f824abec2a6e7d6ab2c974b00f26a9eb401baa&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/5VOXPOPNUANCE.jpeg`,
						},
						{
							id: 'a8cb01b4-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596489877.hd.mp4?s=c56161ee39ee6dedaa1708bc30234fb28943a898&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/9VOXPOPTEGEN.jpeg`,
						},
						{
							id: 'a8cb02cc-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/600857398.hd.mp4?s=875b08cd000414b270ed3ba913e32bde63b60aae&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/11VOXPOPTEGEN.jpeg`,
						},
					],
				},
				{
					id: '1d8ff918-c2d7-11eb-8529-0242ac130003',
					title: 'Wat vindt de andere deskundige?',
					description:
						'Het is extra sterk als een argument wordt weerlegt, zoals in het redeneerschema! Dan kun je namelijk een reden bedenken waarom het tegenargument niet klopt! Denk hieraan bij het kiezen van je volgende fragment.',
					videos: [
						{
							id: '883ebdf0-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596488734.hd.mp4?s=276ada34dfe586b50438c947a86ca9d777ad2b01&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/2DESKUNDIGETEGEN.jpeg`,
						},
						{
							id: '883ec07a-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596488789.hd.mp4?s=25be12030284e3b23fb33b4f57d41bd8582808ed&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/3DESKUNDIGETEGEN.jpeg`,
						},
						{
							id: '883ec21e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596488830.hd.mp4?s=46936ad1e383848158bdf09d1a497d89fa26ed1e&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/6DESKUNDIGENUANCE.jpeg`,
						},
						{
							id: '883ec35e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596492208.hd.mp4?s=83e06e435f138b0220fac218cfe316ed9015660f&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/8DESKUNDIGEVOOR.jpeg`,
						},
						{
							id: '883ec46c-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596489007.hd.mp4?s=a9897302c444f315e1a2b962f250d5b7e4c4206b&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/10DESKUNDIGEVOOR.jpeg`,
						},
						{
							id: '883ec58e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596498063.hd.mp4?s=d024c353500720e8db5bf0fd348c41f74a52eee6&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/12DESKUNDIGENUANCE.jpeg`,
						},
					],
				},
				{
					id: '1d8ff9d6-c2d7-11eb-8529-0242ac130003',
					title: 'Wat vindt de andere voxpop?',
					description: 'Sluit jullie nieuwsitem af met een overtuigend fragment wat de kijker zal onthouden!',
					videos: [
						{
							id: 'a8cafb2e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596489513.hd.mp4?s=fcc4e360c6a983a37e42fd86bec6c8a0f2d9f767&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/1VOXPOPVOOR.jpeg`,
						},
						{
							id: 'a8cafde0-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596494768.hd.mp4?s=5f5e8e6e8a2b416dab5ee7ed23373e76ae6d7fab&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/2VOXPOPVOOR.jpeg`,
						},
						{
							id: 'a8caffde-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596489743.hd.mp4?s=700be9029e9bc122a7dc41ed0e32dca9991d699a&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/4VOXPOPNUANCE.jpeg`,
						},
						{
							id: 'a8cb00ba-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596489813.hd.mp4?s=c7f824abec2a6e7d6ab2c974b00f26a9eb401baa&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/5VOXPOPNUANCE.jpeg`,
						},
						{
							id: 'a8cb01b4-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596489877.hd.mp4?s=c56161ee39ee6dedaa1708bc30234fb28943a898&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/9VOXPOPTEGEN.jpeg`,
						},
						{
							id: 'a8cb02cc-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/600857398.hd.mp4?s=875b08cd000414b270ed3ba913e32bde63b60aae&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/11VOXPOPTEGEN.jpeg`,
						},
					],
				},
			],
		},
		editor: {
			categories: [
				{
					id: '91bb614c-c50c-11eb-8529-0242ac130003',
					steps: [
						{
							id: '91bb6372-c50c-11eb-8529-0242ac130003',
							type: 'recording',
							title: 'Introduceer jullie standpunt!',
							recordingTime: 20,
							description:
								'Neem nu jullie eerste voice-over op. Hier introduceer je de stelling en jullie standpunt.<br><br>Gebruik hierbij het werkblad. Dit helpt jullie bij het schrijven van de voice-overs!<br><br>Let op! De voice-over is precies 20 seconde!',
						},
						{
							id: '91bb6462-c50c-11eb-8529-0242ac130003',
							type: 'video',
							title: 'Bepaal de sfeer!',
							description: 'Kies passende sfeerbeelden voor jullie introductie voice-over. De sfeerbeelden maken je introductie extra sterk!',
							videos: [
								{
									id: 'ca48a878-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/569731071.hd.mp4?s=e46b7b6603ba3bdedf9405cc371c79deb28e424d&profile_id=175',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/SFEER1.jpeg`,
								},
								{
									id: 'ca48aada-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/569731309.hd.mp4?s=ac4265cc2af846fc0a0b44a5fc85586c211047a6&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/SFEER2.jpeg`,
								},
								{
									id: 'ca48abca-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/569731520.hd.mp4?s=feebc4c4d91bdd44969024f11b51b431eb88fbf3&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/SFEER3.jpeg`,
								},
								{
									id: 'ca48ac92-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/566120247.hd.mp4?s=c9d5f576c6944347b283fcb092c511e69e2fae95&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/SFEER4.jpeg`,
								},
								{
									id: 'ca48af30-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/569731865.hd.mp4?s=fae0c1bd3afc4c16812a9a3fd3e16b3527ea2d2b&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/SFEER5.jpeg`,
								},
								{
									id: 'ca48b002-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/569732145.hd.mp4?s=c696962a1154351c822ff9eb7e54cc55dab9609e&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/SFEER6.jpeg`,
								},
							],
						},
					],
				},
				{
					id: '91bb66ba-c50c-11eb-8529-0242ac130003',
					steps: [
						{
							id: '91bb678c-c50c-11eb-8529-0242ac130003',
							type: 'recording',
							title: 'Nu de uitsmijter!',
							recordingTime: 15,
							description:
								'Nu gaan jullie aan de slag met de conclusie van het nieuwsitem! Hier rond je het verhaal af, geef je een korte samenvatting en een goede uitsmijter!  Dit is het laatste moment dat je de kijker kan overtuigen!<br><br>Gebruik hiervoor het werkblad!<br><br>Let op! De voice-over is precies 15 seconde!',
						},
						{
							id: '91bb684a-c50c-11eb-8529-0242ac130003',
							type: 'video',
							title: 'Bepaal de sfeer!',
							description: 'Kies nu passende sfeerbeelden voor jullie conclusie! Zorg ervoor dat je een blijvende indruk maakt in het laatste onderdeel van jullie nieuwsitem!',
							videos: [
								{
									id: 'ca48a878-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/569730885.hd.mp4?s=5fcca4b282bc769f18730608bf827a367f2a8519&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/SFEER1.jpeg`,
								},
								{
									id: 'ca48aada-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/569731197.hd.mp4?s=2bc6cd2fbb600f1a8a08a3d2b981ac36bdb16a7f&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/SFEER2.jpeg`,
								},
								{
									id: 'ca48abca-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/569731386.hd.mp4?s=32d026ebc4bc380f8d3ad178aaf970f34b41e1c4&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/SFEER3.jpeg`,
								},
								{
									id: 'ca48ac92-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/569731571.hd.mp4?s=6ac13eef1608f830e9b570ce5809440f8e3aabae&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/SFEER4.jpeg`,
								},
								{
									id: 'ca48af30-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/569731835.hd.mp4?s=3fc9a3db4ac6592ceba46a83fd2feea31b1f6efe&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/SFEER5.jpeg`,
								},
								{
									id: 'ca48b002-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/569732090.hd.mp4?s=411dc36809e026ed9660fedcc0fcc139688f98f5&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling1/SFEER6.jpeg`,
								},
							],
						},
					],
				},
				{
					id: '91bb68fe-c50c-11eb-8529-0242ac130003',
					type: 'music',
					title: 'Muziek',
					description: 'Bedenk bij het uitzoeken welke sfeer bij jullie nieuwsitem past.',
					tracks: [
						{
							id: 'c8bd53cc-c84e-11eb-b8bc-0242ac130003',
							url: 'https://drive.google.com/uc?id=1irhyXOLInhll1MPJMTo9HI2M7jgHSY22',
						},
						{
							id: 'c8bd50ac-c84e-11eb-b8bc-0242ac130003',
							url: 'https://drive.google.com/uc?id=1q95wph2V86ISuNRWULuk5u3CRPBIZ3rG',
						},
						{
							id: 'c8bd4fee-c84e-11eb-b8bc-0242ac130003',
							url: 'https://drive.google.com/uc?id=1DuxRemJ9jXTcHAsi1bvXKm_uOjgkQjCd',
						},
						{
							id: 'c8bd4fee-c84e-11eb-b8bc-0242ac130004',
							url: 'https://drive.google.com/uc?id=1RfPzOv3iZNb8w4ewEY5vska39l1p8Tp7',
						},
						{
							id: 'c8bd4fee-c84e-11eb-b8bc-0242ac130005',
							url: 'https://drive.google.com/uc?id=1DS0V38nYYyFQfAqo2c1FUhsHs34s46mb',
						},
						{
							id: 'c8bd4fee-c84e-11eb-b8bc-0242ac130006',
							url: 'https://drive.google.com/uc?id=1vxHKl22_r163Ds8Ce3dYKjsqSfMCw5qM',
						},
					],
				},
				{
					id: '91bb69b2-c50c-11eb-8529-0242ac130003',
					type: 'title',
					title: 'Titel',
					description: 'Bedenk een pakkende titel! Deze zal aan het begin van jullie nieuwsitem te zien zijn.',
				},
			],
		},
	},

	{
		intro: {
			title: 'Welkom bij de Nieuwsmaker',
			video: 'https://player.vimeo.com/external/601005127.hd.mp4?s=cb9c61bdecf8aceaf30ba0af5958af29ee34ac3d&profile_id=174',
		},
		selector: {
			categories: [
				{
					id: '1d8ff274-c2d7-11eb-8529-0242ac130003',
					title: 'Wat vindt de deskundige?',
					description:
						'Om mensen te overtuigen laten journalisten vaak deskundigen aan het woord. Dit zijn mensen die veel van het thema afweten. Soms ben je ook deskundige als je persoonlijke ervaring hebt met een onderwerp, dit noem je een ervaringsdeskundige. Selecteer een fragment.',
					videos: [
						{
							id: '883ebdf0-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585722297.hd.mp4?s=d7c374c562ae29eed8c9b64a9c97377bd9e8b19b&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/1DESKUNDIGEVOOR.jpeg`,
						},
						{
							id: '883ec07a-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596528912.hd.mp4?s=10f73289c2d49fe3160be5b476678a5d723babe1&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/3DESKUNDIGEVOOR.jpeg`,
						},
						{
							id: '883ec21e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585726015.hd.mp4?s=b0d474892184b8fecc94062ca00dc313f9bec33b&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/5DESKUNDIGENUANCE.jpeg`,
						},
						{
							id: '883ec35e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585730788.hd.mp4?s=1d7497dfb9658bba04a5efa4dc1f91baad4a87b3&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/7DESKUNDIGETEGEN.jpeg`,
						},
						{
							id: '883ec46c-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585713091.hd.mp4?s=ccfb6a1f68d181b95549f5ddcc70dbffb5198809&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/8DESKUNDIGETEGEN.jpeg`,
						},
						{
							id: '883ec58e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/598878930.hd.mp4?s=1563e1652ad359881d4f4bcab1ceeffe26e126a3&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/13DESKUNDIGETEGEN.jpeg`,
						},
					],
				},
				{
					id: '1d8ff85a-c2d7-11eb-8529-0242ac130003',
					title: 'Wat vindt de voxpop?',
					description:
						'Voor jullie tweede fragment kiezen jullie een "gewone" burger uit, die noem je ook wel "voxpop", Latijn voor: "de mening van het volk". Journalisten gebruiken voxpops om een onderwerp een menselijk gezicht te geven.',
					videos: [
						{
							id: 'a8cafb2e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585705400.hd.mp4?s=b1c395fc6ac22b61b173040f68bba7014aee483c&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/1VOXPOPTEGEN.jpeg`,
						},
						{
							id: 'a8cafde0-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585708745.hd.mp4?s=6c1275597ecf25180c6925cea789060891a7c332&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/2VOXPOPTEGEN.jpeg`,
						},
						{
							id: 'a8caffde-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585703370.hd.mp4?s=b0e080d46efdfa4bdc28aa14019707b837df4865&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/4VOXPOPTEGEN.jpeg`,
						},
						{
							id: 'a8cb00ba-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585708346.hd.mp4?s=f38a2af38263a66d0383d1d2c56857e0bb0f61e5&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/6VOXPOPNUANCE.jpeg`,
						},
						{
							id: 'a8cb01b4-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585706294.hd.mp4?s=870576d83f84322417481f426dca596ce611bf88&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/7VOXPOPVOOR.jpeg`,
						},
						{
							id: 'a8cb02cc-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585707271.hd.mp4?s=bcad3b92b7a5d4de167b1176eb6b81f2f29d0f34&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/8VOXPOPVOOR.jpeg`,
						},
					],
				},
				{
					id: '1d8ff918-c2d7-11eb-8529-0242ac130003',
					title: 'Wat vindt de andere deskundige?',
					description:
						'Het is extra sterk als een argument wordt weerlegt, zoals in het redeneerschema! Dan kun je namelijk een reden bedenken waarom het tegenargument niet klopt! Denk hieraan bij het kiezen van je volgende fragment.',
					videos: [
						{
							id: '883ebdf0-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585722297.hd.mp4?s=d7c374c562ae29eed8c9b64a9c97377bd9e8b19b&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/1DESKUNDIGEVOOR.jpeg`,
						},
						{
							id: '883ec07a-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/596528912.hd.mp4?s=10f73289c2d49fe3160be5b476678a5d723babe1&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/3DESKUNDIGEVOOR.jpeg`,
						},
						{
							id: '883ec21e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585726015.hd.mp4?s=b0d474892184b8fecc94062ca00dc313f9bec33b&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/5DESKUNDIGENUANCE.jpeg`,
						},
						{
							id: '883ec35e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585730788.hd.mp4?s=1d7497dfb9658bba04a5efa4dc1f91baad4a87b3&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/7DESKUNDIGETEGEN.jpeg`,
						},
						{
							id: '883ec46c-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585713091.hd.mp4?s=ccfb6a1f68d181b95549f5ddcc70dbffb5198809&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/8DESKUNDIGETEGEN.jpeg`,
						},
						{
							id: '883ec58e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/598878930.hd.mp4?s=1563e1652ad359881d4f4bcab1ceeffe26e126a3&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/13DESKUNDIGETEGEN.jpeg`,
						},
					],
				},
				{
					id: '1d8ff9d6-c2d7-11eb-8529-0242ac130003',
					title: 'Wat vindt de andere voxpop?',
					description: 'Sluit jullie nieuwsitem af met een overtuigend fragment wat de kijker zal onthouden!',
					videos: [
						{
							id: 'a8cafb2e-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585705400.hd.mp4?s=b1c395fc6ac22b61b173040f68bba7014aee483c&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/1VOXPOPTEGEN.jpeg`,
						},
						{
							id: 'a8cafde0-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585708745.hd.mp4?s=6c1275597ecf25180c6925cea789060891a7c332&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/2VOXPOPTEGEN.jpeg`,
						},
						{
							id: 'a8caffde-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585703370.hd.mp4?s=b0e080d46efdfa4bdc28aa14019707b837df4865&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/4VOXPOPTEGEN.jpeg`,
						},
						{
							id: 'a8cb00ba-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585708346.hd.mp4?s=f38a2af38263a66d0383d1d2c56857e0bb0f61e5&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/6VOXPOPNUANCE.jpeg`,
						},
						{
							id: 'a8cb01b4-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585706294.hd.mp4?s=870576d83f84322417481f426dca596ce611bf88&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/7VOXPOPVOOR.jpeg`,
						},
						{
							id: 'a8cb02cc-d7d9-11eb-b8bc-0242ac130003',
							url: 'https://player.vimeo.com/external/585707271.hd.mp4?s=bcad3b92b7a5d4de167b1176eb6b81f2f29d0f34&profile_id=174',
							thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/8VOXPOPVOOR.jpeg`,
						},
					],
				},
			],
		},
		editor: {
			categories: [
				{
					id: '91bb614c-c50c-11eb-8529-0242ac130003',
					steps: [
						{
							id: '91bb6372-c50c-11eb-8529-0242ac130003',
							type: 'recording',
							title: 'Introduceer jullie standpunt!',
							recordingTime: 20,
							description:
								'Neem nu jullie eerste voice-over op. Hier introduceer je de stelling en jullie standpunt.<br><br>Gebruik hierbij het werkblad. Dit helpt jullie bij het schrijven van de voice-overs!<br><br>Let op! De voice-over is precies 20 seconde!',
						},
						{
							id: '91bb6462-c50c-11eb-8529-0242ac130003',
							type: 'video',
							title: 'Bepaal de sfeer!',
							description: 'Kies passende sfeerbeelden voor jullie introductie voice-over. De sfeerbeelden maken je introductie extra sterk!',
							videos: [
								{
									id: 'ca48a878-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/598821022.hd.mp4?s=b99289353fc6226e1f7107645d47b7ce455c0a11&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/SFEER1.jpeg`,
								},
								{
									id: 'ca48aada-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/598838487.hd.mp4?s=d3f777e954055c8adda2ac153a2ddec764244f1e&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/SFEER2.jpeg`,
								},
								{
									id: 'ca48abca-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/598838391.hd.mp4?s=22671c41c41a838a2c5a915f2028e7a0e4d6aa13&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/SFEER3.jpeg`,
								},
								{
									id: 'ca48ac92-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/598820971.hd.mp4?s=c4ecfca8f0ad970aa2faaab21fd1b72b47057ac0&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/SFEER4.jpeg`,
								},
								{
									id: 'ca48af30-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/598848548.hd.mp4?s=6c171cee2c223e038e1d0af55ee9c1d7a87f6f4a&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/SFEER5.jpeg`,
								},
								{
									id: 'ca48b002-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/598865896.hd.mp4?s=6aa7d8d4469c96a55a7099c3a7d707755cbb5970&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/SFEER6.jpeg`,
								},
							],
						},
					],
				},
				{
					id: '91bb66ba-c50c-11eb-8529-0242ac130003',
					steps: [
						{
							id: '91bb678c-c50c-11eb-8529-0242ac130003',
							type: 'recording',
							title: 'Nu de uitsmijter!',
							recordingTime: 15,
							description:
								'Nu gaan jullie aan de slag met de conclusie van het nieuwsitem! Hier rond je het verhaal af, geef je een korte samenvatting en een goede uitsmijter!  Dit is het laatste moment dat je de kijker kan overtuigen!<br><br>Gebruik hiervoor het werkblad!<br><br>Let op! De voice-over is precies 15 seconde!',
						},
						{
							id: '91bb684a-c50c-11eb-8529-0242ac130003',
							type: 'video',
							title: 'Bepaal de sfeer!',
							description: 'Kies nu passende sfeerbeelden voor jullie conclusie! Zorg ervoor dat je een blijvende indruk maakt in het laatste onderdeel van jullie nieuwsitem!',
							videos: [
								{
									id: 'ca48a878-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/598820813.hd.mp4?s=bee5f80bcb7a129d79cb2720cf30aa45290d423a&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/SFEER1.jpeg`,
								},
								{
									id: 'ca48aada-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/598836256.hd.mp4?s=674bd28f6dc824c6b2a5a90f6caf8be40502954f&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/SFEER2.jpeg`,
								},
								{
									id: 'ca48abca-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/598836196.hd.mp4?s=03ba0eccc60dac0473887eed4be97b88f7f3f07f&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/SFEER3.jpeg`,
								},
								{
									id: 'ca48ac92-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/598820626.hd.mp4?s=58b248e45bb9bcd6e34949eaaf198c0d7b68cf9d&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/SFEER4.jpeg`,
								},
								{
									id: 'ca48af30-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/598848502.hd.mp4?s=574cd52e31a72dc5af517d9c966464a19bfa89ca&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/SFEER5.jpeg`,
								},
								{
									id: 'ca48b002-d7d9-11eb-b8bc-0242ac130003',
									url: 'https://player.vimeo.com/external/598865799.hd.mp4?s=20d0cbf6d68f7121eb761cf117f00deff7c46130&profile_id=174',
									thumbnail: `${process.env.MIX_APP_URL}/images/nieuwsmaker/stelling2/SFEER6.jpeg`,
								},
							],
						},
					],
				},
				{
					id: '91bb68fe-c50c-11eb-8529-0242ac130003',
					type: 'music',
					title: 'Muziek',
					description: 'Bedenk bij het uitzoeken welke sfeer bij jullie nieuwsitem past.',
					tracks: [
						{
							id: 'c8bd53cc-c84e-11eb-b8bc-0242ac130003',
							url: 'https://drive.google.com/uc?id=1irhyXOLInhll1MPJMTo9HI2M7jgHSY22',
						},
						{
							id: 'c8bd50ac-c84e-11eb-b8bc-0242ac130003',
							url: 'https://drive.google.com/uc?id=1q95wph2V86ISuNRWULuk5u3CRPBIZ3rG',
						},
						{
							id: 'c8bd4fee-c84e-11eb-b8bc-0242ac130003',
							url: 'https://drive.google.com/uc?id=1DuxRemJ9jXTcHAsi1bvXKm_uOjgkQjCd',
						},
						{
							id: 'c8bd4fee-c84e-11eb-b8bc-0242ac130004',
							url: 'https://drive.google.com/uc?id=1RfPzOv3iZNb8w4ewEY5vska39l1p8Tp7',
						},
						{
							id: 'c8bd4fee-c84e-11eb-b8bc-0242ac130005',
							url: 'https://drive.google.com/uc?id=1DS0V38nYYyFQfAqo2c1FUhsHs34s46mb',
						},
						{
							id: 'c8bd4fee-c84e-11eb-b8bc-0242ac130006',
							url: 'https://drive.google.com/uc?id=1vxHKl22_r163Ds8Ce3dYKjsqSfMCw5qM',
						},
					],
				},
				{
					id: '91bb69b2-c50c-11eb-8529-0242ac130003',
					type: 'title',
					title: 'Titel',
					description: 'Bedenk een pakkende titel! Deze zal aan het begin van jullie nieuwsitem te zien zijn.',
				},
			],
		},
	},
];
