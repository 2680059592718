<template>
	<div class="c-app-container u-slide-color--purple">
		<div class="c-bg"></div>
		<AppHeader :showMenu="false"></AppHeader>
		<QuestionsSlide :slide="slide" :storage-prefix="storagePrefix" @submit="submit"></QuestionsSlide>
	</div>
</template>

<script>
// Components
import AppHeader from './components/AppHeader';
import QuestionsSlide from './slides/QuestionsSlide';

export default {
	name: 'Questions',
	components: {
		QuestionsSlide,
		AppHeader,
	},
	props: {
    settings: {
      default: {},
    },
    postUrl: {
      default: '/api/v1/student/questions',
    },
    storagePrefix: {
      default: '',
    }
  },
	data() {
		return {
			user: null,
			loading: true,
		};
	},
	computed: {
		slide() {
			// console.log(this.questions);
			return {
				settings: {
					...this.settings,
				},
			};
		},
	},
	methods: {
		submit(data) {
			window.axios.post(this.postUrl, data).then(response => {
				if (response.data.redirect) {
					window.location = response.data.redirect;
				}
			});
		},
	},
};
</script>

<style></style>
