<template>
	<div class="c-planet">
		<Planet :moons="objects" :colors="colors" :showMoons="showMoons" :options="options" />
	</div>
</template>

<script>
import Planet from './Planet.vue';
// import ValueTypes from '../../../components/value-types';

export default {
	name: 'TeamPlanet',
	components: {
		Planet,
	},
	props: {
		team: Object,
		showMoons: {
			type: Boolean,
			default: false,
		},
		showTeamColors: {
			type: Boolean,
			default: false,
		},
		options: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	computed: {
		objects() {
			return this.$actions.getTeamValues(this.team).map(value => {
				return {
					model: value.model,
					color: this.$actions.getValueType(value.type).objectColor,
				};
			});
		},
		teamType() {
			return this.$actions.getTeamType(this.team);
		},
		teamColors() {
			const type = this.$actions.getValueType(this.teamType);
			return type?.planetColors || [];
		},
		colors() {
			if (this.showTeamColors) {
				if (this.teamColors.length) {
					return this.teamColors;
				}
			}

			const colors = this.team?.settings?.colors;

			if (typeof colors == 'object') {
				return colors;
			}

			if (colors) {
				return JSON.parse(colors);
			}

			return ['#1a6959', '#e2451a'];
		},
	},
};
</script>

<style></style>
