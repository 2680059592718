var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-recorder" }, [
    _c(
      "div",
      { staticClass: "c-recorder__wrapper" },
      [
        _c(
          "transition",
          { attrs: { name: "fade", mode: "out-in", appear: "" } },
          [
            _vm.state == "initial"
              ? _c("div", { key: "initial", staticClass: "c-recorder__step" }, [
                  _c("div", { staticClass: "c-recorder__buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "c-record-button",
                        attrs: { type: "button" },
                        on: { click: _vm.start }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 16 24"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M15.118 11.676c.5 0 .882.363.882.838 0 3.885-3.059 7.154-7.118 7.574v2.235h3.206c.5 0 .883.364.883.839s-.383.838-.883.838H3.912c-.5 0-.883-.363-.883-.838s.383-.839.883-.839h3.206v-2.235C3.058 19.668 0 16.398 0 12.514c0-.475.382-.838.882-.838s.883.363.883.838c0 3.27 2.794 5.925 6.235 5.925 3.441 0 6.235-2.655 6.235-5.925 0-.475.383-.838.883-.838zM8 0c2.57 0 4.667 2.087 4.667 4.648v7.57c0 2.56-2.096 4.647-4.667 4.647-2.57-.028-4.667-2.087-4.667-4.676V4.648C3.333 2.088 5.43 0 8 0z",
                                "fill-rule": "nonzero"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "c-recorder__text" }, [
                    _vm._v(
                      "\n\t\t\t\t\tKlik op de knop om op te nemen\n\t\t\t\t"
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "c-recorder__help-text" }, [
                    _vm._v("(precies " + _vm._s(_vm.recordingTime) + " sec)")
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.state == "recording"
              ? _c(
                  "div",
                  { key: "recording", staticClass: "c-recorder__step" },
                  [
                    _c("div", { staticClass: "c-recorder__buttons" }, [
                      _c(
                        "div",
                        { staticClass: "c-recording-button" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "c-stop-button c-stop-button--small",
                              attrs: { type: "button" },
                              on: { click: _vm.stop }
                            },
                            [_c("span")]
                          ),
                          _vm._v(" "),
                          _c("RecordingTimer", {
                            attrs: {
                              time: _vm.recordingTime,
                              running: _vm.timerRunning
                            },
                            on: { "timer-finished": _vm.stop }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.state == "recorded"
              ? _c(
                  "div",
                  { key: "recorded", staticClass: "c-recorder__step" },
                  [
                    _c("div", { staticClass: "c-recorder__buttons" }, [
                      _c(
                        "button",
                        {
                          staticClass: "c-retry-button",
                          attrs: { type: "button" },
                          on: { click: _vm.reset }
                        },
                        [_vm._v("Opnieuw")]
                      ),
                      _vm._v(" "),
                      _vm.src
                        ? _c("audio", {
                            ref: "audio",
                            staticClass: "c-recorder__audio",
                            attrs: {
                              controls: "",
                              src: _vm.src,
                              controlslist: "nodownload",
                              preload: "auto"
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.showDurationWarning
                      ? _c("div", { staticClass: "c-recorder__help-text" }, [
                          _vm._v(
                            "\n\t\t\t\t\tJe audiofragment is een beetje kort (" +
                              _vm._s(_vm.duration) +
                              " seconden). Probeer ongeveer " +
                              _vm._s(_vm.recordingTime) +
                              " seconden te halen.\n\t\t\t\t"
                          )
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }