var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-nm-audio" }, [
    _c("audio", {
      ref: "audio",
      staticClass: "c-nm-audio__audio",
      attrs: { src: _vm.audio.url, controls: "" },
      on: {
        play: function($event) {
          return _vm.$emit("ply", _vm.audio.id)
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }