<template>
	<div class="c-question">
		<template v-if="steps">
			<div class="c-steps">Vraag {{ steps.currentStep }}/{{ steps.totalSteps }}</div>
		</template>
		<div class="t-text">
			{{ question.text }}
		</div>
		<!-- TODO: media -->
		<div class="c-question__media" v-for="item in question.media" :key="item.id">
			<template v-if="item.type === 'video'">
				<div class="c-question__video">
					<video v-bind:src="item.src" controls playsinline disableRemotePlayback></video>
				</div>
			</template>
		</div>
		<div class="c-question__form-fields">
			<div v-for="field in fields" :key="field.name">
				<!-- TODO: omzetten naar aparte components? -->
				<template v-if="field.type === 'textarea'">
					<div class="c-input c-input--textarea">
						<label v-bind:for="field.name">{{ field.label }}</label>
						<textarea
							@input="updateValue(field.name, ...arguments)"
							@change="updateValue(field.name, ...arguments)"
							@keypress="updateValue(field.name, ...arguments)"
							:id="field.name"
							rows="10"
            >{{answers[field.name]}}</textarea>
					</div>
				</template>
				<template v-if="field.type === 'radio'">
          <h2 v-if="field.question">{{field.question}}</h2>
					<div class="c-radio-button" v-for="(option, index) in field.options" :key="index">
						<input
                type="radio"
                :name="field.name"
                :id="`${field.name}_${index}`"
                :value="option.value"
                :checked="answers[field.name]==option.value"
                @change="updateValue(field.name, ...arguments)"
                @click="updateValue(field.name, ...arguments)"
            />
						<label :for="`${field.name}_${index}`">{{ option.label }}</label>
					</div>
				</template>
			</div>
			<div class="c-question__footer">
				<button type="button" class="c-button c-button--transparent" data-button-prev v-on:click="$emit('prev')" v-if="isFirst">Vorige</button>
				<button
					type="button"
					class="c-button c-button--main c-button--purple"
					data-button-next
					v-else-if="steps.totalSteps > 1 && !isLast"
					v-on:click="$emit('next')"
					v-bind:disabled="disabled"
				>
					Volgende vraag
				</button>
				<button type="submit" class="c-button c-button--main c-button--purple" data-button-next v-if="isLast" v-bind:disabled="disabled">Verzenden</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Question',
	props: {
		question: Object,
		steps: Object,
		submitting: Boolean,
    answers: Object,
	},
	data() {
		return {
			values: {},
		};
	},
	computed: {
		fields() {
			return this.question.fields || [];
		},
		disabled() {
			if (this.submitting) {
				return true;
			}

			console.log('Question.computed.disabled', this.fields);
			// Simpele validatie
			return !this.fields.filter(field => !!this.values[field.name]).length;
		},
		isFirst() {
			return !this.steps?.currentStep > 1;
		},
		isLast() {
			if (!this.steps) {
				return true;
			}
			return this.steps?.currentStep == this.steps?.totalSteps;
		},
	},
	methods: {
		updateValue(name, evt) {
      console.log('Question.updateValue', name, evt);
			const value = evt.target.value;
			this.$set(this.values, name, value);
			this.$emit('update', name, value);
      console.log(this.values);
		},
	},
  mounted() {
	  this.values = this.answers;
  },
};
</script>

<style></style>
