var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "u-box t-text" }, [
    _c(
      "div",
      {
        staticClass: "c-simple-collapse",
        class: { "c-simple-collapse--open": _vm.isOpen }
      },
      [
        _c("div", {
          staticClass: "c-simple-collapse__content",
          domProps: { innerHTML: _vm._s(_vm.text) }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "c-simple-collapse__button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                _vm.isOpen = !_vm.isOpen
              }
            }
          },
          [_vm._v(_vm._s(_vm.buttonLabel))]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }