var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-editor-preview__videos" }, [
    _vm.currentVideo.url
      ? _c("video", {
          ref: "video",
          staticClass: "c-selector-preview__video",
          attrs: {
            src: _vm.currentVideo.url,
            disablepictureinpicture: "",
            disableremoteplayback: "",
            preload: "auto",
            autoplay: _vm.autoplay
          },
          on: { ended: _vm.ended }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }