<template>
	<transition name="fade" mode="out-in" appear>
		<div class="c-nieuwsmaker-modal">
			<slot></slot>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'NieuwsmakerModal',
};
</script>

<style></style>
