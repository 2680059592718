var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "c-big-planet" },
      [
        _c("TeamPlanet", { attrs: { team: _vm.team } }),
        _vm._v(" "),
        _c("div", { staticClass: "c-big-planet__name" }, [
          _vm._v(_vm._s(_vm.team.name))
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "c-team" },
      _vm._l(_vm.members, function(member) {
        return _c("li", { key: member.uuid, staticClass: "c-team__member" }, [
          _vm._v("\n\t\t\t" + _vm._s(member.name) + "\n\t\t")
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }