<template>
	<div class="c-editor-preview__recording">
		<video class="c-selector-preview__video" v-bind:src="video" v-on:ended="ended()" ref="video" muted disablepictureinpicture disableremoteplayback preload="auto"></video>
		<audio class="c-editor-preview__music" v-bind:src="audio" ref="audio" preload="auto"></audio>
	</div>
</template>

<script>
export default {
	name: 'PreviewRecording',
	props: {
		recording: Object,
		playing: Boolean,
		active: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			video: '',
			audio: '',
		};
	},
	watch: {
		active: {
			handler() {
				this.$nextTick(() => {
					if (this.active && this.playing) {
						this.stop();
						this.play();
					} else {
						this.stop();
					}
				});
			},
			immediate: true,
		},
	},
	created() {
		this.video = this.recording.video.url;

		fetch(this.recording.recording)
			.then(res => res.blob())
			.then(blob => {
				this.audio = window.URL.createObjectURL(blob);
			});
	},
	methods: {
		play() {
			this.$refs.video.play();
			this.$refs.audio.play();
		},
		stop() {
			this.$refs.video.pause();
			this.$refs.audio.pause();
		},
		ended() {
			if (this.$refs.video) {
				this.$refs.video.pause();
			}

			if (this.$refs.audio) {
				this.$refs.audio.pause();
			}

			if (this.playing) {
				this.$emit('ended');
			}
		},
	},
	beforeDestroy() {
		this.stop();
	},
};
</script>

<style></style>
