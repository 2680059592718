<template>
	<button
		v-bind:class="{ 'is-active': ids.currentId == step.id, 'is-complete': complete }"
		type="button"
		class="c-video-selector-step"
		v-on:click="$emit('current-step', { currentId: step.id, currentSubId: '' })"
	>
		<div class="c-video-selector-step__frame">
			<svg v-if="showMusicSelected" class="c-video-selector-step__music-selected" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 39">
				<path
					d="M44.399 13.187c1.629 0 2.538.647 3.123 1.37l.053.065.05.067.05.066.047.067.045.066.045.067.043.066.041.066.04.066.04.065.057.097.072.127.213.385.047.084.047.08.031.054.032.052.032.051.033.05.033.05.017.024.035.048.035.047.036.046.038.045.038.044c.352.39.858.654 1.86.742l.114.009.117.007c.08.005.163.008.249.01l.13.004.205.002c.858 0 1.553.686 1.553 1.533 0 .847-.695 1.533-1.553 1.533-3.085 0-4.47-1.018-5.359-2.26l-.056-.079-.054-.08-.026-.04v16.108c0 .06-.005.12-.012.179a4.591 4.591 0 01-2.27 3.672l-.048.029-.897.51a4.676 4.676 0 01-3.535.46 4.62 4.62 0 01-2.828-2.143c-1.272-2.175-.535-4.96 1.639-6.245l.066-.039.896-.51a4.68 4.68 0 013.797-.382l.086.03V14.72c0-.847.695-1.533 1.553-1.533zM41.29 32.353c-.253 0-.503.062-.731.183l-.043.023-.896.511a1.525 1.525 0 00-.569 2.095c.208.355.542.608.943.714.387.103.791.055 1.142-.133l.036-.02.897-.511a1.525 1.525 0 00.568-2.095 1.54 1.54 0 00-.943-.714 1.58 1.58 0 00-.404-.053zM25.644.116a1.57 1.57 0 011.455.143c.42.278.677.74.689 1.238V20.697l-.002.054a4.592 4.592 0 01-2.279 3.931l-.048.029-.897.51a4.677 4.677 0 01-3.535.46 4.62 4.62 0 01-2.828-2.143c-1.272-2.175-.535-4.96 1.639-6.245l.066-.039.896-.51a4.679 4.679 0 013.797-.382l.086.03v-6.41l-14.47 5.892v11.964l-.003.054a4.59 4.59 0 01-2.279 3.945l-.048.028-.896.511a4.677 4.677 0 01-3.535.46 4.62 4.62 0 01-2.829-2.143c-1.271-2.175-.534-4.96 1.639-6.245l.066-.039.897-.511a4.68 4.68 0 013.796-.381l.086.029V8.689c0-.61.366-1.16.93-1.404l.031-.014L25.644.116zM5.55 26.347c-.253 0-.503.062-.731.183l-.043.023-.896.512a1.525 1.525 0 00-.568 2.094 1.56 1.56 0 002.085.581l.036-.02.896-.51a1.525 1.525 0 00.569-2.095 1.54 1.54 0 00-.943-.715 1.577 1.577 0 00-.405-.053zm17.576-7.154c-.253 0-.503.061-.731.182l-.043.024-.896.51a1.525 1.525 0 00-.569 2.095 1.56 1.56 0 002.085.581l.036-.02.897-.51a1.525 1.525 0 00.568-2.095 1.54 1.54 0 00-.943-.715 1.577 1.577 0 00-.404-.052zm1.556-15.37l-14.47 5.892v2.842l14.47-5.892V3.824z"
					fill="#3ACBB0"
					fill-rule="nonzero"
				/>
			</svg>
			<div v-if="showTitle">
				{{ step.title }}
			</div>
		</div>
		<div class="c-video-selector-step__label">{{ step.label }}</div>
	</button>
</template>

<script>
export default {
	name: 'VideoEditorStep',
	props: {
		step: Object,
		ids: Object,
	},
	computed: {
		complete() {
			return this.showMusicSelected || this.showTitle;
		},
		showMusicSelected() {
			return this.step.type == 'music' && this.step.music?.url;
		},
		showTitle() {
			return this.step.type == 'title' && this.step.title;
		},
	},
};
</script>

<style></style>
