import { render, staticRenderFns } from "./Recorder.vue?vue&type=template&id=25cd061a&"
import script from "./Recorder.vue?vue&type=script&lang=js&"
export * from "./Recorder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/cmgames.septemberdev.nl/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25cd061a')) {
      api.createRecord('25cd061a', component.options)
    } else {
      api.reload('25cd061a', component.options)
    }
    module.hot.accept("./Recorder.vue?vue&type=template&id=25cd061a&", function () {
      api.rerender('25cd061a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/student/vue/nieuwsmaker/components/Recorder.vue"
export default component.exports