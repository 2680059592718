<template>
	<div class="c-nieuwsmaker-frame">
		<div class="c-nieuwsmaker-frame__main">
			<div class="c-nieuwsmaker-frame__content">
				<div class="c-nieuwsmaker-logo">
					<img :src="logo" :alt="appName + ' logo'" class="c-nieuwsmaker-logo__img" />
				</div>
				<div class="c-nieuwsmaker-frame__monitor">
					<SelectorMonitor v-bind:currentStep="currentStep" v-bind:previewPlaying="preview" :key="currentStep.id" v-if="sidebar == 'video'"></SelectorMonitor>
					<Recorder v-if="sidebar == 'recording'" v-bind:currentStep="currentStep" v-bind:currentSubStep="currentSubStep" :key="currentStep.id"></Recorder>
					<MusicPreview v-if="sidebar == 'music'" v-bind:currentStep="currentStep" v-bind:previewPlaying="preview" :key="currentStep.id"></MusicPreview>
					<TitleEditor v-if="sidebar == 'title'" v-bind:currentStep="currentStep" v-on:input="currentStep.title = $event" :key="currentStep.id"></TitleEditor>
				</div>
			</div>
			<div class="c-nieuwsmaker-frame__bar">
				<div class="c-selector-nav c-selector-nav--editor">
					<div class="c-selector-nav__steps">
						<component v-for="step in steps" v-bind:is="step.component" v-bind:step="step" v-bind:ids="ids" :key="step.id" v-on:current-step="ids = $event"></component>

						<button type="button" class="c-video-selector-step c-video-selector-step--next-view" v-if="ready" v-on:click="showPremiere">
							<div class="c-video-selector-step__frame">
								Klaar voor de premiere
							</div>
							<div class="c-video-selector-step__label">&nbsp;</div>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="c-nieuwsmaker-frame__sidebar">
			<Sidebar v-if="sidebar == 'recording'" v-bind:currentId="currentId" v-bind:currentSubId="currentSubId"></Sidebar>
			<VideoSidebar
				v-if="sidebar == 'video'"
				v-bind:currentId="currentId"
				v-bind:currentSubId="currentSubId"
				v-bind:currentStep="currentStep"
				v-on:video-selected="currentStep.video = $event"
			></VideoSidebar>
			<MusicSidebar v-if="sidebar == 'music'" v-bind:currentId="currentId" v-bind:currentStep="currentStep" v-on:music-selected="currentStep.music = $event"></MusicSidebar>
			<Sidebar v-if="sidebar == 'title'" v-bind:currentId="currentId"></Sidebar>
		</div>

		<NieuwsmakerModal v-if="preview">
			<EditorPreview v-on:close="preview = false"></EditorPreview>
		</NieuwsmakerModal>
	</div>
</template>

<script>
// Helpers etc.
import { getters, mutations } from '../store.js';

// Components
import NieuwsmakerModal from '../components/NieuwsmakerModal';
import VideoEditorStep from '../components/VideoEditorStep';
import VideoEditorMultiStep from '../components/VideoEditorMultiStep';

import Sidebar from '../components/Sidebar';
import VideoSidebar from '../components/VideoSidebar';
import MusicSidebar from '../components/MusicSidebar';

import SelectorMonitor from '../components/SelectorMonitor';
import Recorder from '../components/Recorder';
import MusicPreview from '../components/MusicPreview';
import TitleEditor from '../components/TitleEditor';

import EditorPreview from '../components/EditorPreview';

export default {
	name: 'VideoEditor',
	components: {
		NieuwsmakerModal,
		VideoEditorStep,
		VideoEditorMultiStep,
		Sidebar,
		VideoSidebar,
		MusicSidebar,
		SelectorMonitor,
		Recorder,
		MusicPreview,
		TitleEditor,
		EditorPreview,
	},
	data() {
		return {
			ids: {
				currentId: '',
				currentSubId: '',
			},
			steps: [],
			preview: false,
			logo: window.settings.nieuwsmaker_logo,
			appName: window.settings.name,
		};
	},
	computed: {
		result() {
			return getters.result();
		},
		cmsData() {
			return getters.cmsData();
		},
		currentState() {
			return getters.currentState();
		},
		currentId() {
			return this.ids.currentId;
		},
		currentSubId() {
			return this.ids.currentSubId;
		},
		currentStep() {
			if (!this.currentId) {
				return null;
			}

			return this.steps.find(step => step.id == this.currentId);
		},
		currentSubStep() {
			if (!this.currentSubId) {
				return null;
			}

			return this.currentStep.steps.find(step => step.id == this.currentSubId);
		},
		sidebar() {
			if (this.currentSubStep) {
				return this.currentSubStep.type;
			}

			if (this.currentStep) {
				return this.currentStep.type;
			}

			return null;
		},
		ready() {
			return this.steps.every(step => {
				if (typeof step.steps !== 'undefined' && step.steps.length) {
					// Multi steps hebben een video + een recording nodig
					if (step.video?.url && step.recording) {
						return true;
					}
				} else {
          switch(step.type){
            case 'music':
              return !!step.music;

            case 'recording':
              return !!step.recording;

            case 'title':
              return !!step.title;
          }

				}

				return false;
			});
		},
	},
	methods: {
		showPremiere() {
			mutations.clearRecordings();

			this.steps.forEach(step => {
				switch (step.type) {
					case 'recording':
						mutations.setRecording({
							video: step.video,
							recording: step.recording,
						});
						break;

					case 'music':
						mutations.setMusic(step.music);
						break;

					case 'title':
						mutations.setTitle(step.title);
						break;

					default:
						break;
				}
			});

			this.preview = true;

			localStorage.setItem('nieuwsmaker_editor', JSON.stringify(this.result));
		},
	},
	mounted() {
		const categories = this.cmsData[this.currentState].categories;
		const [firstCategory] = categories;
		this.ids.currentId = firstCategory.id;

		if (typeof firstCategory.steps !== 'undefined' && firstCategory.steps.length) {
			const [firstSubStep] = firstCategory.steps;
			this.ids.currentSubId = firstSubStep.id;
		}

		let result = localStorage.getItem('nieuwsmaker_editor');
		let recordings = [];
		let recordingIndex = 0;

		if (result) {
			result = JSON.parse(result);
			const recordingCat = result.find(cat => cat.id == 'recordings');
			recordings = recordingCat.recordings;
		}

		this.steps = categories.map((category, index) => {
			let component = null;
			let type = null;
			let video = {};
			let recording = '';
			let title = '';
			let music = '';

			if (typeof category.steps !== 'undefined' && category.steps.length) {
				component = 'VideoEditorMultiStep';
				type = 'recording';

				if (recordings.length) {
					video = recordings[recordingIndex].video;
					recording = recordings[recordingIndex].recording;
					recordingIndex++;
				}
			} else {
				component = 'VideoEditorStep';
				type = category.type;
			}

			if (category.type == 'music' && result) {
				const musicCat = result.find(cat => cat.id == 'music');
				music = musicCat.music;
			}

			if (category.type == 'title' && result) {
				const titleCat = result.find(cat => cat.id == 'title');
				title = titleCat.text;
			}

			return {
				id: category.id,
				type: type,
				label: index + 1,
				steps: category.steps,
				component: component,
				// User selected properties:
				video: video,
				recording: recording,
				music: music,
				title: title,
			};
		});
	},
};
</script>

<style></style>
