<template>
	<div class="v-idle">
		<div>Even geduld...</div>
		<!-- <template v-if="!slide">
			<div>Geen actief station</div>
		</template>
		<template v-else>
			<div v-if="slide">Vorig Slide ID: {{ slide }}</div>
		</template> -->
	</div>
</template>

<script>
export default {
	name: 'Idle',
	components: {},
	// computed: {
	// 	slide() {
	// 		if (this.$store.station) {
	// 			return this.$store.station.slide;
	// 		}
	// 	},
	// },
};
</script>

<style></style>
