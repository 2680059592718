<template>
	<div>
		<div class="c-bg"></div>
		<div class="c-content">
			<Questions
          v-if="settings.questions"
          :settings="settings"
          post-url="/api/v1/student/questions/2"
          storage-prefix="second-"
      />
		</div>
	</div>
</template>

<script>
import Questions from "../Questions";

export default {
	name: 'EvaluationQuestionsSlide',
	components: {
    Questions,
	},
	props: {
		slide: Object,
		user: null,
	},
	data() {
		return {
			step: null,
      settings: {},
			data: {},
		};
	},
	computed: {

	},
	beforeCreate() {
		window.axios.get('/api/v1/student/questions/2')
      .then(({data}) => {
        this.settings = {
          questions: data,
        };
      });
	},
	methods: {

		submit() {

		},

	},
};
</script>

<style></style>
