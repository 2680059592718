<template>
	<div class="c-sidebar">
		<div class="c-sidebar__header">
			<h3 class="c-sidebar__title">{{ title }}</h3>
			<div class="c-sidebar__description" v-html="description"></div>
		</div>
		<div class="c-sidebar__items"></div>
	</div>
</template>

<script>
import sidebarMixin from '../mixins/sidebar';

export default {
	name: 'Sidebar',
	mixins: [sidebarMixin],
};
</script>

<style></style>
