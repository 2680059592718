var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-sidebar" }, [
    _c("div", { staticClass: "c-sidebar__header" }, [
      _c("h3", { staticClass: "c-sidebar__title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "c-sidebar__description",
        domProps: { innerHTML: _vm._s(_vm.description) }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-sidebar__items" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }