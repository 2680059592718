var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-selector-monitor" }, [
    _c("div", { staticClass: "c-selector-monitor__wrapper" }, [
      _vm.video
        ? _c("video", {
            ref: "video",
            staticClass: "c-selector-monitor__video",
            attrs: {
              src: _vm.video,
              controls: "",
              controlslist: "nodownload",
              disablepictureinpicture: "",
              disableremoteplayback: "",
              autoplay: "",
              preload: "auto"
            },
            on: { loadedmetadata: _vm.setDuration }
          })
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-selector-monitor__duration" }, [
      _vm._v(_vm._s(_vm.duration))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }