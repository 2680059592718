import * as THREE from 'three';

// Planet
export default class Orbit {
    constructor(planet, size, speed, moonSlots) {

        this.speed = speed;
        this.size = size;

        this.moonCount = 0;
        this.slots = moonSlots;

        // Orbit
        const geometry = new THREE.CircleGeometry(this.size, 20);
        const material = new THREE.LineBasicMaterial({
            color: planet.color1,
            transparent: true,
            opacity: 0
        });
        const orbit = new THREE.Line(geometry, material);
        const moonOrbit = new THREE.Group();
        moonOrbit.add(orbit);

        this.orbit = new THREE.Group();
        this.orbit.add(moonOrbit);
        this.orbit.position.set(planet.positionX, planet.positionY, planet.positionZ);
        this.orbit.rotation.x = 5.25;
        this.orbit.rotation.y = 0.25;
        planet.scene.add(this.orbit);

    }

    update(dt) {
        this.orbit.rotation.z -= this.speed * dt;
    }

    clear(){
    	this.moonCount = 0;
    	this.orbit.clear();
	}

};
