<template>
	<div class="c-content">
		<div class="c-bg"></div>
		<div class="u-container">
			<ul class="c-planet-list">
				<li class="c-planet-item" v-for="team in teams" :key="team.uuid">
					<div class="c-planet-item__shape">
						<TeamPlanet v-bind:team="team"></TeamPlanet>
					</div>
					<div class="c-planet-item__info">
						<div class="c-planet-item__name">{{ team.name }}</div>
						<div class="c-planet-item__objects">{{ getTeamVotes(team) }} {{ getTeamVotes(team) == 1 ? 'stem' : 'stemmen' }}</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import TeamPlanet from '../components/TeamPlanet.vue';

export default {
	name: 'ResultsSlide',
	components: {
		TeamPlanet,
	},
	props: {
		slide: Object,
		user: null,
		showMoons: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		getTeamVotes(team) {
			const votes = this.votes;
			return votes.find(x => x.team.uuid == team.uuid)?.count || 0;
		},
	},
	computed: {
		votes() {
			return this.$actions.getVotes(this.slide?.settings?.subject);
		},
		teams() {
			return this.$actions.getTeams();
		},
	},
};
</script>

<style scoped>
.planet-3d {
	width: 100%;
	height: 100%;
}
</style>
