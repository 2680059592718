var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "t-title" }, [_vm._v("Planeten")]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "c-planet-list" },
      _vm._l(_vm.teams, function(team) {
        return _c(
          "li",
          { key: team.uuid, staticClass: "c-planet-item" },
          [
            _c("TeamPlanet", { attrs: { team: team } }),
            _vm._v(" "),
            _c("div", { staticClass: "c-planet-item__info" }, [
              _c("div", { staticClass: "c-planet-item__name" }, [
                _vm._v(_vm._s(team.name))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "c-planet-item__objects" }, [
                _vm._v(
                  _vm._s(_vm.$actions.getTeamValues(team).length) + " objecten"
                )
              ])
            ])
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }