var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-sidebar c-sidebar--video" }, [
    _c("div", { staticClass: "c-sidebar__header" }, [
      _c("h3", { staticClass: "c-sidebar__title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-sidebar__description" }, [
        _vm._v("\n\t\t\t" + _vm._s(_vm.description) + "\n\t\t")
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-sidebar__items" },
      _vm._l(_vm.videos, function(video) {
        return _c(
          "button",
          {
            key: video.id,
            staticClass: "c-sidebar-video",
            class: { "is-active": _vm.currentStep.video.id == video.id },
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("video-selected", video)
              }
            }
          },
          [
            _c("img", {
              staticClass: "c-sidebar-video__thumb",
              attrs: { src: video.thumbnail, alt: "" }
            })
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }