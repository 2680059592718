var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "u-slide-color--purple-orange" }, [
    _c("div", { staticClass: "c-bg" }),
    _vm._v(" "),
    _c("div", { staticClass: "c-content" }, [
      _c(
        "div",
        { staticClass: "c-tabs" },
        _vm._l(_vm.tabs, function(tab) {
          return _c(
            "button",
            {
              key: tab.component,
              staticClass: "c-tabs__tab",
              class: { "is-active": _vm.currentTab == tab.component },
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  _vm.currentTab = tab.component
                }
              }
            },
            [_vm._v("\n\t\t\t\t" + _vm._s(tab.label) + "\n\t\t\t")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "u-container" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [
              _c(_vm.currentTab, {
                tag: "component",
                attrs: { user: _vm.user }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }