<template>
	<div class="c-nm-audio">
		<audio ref="audio" class="c-nm-audio__audio" v-bind:src="audio.url" v-on:play="$emit('ply', audio.id)" controls></audio>
	</div>
</template>

<script>
export default {
	name: 'SimpleAudio',
	props: {
		audio: Object,
		playing: String,
	},
	watch: {
		playing: {
			immediate: true,
			handler(val) {
				if (!this.$refs.audio) {
					return false;
				}

				if (val !== this.audio.id) {
					this.$refs.audio.pause();
				}
			},
		},
	},
};
</script>

<style></style>
