var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-editor-preview" },
    [
      !_vm.presentation
        ? [
            _c(
              "button",
              {
                staticClass: "c-editor-preview__close c-button c-button--main",
                attrs: { type: "button" },
                on: { click: _vm.close }
              },
              [_vm._v("sluiten")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "c-editor-preview__buttons" }, [
              _c(
                "button",
                {
                  staticClass: "c-editor-preview__play c-button c-button--main",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.playing = !_vm.playing
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.playing ? "stoppen" : "afspelen"))]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "c-button c-button--main",
                  attrs: { type: "button" },
                  on: { click: _vm.submit }
                },
                [_vm._v("nieuwsmaker insturen")]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "c-editor-preview__wrapper" }, [
        _c(
          "div",
          { staticClass: "c-editor-preview__frame" },
          [
            _c("PreviewTitle", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentPart == "title_0",
                  expression: "currentPart == 'title_0'"
                }
              ],
              attrs: {
                title: _vm.title,
                playing: _vm.playing,
                active: _vm.currentPart == "title_0"
              },
              on: {
                ended: function($event) {
                  return _vm.nextPart()
                }
              }
            }),
            _vm._v(" "),
            _c("PreviewTitle", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentPart == "title_1",
                  expression: "currentPart == 'title_1'"
                }
              ],
              attrs: {
                title: _vm.title,
                playing: _vm.playing,
                active: _vm.currentPart == "title_1"
              },
              on: {
                ended: function($event) {
                  return _vm.nextPart()
                }
              }
            }),
            _vm._v(" "),
            _c("PreviewRecording", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentPart == "recording_0",
                  expression: "currentPart == 'recording_0'"
                }
              ],
              attrs: {
                recording: _vm.getRecording(0),
                playing: _vm.playing,
                active: _vm.currentPart == "recording_0"
              },
              on: {
                ended: function($event) {
                  return _vm.nextPart()
                }
              }
            }),
            _vm._v(" "),
            _c("PreviewRecording", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentPart == "recording_1",
                  expression: "currentPart == 'recording_1'"
                }
              ],
              attrs: {
                recording: _vm.getRecording(1),
                playing: _vm.playing,
                active: _vm.currentPart == "recording_1"
              },
              on: {
                ended: function($event) {
                  return _vm.nextPart()
                }
              }
            }),
            _vm._v(" "),
            _c("PreviewVideos", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentPart == "videos",
                  expression: "currentPart == 'videos'"
                }
              ],
              attrs: {
                videos: _vm.videos,
                playing: _vm.playing,
                active: _vm.currentPart == "videos"
              },
              on: {
                ended: function($event) {
                  return _vm.nextPart()
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("audio", {
        ref: "music",
        staticClass: "c-editor-preview__music",
        attrs: { src: _vm.music, preload: "auto", loop: "" }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }