var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-nieuwsmaker-mobile" },
    [
      _vm._l(_vm.selectorCategories, function(category, index) {
        return _c(
          "div",
          _vm._b(
            { key: category.id, staticClass: "c-nm-video-category" },
            "div",
            category,
            false
          ),
          [
            index == 0
              ? _c("div", { staticClass: "t-title" }, [_vm._v("Deskundige")])
              : index == 1
              ? _c("div", { staticClass: "t-title" }, [_vm._v("Voxpop")])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "simple-collapse",
              _vm._l(category.videos, function(video) {
                return _c("simple-video", {
                  key: video.id,
                  attrs: { video: video, playing: _vm.playing },
                  on: { ply: _vm.handlePlay }
                })
              }),
              1
            )
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-nm-video-category" },
        [
          _c("div", { staticClass: "t-title" }, [_vm._v("Sfeerbeelden")]),
          _vm._v(" "),
          _c(
            "simple-collapse",
            _vm._l(_vm.editorVideos, function(video) {
              return _c("simple-video", {
                key: video.id,
                attrs: { video: video, playing: _vm.playing },
                on: { ply: _vm.handlePlay }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-nm-video-category" },
        [
          _c("div", { staticClass: "t-title" }, [_vm._v("Muziek")]),
          _vm._v(" "),
          _c(
            "simple-collapse",
            _vm._l(_vm.editorTracks, function(audio) {
              return _c("simple-audio", {
                key: audio.id,
                attrs: { audio: audio, playing: _vm.playing },
                on: { ply: _vm.handlePlay }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }