var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-nieuwsmaker" },
    [
      _vm.isPhone
        ? [
            _c("div", { staticClass: "u-slide" }, [
              _c("div", { staticClass: "c-bg" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "u-container" },
                [
                  _c("Waiting", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c("h2", { staticClass: "t-title" }, [
                                _vm._v("Pak je laptop erbij")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "t-text" }, [
                                _c("p", [
                                  _vm._v(
                                    "De nieuwsmaker werkt alleen op een laptop"
                                  )
                                ])
                              ])
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "box",
                          fn: function() {
                            return [
                              _c("div", { staticClass: "u-box c-leader-box" }, [
                                _c(
                                  "div",
                                  { staticClass: "c-leader-box__text" },
                                  [_vm._v("Log in op je laptop")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "c-leader-box__help" },
                                  [
                                    _vm._v(
                                      "Gebruik je " +
                                        _vm._s(_vm.appName) +
                                        " account om de nieuwsmaker te spelen"
                                    )
                                  ]
                                )
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2537636971
                    )
                  })
                ],
                1
              )
            ])
          ]
        : [
            _vm.loading
              ? [_vm._m(0)]
              : !_vm.playable
              ? [_vm._m(1)]
              : _vm.submitted
              ? [
                  _c("div", { staticClass: "c-nieuwsmaker-success" }, [
                    _c("h2", { staticClass: "t-title" }, [_vm._v("Gelukt!")]),
                    _vm._v(" "),
                    _vm._m(2),
                    _vm._v(" "),
                    _c("div", { staticClass: "u-box c-leader-box" }, [
                      _c("div", { staticClass: "c-leader-box__text" }, [
                        _vm._v("Log weer in op je telefoon")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "c-leader-box__help" }, [
                        _vm._v(
                          "Gebruik je " +
                            _vm._s(_vm.appName) +
                            " account om direct door te spelen"
                        )
                      ])
                    ])
                  ])
                ]
              : [
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in", appear: "" } },
                    [
                      _vm.view
                        ? _c(_vm.view, {
                            tag: "component",
                            on: {
                              "view-finished": function($event) {
                                return _vm.viewFinished($event)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "u-container" }, [
      _c("h2", { staticClass: "t-title" }, [_vm._v("Even geduld...")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "u-container" }, [
      _c("h2", { staticClass: "t-title" }, [
        _vm._v("Helaas, je webbrowser is niet geschikt voor de nieuwsmaker.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t-text" }, [
      _c("p", [_vm._v("Jullie nieuwsmaker is verstuurd.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }