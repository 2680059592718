<template>
	<div class="v-team-leader">
		<slot name="leader-content" v-if="leading"></slot>
		<template v-else-if="leader">
			<h2 class="t-title">Teamopdracht</h2>
			<slot name="follower-content">
				<div class="t-text">
					<p>Bedenk gezamenlijk met je teamleden jullie antwoord. De teamleider kan het antwoord invoeren in zijn/haar scherm.</p>
				</div>
			</slot>
			<div class="u-box c-leader-box">
				<div class="c-leader-box__text">{{ leader.name }} is teamleider</div>
				<div class="c-leader-box__help">Je gaat automatisch door wanneer de actie is uitgevoerd</div>
			</div>
		</template>
		<template v-else>
			<h2 class="t-title">Teamopdracht</h2>
			<div class="t-text">
				<p>Er wordt een teamleider gekozen.</p>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: 'TeamLeaderView',
	props: {
		user: Object,
	},
	data(){
		return {
			onlineTeamMembers: [],
		}
	},
	computed: {
		teams() {
			return this.$actions.getTeams();
		},
		team() {
			return this.teams.find(x => x.uuid == this.user?.team_uuid);
		},
		leader() {
      return this.team?.leader;
		},
		leading() {
			return this.leader?.uuid == this.user?.uuid;
		},
	},
	methods: {
	},
};
</script>

<style scoped></style>
