import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

// Pusher.log = function(msg) {
// 	console.log(msg);
// };

if (document.getElementById('echo-config')) {
	const config = JSON.parse(document.getElementById('echo-config').textContent);

	const options = {
		broadcaster: 'pusher',
		key: config.key || '1',
		wsHost: config.host || window.location.hostname,
		wsPort: config.port || 6001,
		forceTLS: false,
		enableStats: false,
		auth: {
			headers: config.headers ?? {},
		},
		enabledTransports: [
            'ws',
            'wss',
        ],
		activityTimeout: 1000,
		pongTimeout: 2000,
		unavailableTimeout: 2000,
	};

	if (config.channels) {
		window.Echo = new Echo(options);

		let previouslyDisconnected = false;

		window.Echo.connector.pusher.connection.bind('connected', function() {
			console.log('connected!');
			if (!previouslyDisconnected) {
				document.body.classList.remove('not-connected');
			}
		});

		window.Echo.connector.pusher.connection.bind('connecting', function() {
			console.log('disconnected!');
			document.body.classList.add('not-connected');
			previouslyDisconnected = true;

			window.channels.forEach(channel => {
				channel?.unsubscribe();
				console.log('unsubscribed', channel);
			});
		});

		window.channels = [];
		config.channels.forEach(channel => {
			switch (channel.type) {
				case 'private':
					window.channels.push(window.Echo.private(channel.name));
					break;

				case 'presence':
					window.channels.push(window.Echo.join(channel.name));
					break;
			}
		});

		setTimeout(function() {
			const event = new Event('realtime.init');
			event.channels = window.channels;
			document.dispatchEvent(event);
		}, 0);
	}
}
