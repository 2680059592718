var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.leading
        ? [_c("NieuwsMaker", { attrs: { slide: _vm.slide } })]
        : [_c("TeamLeaderView", { attrs: { user: _vm.user } })]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }