<template>
	<div class="c-content">
		<div class="c-bg"></div>
		<div class="u-container">
			<Waiting>
				<template v-slot:header>
					<h2 class="t-title" v-if="waitingTitle">{{ waitingTitle }}</h2>
					<div class="t-text" v-if="waitingText">
						<p>{{ waitingText }}</p>
					</div>
				</template>

				<template v-slot:box v-if="slide.settings.disableBox">
					<br />
				</template>
			</Waiting>
		</div>
	</div>
</template>

<script>
import Waiting from '../views/Waiting';

export default {
	name: 'ImagesSlide',
	components: {
		Waiting,
	},
	props: {
		slide: Object,
	},
	computed: {
		waitingTitle() {
			return this.slide.settings?.waiting?.title;
		},
		waitingText() {
			return this.slide.settings?.waiting?.text;
		},
	},
};
</script>

<style></style>
