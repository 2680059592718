<template>
	<div class="c-editor-preview__title">
		{{ title }}
	</div>
</template>

<script>
export default {
	name: 'PreviewTitle',
	props: {
		title: String,
		playing: Boolean,
		active: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			timer: null,
		};
	},
	watch: {
		playing(val) {
			if (!val) {
				this.resetTimer();
				return;
			}

			if (!this.timer && this.active) {
				this.startTimer();
			}
		},
		active: {
			handler(val) {
				this.$nextTick(() => {
					if (!val) {
						this.resetTimer();
						return;
					}

					if (val && this.playing && !this.timer) {
						this.startTimer();
					}
				});
			},
			immediate: true,
		},
	},
	methods: {
		resetTimer() {
			console.log('in reset timer');

			if (this.timer) {
				clearTimeout(this.timer);
			}

			this.timer = null;
		},
		startTimer() {
			this.timer = setTimeout(() => {
				if (this.playing) {
					this.$emit('ended');
				}
			}, 3000);
		},
	},
	beforeDestroy() {
		this.resetTimer();
	},
};
</script>

<style></style>
