<template>
	<div class="c-content">
		<div class="c-bg"></div>
		<div class="u-container">
			<TeamLeaderView :user="user">
				<template v-slot:leader-content>
					<form v-on:submit.prevent="submit" autocomplete="off">
						<div v-if="step == 1" :key="step">
							<h2 class="t-title">Vul de naam in van jullie planeet</h2>
							<div class="t-text">
								<p>Vul gezamenlijk met je teamleden welke naam jullie planeet gaat vertegenwoordigen.</p>
							</div>
							<div class="c-input">
								<label for="planetName">Planeetnaam</label>
								<input type="text" id="planetName" placeholder="Planeetnaam" v-model="form.planetName" v-on:keyup="form.planetName = $event.target.value" autocomplete="off" />
							</div>
							<button class="c-button c-button--full c-button--main" type="button" v-on:click="next()" v-bind:disabled="disabled">
								Volgende <svg class="icon icon_arrow"><use xlink:href="#icon_arrow"></use></svg>
							</button>
						</div>
						<div v-if="step == 2" :key="step">
							<h2 class="t-title">Kies de kleur van jullie planeet</h2>
							<div class="t-text">
								<p>Kies gezamenlijk met je teamleden welke kleuren jullie planeet krijgt.</p>
							</div>

							<PlanetColorPicker v-model="form.planetColors" v-on:planet-colors="form.planetColors = $event"></PlanetColorPicker>

							<button class="c-button c-button--full c-button--main" type="submit" v-bind:disabled="disabled">
								{{ buttonText }} <svg class="icon icon_arrow"><use xlink:href="#icon_arrow"></use></svg>
							</button>
						</div>
						<div v-else-if="step == 3" :key="step">
							<Waiting>
								<template v-slot:header>
									<h2 class="t-title">Gelukt!</h2>
									<div class="t-text">
										<p>Jullie naam en kleur zijn verstuurd.</p>
									</div>
								</template>
							</Waiting>
						</div>
					</form>
				</template>
				<template v-slot:follower-content>
					<div class="t-text">
						<p>Kies gezamenlijk met je teamleden welke naam en kleur jullie planeet gaat vertegenwoordigen. Éen van je teamleden kan nu de planeetnaam en kleur invoeren.</p>
					</div>
				</template>
			</TeamLeaderView>
		</div>
	</div>
</template>

<script>
// Components
import TeamLeaderView from '../views/TeamLeaderView.vue';
import PlanetColorPicker from '../components/PlanetColorPicker.vue';
import Waiting from '../views/Waiting.vue';

/*
	Stappen:

	1. Naam kiezen
	2. Kleuren kiezen
	3. Wachten
*/

export default {
	name: 'ChoosePlanet',
	components: {
		TeamLeaderView,
		PlanetColorPicker,
		Waiting,
	},
	props: {
		slide: Object,
		user: Object,
	},
	data() {
		return {
			step: 1,
			submitting: false,
			form: {
				planetName: '',
				planetColor: '',
				planetColors: [],
			},
			errors: {},
		};
	},
	computed: {
		disabled() {
			if (this.submitting) {
				return true;
			}

			if (this.step == 1 && this.form.planetName) {
				return false;
			}

			if (this.step == 2 && this.form.planetColors?.length > 1) {
				return false;
			}

			return true;
		},
		ready() {
			return this.form.planetName && this.form.planetColors.length > 1;
		},
		buttonText() {
			return this.submitting ? 'Even geduld...' : 'Verzenden';
		},
    team(){
		  return this.$actions.getTeam(this.user.team_uuid);
    }
	},
	mounted() {
	  this.form.planetName = this.team.name;
	  this.form.planetColors = this.team.settings.colors;
  },
	methods: {
		next() {
			// TODO: api call voor uniciteit planetName
			if (this.form.planetName) {
				this.step = 2;
			}
		},
		submit() {
			// Navigatie
			if (this.step == 1 && !this.disabled) {
				this.step = 2;
			}

			// Insturen voorkomen
			if (!this.ready) {
				return false;
			}

			this.submitting = true;

			window.axios
				.post('/api/v1/team-metadata', {
					name: this.form.planetName,
					colors: JSON.stringify(this.form.planetColors),
				})
				.then(res => {
					this.step = 3;
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					this.submitting = false;
				});
		},
	},
};
</script>

<style></style>
