<template>
	<div class="c-nieuwsmaker-frame">
		<div class="c-nieuwsmaker-frame__main">
			<div class="c-nieuwsmaker-frame__content">
				<div class="c-nieuwsmaker-logo">
					<img :src="logo" :alt="appName + ' logo'" class="c-nieuwsmaker-logo__img" />
				</div>
				<div class="c-nieuwsmaker-frame__monitor">
					<SelectorMonitor v-bind:currentStep="currentStep" v-bind:previewPlaying="preview" :key="currentId"></SelectorMonitor>
				</div>
			</div>
			<div class="c-nieuwsmaker-frame__bar">
				<div class="c-selector-nav">
					<button class="c-play-button c-play-button--nav" type="button" v-on:click="showPreview">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 11">
							<path
								d="M8.74 5.037L.868.087A.57.57 0 00.09.253.541.541 0 000 .55v9.898c0 .304.25.55.561.551.109 0 .215-.03.306-.088l7.874-4.949a.543.543 0 000-.926z"
								fill-rule="nonzero"
							/>
						</svg>
					</button>

					<div class="c-selector-nav__steps" v-if="steps.length">
						<VideoSelectorStep
							v-for="(step, index) in steps"
							v-bind:step="step"
							v-bind:currentId="currentId"
							v-bind:index="index"
							v-on:current-step="currentId = $event"
							:key="step.id"
						></VideoSelectorStep>

						<button type="button" class="c-video-selector-step c-video-selector-step--next-view" v-if="ready" v-on:click="nextView">
							<div class="c-video-selector-step__frame">
								Door met deel 2
							</div>
							<div class="c-video-selector-step__label">&nbsp;</div>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="c-nieuwsmaker-frame__sidebar">
			<VideoSidebar v-if="steps.length" v-bind:currentId="currentId" v-bind:currentStep="currentStep" v-on:video-selected="currentStep.video = $event"></VideoSidebar>
		</div>

		<NieuwsmakerModal v-if="preview">
			<SelectorPreview v-on:close="preview = false" v-bind:videos="selectedVideos"></SelectorPreview>
		</NieuwsmakerModal>
	</div>
</template>

<script>
// Components
import VideoSelectorStep from '../components/VideoSelectorStep';
import VideoSidebar from '../components/VideoSidebar';
import SelectorMonitor from '../components/SelectorMonitor';
import SelectorPreview from '../components/SelectorPreview';
import NieuwsmakerModal from '../components/NieuwsmakerModal';

// Helpers etc.
import { getters, mutations } from '../store.js';

export default {
	name: 'VideoSelector',
	components: {
		VideoSelectorStep,
		VideoSidebar,
		SelectorMonitor,
		SelectorPreview,
		NieuwsmakerModal,
	},
	data() {
		return {
			currentId: '',
			steps: [],
			preview: false,
			logo: window.settings.nieuwsmaker_logo,
			appName: window.settings.name,
		};
	},
	computed: {
		result() {
			return getters.result();
		},
		cmsData() {
			return getters.cmsData();
		},
		currentState() {
			return getters.currentState();
		},
		ready() {
			return this.steps.every(step => step.video.id);
		},
		currentStep() {
			return this.steps.find(step => step.id == this.currentId);
		},
		selectedVideos() {
			return this.steps.reduce((filtered, step) => {
				if (step.video.url) {
					filtered.push(step.video);
				}

				return filtered;
			}, []);
		},
	},
	mounted() {
		const categories = this.cmsData[this.currentState].categories;
		const [firstCategory] = categories;

		this.currentId = firstCategory.id;

		let result = localStorage.getItem('nieuwsmaker_selector');
		let videos = [];

		if (result) {
			result = JSON.parse(result);
			const videoCat = result.find(cat => cat.id == 'videos');
			videos = videoCat.videos;
		}

		this.steps = categories.map((category, index) => {
			return {
				id: category.id,
				label: index + 1,
				video: videos.length ? videos[index] : {},
			};
		});
	},
	methods: {
		nextView() {
			this.storeSelection();
			this.$emit('view-finished', {
				next: 'editor',
			});
		},
		storeSelection() {
			const videos = this.steps.map(step => step.video);
			mutations.setVideos(videos);

			localStorage.setItem('nieuwsmaker_selector', JSON.stringify(this.result));
		},
		showPreview() {
			if (!this.steps.some(step => step.video.id)) {
				console.log('nope mag nog niet');
				return;
			}

			this.preview = true;
		},
	},
};
</script>

<style></style>
