<template>
	<div class="u-slide-color--purple-orange">
		<div class="c-bg"></div>
		<div class="c-content">
			<div class="c-tabs">
				<button type="button" class="c-tabs__tab" v-for="tab in tabs" :key="tab.component" :class="{ 'is-active': currentTab == tab.component }" @click="currentTab = tab.component">
					{{ tab.label }}
				</button>
			</div>
			<div class="u-container">
				<transition name="fade" mode="out-in">
					<component v-bind:is="currentTab" :user="user"></component>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
import MyPlanet from '../components/MyPlanet';
import Team from '../components/Team';
import AllPlanets from '../components/AllPlanets';

export default {
	name: 'Dashboard',
	components: {
		MyPlanet,
		Team,
		AllPlanets,
	},
	props: {
		user: Object,
	},
	data() {
		return {
			currentTab: 'MyPlanet',
			tabs: [
				{
					label: 'Mijn planeet',
					component: 'MyPlanet',
				},
				{
					label: 'Team',
					component: 'Team',
				},
				{
					label: 'Alle planeten',
					component: 'AllPlanets',
				},
			],
		};
	},
};
</script>

<style></style>
