var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "c-bg" }),
    _vm._v(" "),
    _c("div", { staticClass: "c-content" }, [
      _c(
        "div",
        { staticClass: "u-container" },
        [
          !_vm.step && _vm.settings.intro
            ? [
                _c("h2", { staticClass: "t-title" }, [
                  _vm._v(_vm._s(_vm.settings.intro.title))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "t-text" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.settings.intro.text) +
                      "\n\t\t\t\t"
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "c-button c-button--full c-button--main",
                    attrs: { type: "button" },
                    on: { click: _vm.next }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.settings.intro.buttonText) +
                        " "
                    ),
                    _c("svg", { staticClass: "icon icon_arrow" }, [
                      _c("use", { attrs: { "xlink:href": "#icon_arrow" } })
                    ])
                  ]
                )
              ]
            : !_vm.submitted
            ? [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade", mode: "out-in" } },
                      [
                        _c("Question", {
                          key: _vm.currentQuestion.id,
                          attrs: {
                            question: _vm.currentQuestion,
                            answers: _vm.previousAnswers,
                            steps: _vm.steps,
                            submitting: _vm.submitting
                          },
                          on: {
                            prev: _vm.prev,
                            next: _vm.next,
                            update: _vm.update
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _c("Waiting")
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }