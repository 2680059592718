<template>
	<div class="u-box t-text">
		<div class="c-simple-collapse" v-bind:class="{ 'c-simple-collapse--open': isOpen }">
			<div class="c-simple-collapse__content" v-html="text"></div>
			<button class="c-simple-collapse__button" type="button" v-on:click="isOpen = !isOpen">{{ buttonLabel }}</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SimpleCollapse',
	props: {
		text: String,
		buttonText: {
			type: Object,
			default() {
				return {
					open: 'Lees meer',
					close: 'Dichtklappen',
				};
			},
		},
		open: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isOpen: null,
		};
	},
	computed: {
		buttonLabel() {
			return this.isOpen ? this.buttonText.close : this.buttonText.open;
		},
	},
	watch: {
		open: {
			immediate: true,
			handler(newValue) {
				this.isOpen = newValue;
			},
		},
	},
};
</script>

<style>
	.c-simple-collapse__content p {
		margin-bottom: 1em;
	}
</style>
