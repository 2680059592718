<template>
	<div class="c-selector-preview">
		<button class="c-selector-preview__close" type="button" v-on:click="close">sluiten</button>
		<div class="c-selector-preview__wrapper">
			<div class="c-selector-preview__frame">
				<video
					class="c-selector-preview__video"
					v-if="currentVideo.url"
					v-bind:src="currentVideo.url"
					v-on:ended="ended"
					ref="video"
					disablepictureinpicture
					disableremoteplayback
					autoplay
					preload="auto"
				></video>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SelectorPreview',
	props: {
		videos: Array,
	},
	data() {
		return {
			index: 0,
		};
	},
	computed: {
		currentVideo() {
			return this.videos[this.index];
		},
	},
	methods: {
		ended(e) {
			const max = this.videos.length;

			if (this.index + 1 < max) {
				this.index++;
			}
		},
		close() {
			this.$refs.video.pause();
			this.$emit('close');
		},
	},
};
</script>

<style></style>
