var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "c-big-planet" },
        [
          _c("TeamPlanet", { attrs: { team: _vm.team, showMoons: true } }),
          _vm._v(" "),
          _c("div", { staticClass: "c-big-planet__name" }, [
            _vm._v(_vm._s(_vm.team.name))
          ]),
          _vm._v(" "),
          _vm.allStationsClosed
            ? _c("div", { staticClass: "c-big-planet__type" }, [
                _vm._v(_vm._s(_vm.teamTypeName))
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.allStationsClosed
        ? _c("simple-collapse", { attrs: { text: _vm.teamTypeDescription } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-values" },
        _vm._l(_vm.objects, function(object) {
          return _c("label", { key: object.uuid, staticClass: "c-value" }, [
            _c("div", { staticClass: "c-value__content" }, [
              _c("div", { staticClass: "c-value__model-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "c-value__model" },
                  [
                    _c("Model3D", {
                      attrs: {
                        model: object.model,
                        color: _vm.getColor(object.type)
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "c-value__name" }, [
                _vm._v(_vm._s(object.name))
              ])
            ])
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }