<template>
	<div class="c-nm-video">
		<video ref="video" class="c-nm-video__video" v-bind:src="video.url" v-on:play="$emit('ply', video.id)" disablepictureinpicture disableremoteplayback controls></video>
	</div>
</template>

<script>
export default {
	name: 'SimpleVideo',
	props: {
		video: Object,
		playing: String,
	},
	watch: {
		playing: {
			immediate: true,
			handler(val) {
				if (!this.$refs.video) {
					return false;
				}

				if (val !== this.video.id) {
					this.$refs.video.pause();
				}
			},
		},
	},
};
</script>

<style></style>
