<template>
	<div class="c-planet__3d"></div>
</template>

<script>
import Engine from './d3/Engine';
import Planet from './d3/Planet';

export default {
	name: 'Planet',
	props: {
		showMoons: {
			type: Boolean,
			default: true,
		},
		moons: {
			type: Array,
			default: () => {
				return [];
			},
		},
		colors: {
			type: Array,
			default: () => {
				return [];
			},
		},
		options: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data() {
		return {
			engine: {},
		};
	},
	computed: {
		gradient() {
			if (this.colors.length) {
				return this.colors;
			}

			return ['', ''];
		},
	},
	watch: {
		moons(value) {
			console.log('Planet.watch(moons)', value);
			this.planet.setMoons(value);
		},
		colors() {
			this.changeColor();
		},
	},
	methods: {
		changeColor() {
			this.planet.changeColor1(this.gradient[0]);
			this.planet.changeColor2(this.gradient[1]);
		},
		resize() {
			if (this.engine) {
				this.engine.resize();
			}
		},
	},
	mounted() {
    try {

      this.engine = new Engine(this.$el, this.options);

      this.planet = new Planet({
        color1: this.gradient[0],
        color2: this.gradient[1],
        moons: this.moons,
        showMoons: this.showMoons,
      });
      this.engine.addItem(this.planet);

    }catch(e){
      console.error('Failed to initialize WebGL');
    }



		window.addEventListener('resize', this.resize);
	},
	destroyed() {
		window.removeEventListener('resize', this.resize);
	},
};
</script>

<style scoped></style>
